import React, { Component } from "react";
import "./DealStructure.scss";
import axios from "axios";
import RateCardData from "../../json/ratecard.json";
import * as Constants from "../../constants/dealStructureConstants";
import DealDetails from "../RateCard/DealDetails";
import RegionalDetails from "../RateCard/RegionalDetails";
import PricingDetails from "../RateCard/PricingDetails";
import Col from "../common/AWSCol/AWSCol";
import PropTypes from "prop-types";
import AWSConfig from "../../config/AWSconfig";
import * as roleNames from '../../constants/roleNames.const';
import * as dealStatusConstant from '../../constants/dealStatusConstant';
import AWSModal from "../common/AWSModal/AWSModal";
import { getIsCloudFrontShieldAdvancedDeal } from "../RateCard/helpers/rateCard";

import _ from "underscore";
import { ApiRequest } from "../../api/requests";
import { getRateCardApiBody, getUpdatedStateFromRateCardApi, isDealEdited, parseRevenueFromApi } from "./helpers/dashboard";
import {
  CLOUDFRONT_SHIELD_ADVANCED_CUSTOM_RATES_ERROR,
  CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE,
  getCloudFrontShieldAdvancedTypeForRateCard, 
  isCloudFrontShieldAdvancedDeal,
  isCommitAcceptable,
  CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_MIN_COMMIT,
  getCloudFrontShieldAdvancedAosValue,
  getCloudFrontShieldAdvancedMinCommit
} from "../../constants/cloudFrontShieldAdvancedConstants";

const config = new AWSConfig();
const RATE_CARD_API_URL = `${config.pricingIntakeUrl}ratecard`;
let customRateCardObject = [];

class DealStructure extends Component {
  state = {
    rateCard: RateCardData,
    visible: false,
    origionalRatecard: ""
  };

  /**
   * This lifecycle method is getting the values from parent component in Props and setting it to local state
   * Also it will call the commitment Fee calcuation API with default values
   */
  componentDidMount() {
    let { userRole } = this.props;

    if (userRole) {
      this.onComponentRender()
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    let { rateCard } = this.state;
    let { rateCardData } = this.props;

    if (this.props.auditVersionId !== prevProps.auditVersionId) {
      this.onComponentRender()
    }
    if (prevProps.pprId !== this.props.pprId) {
      this.onComponentRender();
      if (rateCardData === null) {
        this.setState({
          rateCard: RateCardData
        })
      }
    }

    const termLengthChanged = prevProps.termLength
      && prevProps.termLength !== ""
      && prevProps.termLength.toString() !== this.state.rateCard.TermLength.toString();

    const { cloudFrontShieldAdvancedDeal } = this.props.dealInfo;
    const cloudFrontShieldAdvancedDealChanged = cloudFrontShieldAdvancedDeal !== this.state.rateCard.cloudFrontShieldAdvancedDeal;

    if (termLengthChanged || cloudFrontShieldAdvancedDealChanged) {
      this.setState(
        prevState => ({
          rateCard: {
            ...prevState.rateCard,
            TermLength: parseInt(this.props.termLength || 12),
            TermLength_Nocommit: parseInt(this.props.termLength || 12),
            cloudFrontShieldAdvancedDeal
          }
        }),
        () => {
          if (
            this.props.dealStatus &&
            this.props.dealStatus === dealStatusConstant.PPR_Created
          ) {
            this.getCommitmentfee("TermLength");
          }
          this.props.onchangeDealStructure("TermLength", this.state.rateCard.TermLength);
        }
      );
    }
  }

  onComponentRender() {
    this.props.onRef(this);
    let { dealInfo, userRole, rateCardData } = this.props;
    let { rateCard } = this.state;
    const { cloudFrontShieldAdvancedDeal } = dealInfo;
    const isCloudFrontShieldAdvanced = isCloudFrontShieldAdvancedDeal(cloudFrontShieldAdvancedDeal);

    if (dealInfo && rateCardData) {
      const rateCardJson = JSON.parse(this.props.rateCardData);
      this.setState({
        rateCard: {
          ...rateCardJson,
          geoVolumeCommitErrors: rateCardJson.geoVolumeCommitErrors || {},
          saveEnteredRates: rateCardJson.saveEnteredRates || false
        }
      }, () => {
        if (dealInfo.dealStatus !== dealStatusConstant.PPR_Created && userRole === roleNames.SALES_REPRESENTATIVE) {
          this.setState(
            prevState => ({
              rateCard: {
                ...prevState.rateCard,
                isMaterialChange: false
              }
            })
          );
          let StandardRecord = [];
          _.each(this.state.rateCard.defaultRateCardRates, rowVal => {
            StandardRecord[rowVal.geo] = rowVal.price;
          });
          customRateCardObject[0] = StandardRecord;
        }
        if (dealInfo.dealStatus === dealStatusConstant.PPR_Created && userRole === roleNames.SALES_REPRESENTATIVE) {
          this.setState(
            prevState => ({
              rateCard: {
                ...prevState.rateCard,
                isMaterialChange: true
              }
            })
          );
        }
        if (dealInfo.dealStatus !== dealStatusConstant.PPR_Created) {
          this.setState({
            rateCardClone: JSON.parse(this.props.rateCardClone)
          });
        }
      });
      if (dealInfo.origionalRatecard) {
        this.setState({
          origionalRatecard: JSON.parse(this.props.dealInfo.origionalRatecard)
        });
      }
      this.props.onchangeDealStructure("Dataload", true);
    } else if (this.props.termLength) {
      this.setState(
        prevState => ({
          rateCard: {
            ...prevState.rateCard,
            TermLength: parseInt(this.props.termLength),
            TermLength_Nocommit: parseInt(this.props.termLength),
            Aos_Selected: getCloudFrontShieldAdvancedAosValue(isCloudFrontShieldAdvanced, prevState.rateCard.Aos_Selected)
          }
        }),
        () => {
          this.props.onchangeDealStructure(
            "Minimum Average Object Size",
            this.state.rateCard.Aos_Selected
          );
          this.props.onchangeDealStructure("TermLength", this.state.rateCard.TermLength);
          this.getCommitmentfee("TermLength");
        }
      );
    } else {
      this.setState(
        prevState => ({
          rateCard: {
            ...prevState.rateCard,
            TermLength: 12,
            Aos_Selected: getCloudFrontShieldAdvancedAosValue(isCloudFrontShieldAdvanced, prevState.rateCard.Aos_Selected)
          }
        }),
        () => {
          this.props.onchangeDealStructure("TermLength", this.state.rateCard.TermLength);
          this.getCommitmentfee("Aos_Selected");
        }
      );
    }
  }

  /**
   * This function used to display Toast message from parent component
   * @param {*} message message label to be displayed in the toast message
   * @param {*} toast the toast value of true or false
   */
  showDealStructureLoadingToast = (message, toast) => {
    this.props.showDealStructureLoadingToast(message, toast);
  };

  /**
   * This function used to load the standard rates after user Edited the rate card values
   */
  loadStandardDeal() {
    let data = this.state.rateCard.defaultRateCardRates;
    _.each(data, rowVal => {
      this.state.rateCard.dataTransferPriceNotTiered.map(cells => {
        cells.cells.map(d => {
          if (d.tierKey === rowVal.geo) {
            d.value = rowVal.price;
            d.isEdited = false;
          }
          if (d.tierKey === "ALL") {
            d.value = "";
            d.isEdited = false;
          }
        });
      });
    });
    this.setState(prevState => ({
      rateCard: {
        ...prevState.rateCard,
        minimum_commitment_fee: this.state.rateCard.standardMinimumRevenue,
        dealEdited: false,
        dataTransfertableError: "",
        dataTransferError: "",
        tieredPricing: false,
        total_commitment_fee: this.state.rateCard.standardMinimumRevenue,
        totalExpError: "",
        rcCustom: "Standard",
        cloudFrontShieldAdvancedCustomRatesError: undefined
      }
    }),
      () => {
        this.props.onchangeDealStructure("LefNav", this.state.rateCard);
        this.props.onchangeDealStructure(
          "Minimum Committed Revenue",
          "$ " + this.state.rateCard.standardMinimumRevenue
        );
        this.props.onchangeDealStructure(
          "datasource",
          this.state.rateCard.dataTransferPriceNotTiered
        );
      });
    this.props.onChangeAdditionalApproval("customRateCard", false);
    this.props.onchangeDealStructure("dealEdited", false);
    this.props.onChangeCustomDeal("customRateCard", false);
  }

  /**
   * This function used to load the calcualte minimum committed revenue after user modified the rate card values
   * @param {*} key the rate card key value for the edited region value
   */
  calculateDeal(key, prevMinimumCommit) {
    if (
      this.state.rateCard.TypeOfCommit.length > 0 &&
      this.state.rateCard.TypeOfCFRL !== "" &&
      this.state.rateCard.Aos_Selected !== "" &&
      this.state.rateCard.volumeUnit !== "" &&
      this.state.rateCard.TermLength !== "" &&
      this.state.rateCard.geo !== ""
    ) {
      const { cloudFrontShieldAdvancedDeal } = this.props.dealInfo;
      const isCloudFrontShieldAdvanced = isCloudFrontShieldAdvancedDeal(cloudFrontShieldAdvancedDeal);
      const cloudFrontShieldAdvancedType = getCloudFrontShieldAdvancedTypeForRateCard(cloudFrontShieldAdvancedDeal);
      let requestCommit;
      let DataObj = { ...this.state.rateCard };
      let dataValues =
        this.state.rateCard.tieredPricing === true
          ? this.state.rateCard.dataTransferPriceTiered
          : this.state.rateCard.dataTransferPriceNotTiered;
      let commit_Fee = this.state.rateCard.commitment_fee.replace(/,/g, "");
      let newDataObject = {
        default: {
          TypeOfCommit: DataObj.TypeOfCommit,
          TypeOfCFRL: DataObj.TypeOfCFRL,
          Aos_Selected: parseFloat(DataObj.Aos_Selected),
          TermLength: parseFloat(DataObj.TermLength),
          isGeoLocALL: this.state.rateCard.geo === "All" ? true : false,
          commitPrice: parseInt(commit_Fee),
          isCloudFrontShieldAdvanced,
          cloudFrontShieldAdvancedType
        },
        rowValues: []
      };

      if (customRateCardObject && customRateCardObject.length === 0) {
        let StandardRecord = [];
        _.each(this.state.rateCard.defaultRateCardRates, rowVal => {
          StandardRecord[rowVal.geo] = rowVal.price;
        });
        customRateCardObject[0] = StandardRecord;
      }

      dataValues.forEach((dataSource, dataIndex) => {
        if (dataIndex === 0) {
          let newDataArray = [];
          if (this.state.rateCard.volumeUnit === "PB") {
            requestCommit =
              parseFloat(this.state.rateCard.requested_commit) * 1024;
            if (this.state.rateCard.geo !== "All") {
              requestCommit =
                parseFloat(
                  this.state.rateCard.geolocations[dataIndex].optionValue
                ) * 1024;
            }
          } else {
            requestCommit = parseFloat(this.state.rateCard.requested_commit);
            if (this.state.rateCard.geo !== "All") {
              requestCommit = parseFloat(
                this.state.rateCard.geolocations[dataIndex].optionValue
              );
            }
          }

          newDataArray = {
            requested_commit: parseFloat(requestCommit),
            tierCommit: parseFloat(requestCommit),
            values: []
          };

          if (this.state.rateCard.geo === "All") {
            if (key === "ALL") {
              dataSource.cells.map(d => {
                if (
                  d.tierKey === "ALL" &&
                  d.tierKey !== "<TB" &&
                  d.tierKey !== ">TB" &&
                  d.value !== "" &&
                  customRateCardObject[dataIndex]
                ) {
                  newDataArray.values.push({
                    geo: d.tierKey,
                    price: {
                      edited: parseFloat(d.value),
                      RC1: parseFloat(customRateCardObject[dataIndex]["NA"])
                    }
                  });
                }
              });
            } else {
              dataSource.cells.map(d => {
                if (
                  d.tierKey !== "ALL" &&
                  d.tierKey !== "<TB" &&
                  d.tierKey !== ">TB" &&
                  d.value !== "" &&
                  customRateCardObject[dataIndex]
                ) {
                  newDataArray.values.push({
                    geo: d.tierKey,
                    price: {
                      edited: parseFloat(d.value),
                      RC1: parseFloat(customRateCardObject[dataIndex][d.tierKey])
                    }
                  });
                }
              });
            }
            newDataObject.rowValues.push(newDataArray);
          } else {
            if (key === "ALL") {
              dataSource.cells.map(d => {
                if (
                  d.tierKey === "ALL" &&
                  d.tierKey !== "<TB" &&
                  d.tierKey !== ">TB" &&
                  d.value !== ""
                ) {
                  newDataArray.values.push({
                    geo: d.tierKey,
                    price: {
                      edited: parseFloat(d.value),
                      RC1: parseFloat(customRateCardObject[dataIndex]["NA"])
                    }
                  });
                }
              });
            } else {
              dataSource.cells.map(d => {
                if (
                  d.tierKey !== "ALL" &&
                  d.tierKey !== "<TB" &&
                  d.tierKey !== ">TB" &&
                  d.value !== ""
                ) {
                  const tierRC1 = customRateCardObject[dataIndex][d.tierKey];
                  if (tierRC1 !== undefined) {
                    newDataArray.values.push({
                      geo: d.tierKey,
                      price: {
                        edited: parseFloat(d.value),
                        RC1: parseFloat(tierRC1)
                      }
                    });
                  }
                }
              });
            }
            newDataObject.rowValues.push(newDataArray);
          }
        }
      });
      if (newDataObject) {
        this.showDealStructureLoadingToast("Calculating Minimum Committed Revenue", true);
        axios({
          method: "POST",
          url: `${config.pricingIntakeUrl}dealandtier`,
          data: newDataObject
        }).then(resp => {
          let minimumCommitRevenue;
          let minimumCommitFee;
          let { dealInfo } = this.props;
          const isCloudFrontShieldAdvancedDeal = getIsCloudFrontShieldAdvancedDeal(dealInfo);

          if (resp.data.Minimum_commit_revenue) {
            if (resp.data.Minimum_commit_revenue !== null)
              minimumCommitRevenue = Math.round(
                parseFloat(resp.data.Minimum_commit_revenue)
              )
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
          } else {
            minimumCommitRevenue = this.state.rateCard.minimum_commitment_fee
          }

          if (
            this.state.rateCard.TypeOfCommit.length === 1 &&
            this.state.rateCard.TypeOfCommit[0] === "Commitment Fee"
          ) {
            minimumCommitFee = this.state.rateCard.commitment_fee;
          } else {
            minimumCommitFee = minimumCommitRevenue;
          }

          if (isCloudFrontShieldAdvancedDeal && resp.data.data === "Custom") {
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                minimum_commitment_fee: minimumCommitFee,
                total_commitment_fee: minimumCommitRevenue,
                totalExpError: "",
                dataTransfertableError: "Enter rates above the pre-approved rates",
                cloudFrontShieldAdvancedCustomRatesError: CLOUDFRONT_SHIELD_ADVANCED_CUSTOM_RATES_ERROR
              }
            }));
            this.showDealStructureLoadingToast("Calculating Minimum Committed Revenue", false);
          } else {
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                minimum_commitment_fee: minimumCommitFee,
                total_commitment_fee: minimumCommitRevenue,
                totalExpError: "",
                rcCustom: resp.data.data,
                cloudFrontShieldAdvancedCustomRatesError: undefined
              }
            }),
              () => {
                this.props.onchangeDealStructure("LefNav", this.state.rateCard);
              });
            this.props.onChangeCustomDeal("customRateCard", resp.data.data);
            this.props.onchangeDealStructure(
              "Minimum Committed Revenue",
              "$ " + minimumCommitFee
            );
            this.showDealStructureLoadingToast("Calculating Minimum Committed Revenue", false);
            this.checkPublicRates(key);
          }
        }).catch(error => {
          this.showDealStructureLoadingToast("Failed to calculate Minimum Committed Revenue. Please try after sometime", true);
          if (DataObj.TypeOfCommit.includes("Volume Commit")) {
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                minimum_commitment_fee: prevMinimumCommit,
                total_commitment_fee: prevMinimumCommit,
              }
            }));
          }
          setTimeout(() => {
            this.showDealStructureLoadingToast("Failed to calculate Minimum Committed Revenue. Please try after sometime", false);
          }, 2000);
        });
      }
    }
  }

  /**
   * This function used update the local state value of each input element
   * and call the commitment Fee calcualtion based on value change
   * @param {*} value typed or selected option which need to update in the state
   * @param {*} name in which name of the particular element value to be set
   * @param {*} location the array row location where the values chaned
   * @param {*} cellId cell id of table when any table data is inserted to make change in particular cell
   */
  handleInputChange(value, name, location, cellId, type) {
    let { rateCard } = this.state;
    let { dealInfo, userRole, canEditPPR } = this.props;
    const isCloudFrontShieldAdvancedDeal = getIsCloudFrontShieldAdvancedDeal(dealInfo);

    //* Check the Prvate Pricing Users edit and display alert *//
    if (roleNames.PRIVATE_PRICING_USERS.includes(userRole) && !Constants.dealDetailsSection.includes(name)) {
      this.checkPrivatePricingEdit(value, name, type);
    }

    if (dealInfo.dealStatus !== dealStatusConstant.PPR_Created && userRole === roleNames.SALES_REPRESENTATIVE) {
      if (name === "regionalTraffic") {
        if (rateCard.regionalTraffic !== value) {
          rateCard.isMaterialChange = true
        }
      } else if (name === "winDeal") {
        if (rateCard.winDeal !== value) {
          rateCard.isMaterialChange = true
        }
      } else if (name === "pricingTerms") {
        if (rateCard.pricingTerms !== value) {
          rateCard.isMaterialChange = true
        }
      } else {
        if (name !== "regionalTraffic" && name !== "winDeal" && name !== "pricingTerms") {
          rateCard.isMaterialChange = true;
        }
      }
    }

    if (canEditPPR === true && userRole && userRole !== roleNames.BILLING_TEAM) {
      if (
        name === "total_commitment_fee" ||
        name === "total_commitment_fee_Nocommit" ||
        name === "commitment_fee"
      ) {
        if (value !== "" && parseInt(value) !== 0) {
          value = value.replace(/,/g, "");
          if (!isNaN(value)) {
            value = parseInt(value);
            value =
              value &&
              value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
          } else {
            value = "";
          }
        }
      }
      switch (name) {
        case "dataTransferInput":
          if (this.state.rateCard.tieredPricing === true) {
            this.state.rateCard.dataTransferPriceTiered.map(
              (cells, dataIndex) => {
                if (location === dataIndex) {
                  cells.cells.map((data, index) => {
                    if (index === cellId) {
                      data.value = value;
                      if (data.tierKey !== "<TB") {
                        data.isEdited = true;
                      }
                    }
                  });
                }
              }
            );
            this.props.onchangeDealStructure(
              "datasource",
              this.state.rateCard.dataTransferPriceTiered,
              "tiered"
            );
          } else {
            this.state.rateCard.dataTransferPriceNotTiered.map(
              (cells, dataIndex) => {
                if (location === dataIndex) {
                  cells.cells.map((data, index) => {
                    if (index === cellId) {
                      data.value = value;
                      data.isEdited = true;
                    }
                  });
                }
              }
            );
            this.props.onchangeDealStructure(
              "datasource",
              this.state.rateCard.dataTransferPriceNotTiered
            );
          }
          name = "updateState";
          break;
        case "TypeOfCFRL":
          this.props.onchangeDealStructure("Commit Type", value);
          break;
        case "geo":
          if (!this.state.rateCard.saveEnteredRates) {
            this.state.rateCard.dataTransferPriceNotTiered.map(cells => {
              cells.cells.map(data => {
                data.value = "";
                data.isEdited = false;
              });
            });
            this.state.rateCard.dataTransferPriceTiered.map(cells => {
              cells.cells.map(data => {
                data.value = "";
                data.isEdited = false;
              });
            });
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                minimum_commitment_fee: "",
                total_commitment_fee: "",
                totalExpError: "",
                dealType: "Standard",
                defaultRateCardRates: {},
                dealEdited: false,
                tieredPricing: false
              }
            }));
            this.props.onChangeAdditionalApproval("customRateCard", false);
            this.props.onchangeDealStructure("dealEdited", false);
          }
          break;
        case "dataTransferPricenoCommit":
          if (this.state.rateCard.tieredPricing_noCommit === true) {
            this.state.rateCard.dataTransferPriceTieredNoCommit.map(
              (cells, dataIndex) => {
                if (location === dataIndex) {
                  cells.cells.map((data, index) => {
                    if (index === cellId) {
                      data.value = value;
                    }
                  });
                }
              }
            );
            this.props.onchangeDealStructure(
              "datasource",
              this.state.rateCard.dataTransferPriceTieredNoCommit,
              "tiered"
            );
          } else {
            this.state.rateCard.dataTransferPriceNotTieredNoCommit.map(
              (cells, dataIndex) => {
                if (location === dataIndex) {
                  cells.cells.map((data, index) => {
                    if (index === cellId) {
                      data.value = value;
                    }
                  });
                }
              }
            );
            this.props.onchangeDealStructure(
              "datasource",
              this.state.rateCard.dataTransferPriceNotTieredNoCommit
            );
          }
          name = "updateState";
          break;
        case "PrivateRates":
          this.state.rateCard.dataprivateRates.map((cells, dataIndex) => {
            if (location === dataIndex) {
              cells.cells.map((data, index) => {
                if (index === cellId) {
                  data.value = value;
                  data.isEdited = true;
                }
              });
            }
          });
          name = "updateState";
          break;
        case "regionalTrafficLimit":
          let regionalTrafficMax = false;
          let sum = 0;

          this.state.rateCard.regionalTrafficLimit.map((cells, dataIndex) => {
            cells.cells.map((data, index) => {
              if (index === cellId) {
                data.value = value;
              }
              if (data.value !== "") {
                sum = sum + parseInt(data.value);
              }
            });
          });
          if (sum > 100) {
            regionalTrafficMax = true;
          }

          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              regionalTrafficMixmaximum: regionalTrafficMax
            }
          }));
          this.props.onchangeDealStructure(
            "regionalTrafficLimit",
            this.state.rateCard.regionalTrafficLimit,
            this.state.rateCard.regionalTraffic
          );
          name = "updateState";
          break;
        case "PublicRates":
          this.state.rateCard.dataPublicRates.map((cells, dataIndex) => {
            if (location === dataIndex) {
              cells.cells.map((data, index) => {
                if (index === cellId) {
                  data.value = value;
                }
              });
            }
          });
          name = "updateState";
          break;
        case "regionalMix":
          let regionalMixMax = false;
          sum = 0;

          this.state.rateCard.regionalUsageMixValues.map((cells, dataIndex) => {
            cells.cells.map((data, index) => {
              if (index === cellId) {
                data.value = value;
              }
              if (data.value !== "") {
                sum = sum + parseFloat(data.value);
              }
            });
          });
          if (sum > 100 || sum < 100) {
            regionalMixMax = true;
          }

          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              regionalMixmaximum: regionalMixMax
            }
          }));
          this.props.onchangeDealStructure(
            "regionalMix",
            this.state.rateCard.regionalUsageMixValues
          );
          if (
            this.state.rateCard.combinedTraffic > 30 &&
            this.state.rateCard.regionalUsageMixValues[0] &&
            this.state.rateCard.regionalUsageMixValues[0].cells[2] &&
            this.state.rateCard.regionalUsageMixValues[0].cells[2].value > 10
          ) {
            this.props.onChangeAdditionalApproval("regionalLimitOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("regionalLimitOverride", false);
          }
          name = "updateState";
          break;
        case "combinedTraffic":
          if (isNaN(value)) {
            value = "";
          }
          if (value > 30) {
            this.state.rateCard.areaConveredOutside = true;
          } else {
            this.state.rateCard.areaConveredOutside = false;
          }
          if (
            value > 30 &&
            this.state.rateCard.regionalUsageMixValues[0] &&
            this.state.rateCard.regionalUsageMixValues[0].cells[2] &&
            this.state.rateCard.regionalUsageMixValues[0].cells[2].value > 10
          ) {
            this.props.onChangeAdditionalApproval("regionalLimitOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("regionalLimitOverride", false);
          }
          this.props.onchangeDealStructure(
            "combinedTraffic",
            value
          );
          break;
        case "Commitment Fee":
          var commitment_fee;
          var minimum_commitment_fee;
          var total_commitment_fee;
          var Aos_Selected = this.state.rateCard.Aos_Selected;
          Aos_Selected =
            parseInt(Aos_Selected) > 35
              ? Aos_Selected
              : value === false
                ? 15
                : 35;
          if (value === false) {
            commitment_fee = "";
            Aos_Selected = 15;
            minimum_commitment_fee = this.state.rateCard.minimum_commitment_fee;
            total_commitment_fee = this.state.rateCard.minimum_commitment_fee;
          } else {
            commitment_fee = this.state.rateCard.commitment_fee;
            minimum_commitment_fee = this.state.rateCard.commitment_fee;
            total_commitment_fee = this.state.rateCard.minimum_commitment_fee;
          }
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              VolumeCommitCheckBox: prevState.rateCard.VolumeCommitCheckBox.map(
                el =>
                  el.optionValue === name
                    ? { ...el, status: value }
                    : el.optionValue === "No Commit"
                      ? { ...el, status: false }
                      : el
              ),
              commitment_fee: commitment_fee,
              minimum_commitment_fee: minimum_commitment_fee,
              total_commitment_fee: minimum_commitment_fee,
              Aos_Selected: Aos_Selected
            }
          }));
          this.props.onchangeDealStructure(
            "Commitment Fee",
            commitment_fee !== "" ? "$ " + commitment_fee : ""
          );
          if (
            commitment_fee !== "" &&
            ((parseInt(commitment_fee.toString().replace(/,/g, "")) < 10000
              && parseInt(this.state.rateCard.TermLength) < 24) ||
              (parseInt(commitment_fee.toString().replace(/,/g, "")) < 15000
                && parseInt(this.state.rateCard.TermLength) >= 24))
          ) {
            this.props.onChangeAdditionalApproval("commitFeeOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("commitFeeOverride", false);
          }
          this.props.onChangeAdditionalApproval("noCommitDeal", false);
          this.props.onchangeDealStructure("NoCommit", false);
          this.props.onChangeCustomDeal("No Commit", false);
          this.checkVloumeCommitValues(name, value);
          break;
        case "No Commit":
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              VolumeCommitCheckBox: prevState.rateCard.VolumeCommitCheckBox.map(
                el =>
                  el.optionValue === name
                    ? { ...el, status: value }
                    : { ...el, status: false }
              ),
              commitment_fee: "",
              minimum_commitment_fee: "",
              total_commitment_fee: ""
            }
          }));
          this.props.onchangeDealStructure("Commitment Fee", "");
          this.props.onchangeDealStructure("Minimum Committed Revenue", "");
          this.props.onchangeDealStructure("NoCommit", value);
          this.state.rateCard.TypeOfCommit = [];
          this.props.onChangeCustomDeal("No Commit", value);
          this.checkVloumeCommitValues(name, value);
          if (value === true) {
            this.state.rateCard.regionalUsageMixValues.map((cells, dataIndex) => {
              cells.cells.map(d => {
                d.value = "";
              });
            });

            this.state.rateCard.dataTransferPriceNotTieredNoCommit.map((cells, dataIndex) => {
              cells.cells.map(d => {
                d.value = "";
              });
            });

            this.props.onchangeDealStructure("CFRC Type", "No Commit");
            this.props.onchangeDealStructure("Commit Type", "");
            this.props.onchangeDealStructure("Request fees", "");
            this.props.onChangeAdditionalApproval("noCommitDeal", true);
            this.props.onchangeDealStructure(
              "Commit Volume",
              "",
              "All"
            );
            this.props.onchangeDealStructure(
              "Minimum Average Object Size",
              this.state.rateCard.Aos_Selected_Nocommit
            );
          } else {
            //this.props.onchangeDealStructure("CFRC Type", "");
            this.props.onChangeAdditionalApproval("noCommitDeal", false);
            this.props.onchangeDealStructure(
              "Commit Volume",
              this.state.rateCard.requested_commit,
              "All",
              this.state.rateCard.geolocations,
              this.state.rateCard.volumeUnit
            );
            this.props.onchangeDealStructure(
              "Minimum Average Object Size",
              this.state.rateCard.Aos_Selected
            );
          }
          break;
        case "Volume Commit":
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              VolumeCommitCheckBox: prevState.rateCard.VolumeCommitCheckBox.map(
                el =>
                  el.optionValue === name
                    ? { ...el, status: value }
                    : el.optionValue === "No Commit"
                      ? { ...el, status: false }
                      : el
              )
            }
          }));
          if (value === true) {
            this.props.onchangeDealStructure(
              "Commit Volume",
              this.state.rateCard.requested_commit,
              "All",
              this.state.rateCard.geolocations,
              this.state.rateCard.volumeUnit
            );
          } else {
            this.props.onchangeDealStructure(
              "Commit Volume",
              "",
              "All",
              this.state.rateCard.geolocations,
              this.state.rateCard.volumeUnit
            );
          }
          if (!isCloudFrontShieldAdvancedDeal && value === true && this.state.rateCard.Aos_Selected < 15) {
            this.props.onChangeAdditionalApproval("aosVolumeOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("aosVolumeOverride", false);
          }
          if (!isCloudFrontShieldAdvancedDeal && value === false && this.state.rateCard.Aos_Selected < 35 && this.state.rateCard.VolumeCommitCheckBox[1].status === true) {
            this.props.onChangeAdditionalApproval("aosVolumeOverride", true);
          }
          this.props.onChangeAdditionalApproval("noCommitDeal", false);
          this.checkVloumeCommitValues(name, value);
          this.props.onchangeDealStructure("NoCommit", false);
          this.props.onChangeCustomDeal("No Commit", false);
          break;
        case "TermLength":
          if (isNaN(value)) {
            value = "";
          }
          this.props.onchangeDealStructure("TermLength", value);
          if (value < 12 || value > 36) {
            this.props.onChangeAdditionalApproval("termLength", true);
          } else {
            this.props.onChangeAdditionalApproval("termLength", false);
          }
          if (
            this.state.rateCard.commitment_fee !== "" &&
            ((parseInt(this.state.rateCard.commitment_fee.toString().replace(/,/g, "")) < 10000
              && parseInt(value) < 24) ||
              (parseInt(this.state.rateCard.commitment_fee.toString().replace(/,/g, "")) < 15000
                && parseInt(value) >= 24))
          ) {
            this.props.onChangeAdditionalApproval("commitFeeOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("commitFeeOverride", false);
          }
          break;
        case "TermLength_Nocommit":
          this.props.onchangeDealStructure("TermLength", value);
          break;
        case "Aos_Selected":
          if (isNaN(value)) {
            value = "";
          }
          if (
            (!isCloudFrontShieldAdvancedDeal && value < 15 &&
              this.state.rateCard.VolumeCommitCheckBox[0].status === true) ||
            (value < 35 &&
              this.state.rateCard.VolumeCommitCheckBox[1].status === true &&
              this.state.rateCard.VolumeCommitCheckBox[0].status === false)
          ) {
            this.props.onChangeAdditionalApproval("aosVolumeOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("aosVolumeOverride", false);
          }
          if (this.state.rateCard.request_fees === "Waive Request Fees") {
            this.props.onchangeDealStructure(
              "Minimum Average Object Size",
              value
            );
          } else {
            this.props.onchangeDealStructure("Minimum Average Object Size", "");
          }
          break;
        case "volumeUnit":
          let RequestCommit;
          if (value === "PB" && this.state.rateCard.geo === "All") {
            RequestCommit = 0.1;
          } else if (value === "TB" && this.state.rateCard.geo === "All") {
            RequestCommit = 10;
          } else {
            RequestCommit = 0;
          }
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              geolocations: prevState.rateCard.geolocations.map(obj =>
                Object.assign(obj, { optionValue: 0 })
              ),
              requested_commit: RequestCommit
            }
          }));
          break;
        case "total_commitment_fee":
          var totalExpError;
          if (
            parseFloat(value.replace(/,/g, "")) <
            parseFloat(
              this.state.rateCard.minimum_commitment_fee.replace(/,/g, "")
            )
          ) {
            totalExpError =
              "Total Expected Revenue should be greater than or equal to Minimum Committed Revenue ";
          } else {
            totalExpError = "";
          }
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              totalExpError: totalExpError
            }
          }));
          break;
        case "FORMGROUP":
          this.state.rateCard.formGroup.map((cells, dataIndex) => {
            if (location === dataIndex) {
              cells.cells.map((data, index) => {
                if (index === cellId) {
                  data.value = value;
                }
              });
            }
          });
          this.props.onchangeDealStructure(
            "AdditionalPricingTerms",
            this.state.rateCard.formGroup,
            this.state.rateCard.pricingTerms
          );
          name = "updateState";
          break;
        case "FormGroupCorePlus":
          this.state.rateCard.formGroup.map((cells, dataIndex) => {
            if (location === dataIndex) {
              cells.corePlusOption = value;
            }
          });
          name = "updateState";
          break;
        case "requested_commit":
          if (isNaN(value)) {
            value = "";
          }
          if (location !== "All") {
            const newGeoVolumeCommitErrors = { ...this.state.rateCard.geoVolumeCommitErrors };
            if(isCloudFrontShieldAdvancedDeal) {
              const commitAcceptable = isCommitAcceptable(dealInfo.cloudFrontShieldAdvancedDeal, value);
              const minCommit = getCloudFrontShieldAdvancedMinCommit(dealInfo.cloudFrontShieldAdvancedDeal);
              const error = `Monthly commit must be greater than ${minCommit} TB, please increase commit.`;
              newGeoVolumeCommitErrors[location] = commitAcceptable ? undefined : error;
            }
            this.setState(
              prevState => ({
                rateCard: {
                  ...prevState.rateCard,
                  geolocations: prevState.rateCard.geolocations.map(el =>
                    el.optionKey === location ? { ...el, optionValue: value } : el
                  ),
                  geoVolumeCommitErrors: newGeoVolumeCommitErrors
                }
              }),
              () => {
                this.props.onchangeDealStructure(
                  "Commit Volume",
                  value,
                  location,
                  this.state.rateCard.geolocations,
                  this.state.rateCard.volumeUnit
                );
              }
            );
            name = "chooseByGeo";
          } else {
            this.props.onchangeDealStructure(
              "Commit Volume",
              value,
              location,
              "",
              this.state.rateCard.volumeUnit
            );
          }
          break;
        case "request_fees":
          if (value === "Waive Request Fees") {
            let aosSelected = (this.state.rateCard.VolumeCommitCheckBox[1].status === true) ? 35 : 15;
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                Aos_Selected: aosSelected
              }
            }));
            this.props.onchangeDealStructure(
              "Minimum Average Object Size",
              this.state.rateCard.Aos_Selected
            );
          } else {
            if (value === "Apply Private Rates") {
              this.setPrivateRates();
            }
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                Aos_Selected: 35
              }
            }));
            this.props.onchangeDealStructure("Minimum Average Object Size", "");
          }
          this.props.onchangeDealStructure("Request fees", value);
          this.props.onChangeAdditionalApproval("aosVolumeOverride", false);
          break;
        case "regionalTraffic":
          this.props.onchangeDealStructure("Traffic Limit", value);
          break;
        case "commitment_fee":
          if (
            this.state.rateCard.VolumeCommitCheckBox[1].status === true &&
            this.state.rateCard.VolumeCommitCheckBox[0].status === false
          ) {
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                minimum_commitment_fee: value,
                total_commitment_fee: value
              }
            }));
            this.props.onchangeDealStructure(
              "Minimum Committed Revenue",
              value !== "" ? "$ " + value : ""
            );
          }
          this.props.onchangeDealStructure(
            "Commitment Fee",
            value !== "" ? "$ " + value : ""
          );
          if (
            value !== "" &&
            ((parseInt(value.toString().replace(/,/g, "")) < 10000
              && parseInt(this.state.rateCard.TermLength) < 24) ||
              (parseInt(value.toString().replace(/,/g, "")) < 15000
                && parseInt(this.state.rateCard.TermLength) >= 24))
          ) {
            this.props.onChangeAdditionalApproval("commitFeeOverride", true);
          } else {
            this.props.onChangeAdditionalApproval("commitFeeOverride", false);
          }
          break;
        case "pricingTerms":
          this.props.onchangeDealStructure(
            "AdditionalPricingTerms",
            this.state.rateCard.formGroup,
            value
          );
          break;
        case "tieredPricing":
          this.tierPricingLogic(value);
          break;
        case "tieredPricing_noCommit":
          if (value === true) {
            this.props.onchangeDealStructure(
              "datasource",
              this.state.rateCard.dataTransferPriceTieredNoCommit
            );
          } else {
            this.props.onchangeDealStructure(
              "datasource",
              this.state.rateCard.dataTransferPriceNotTieredNoCommit
            );
          }
          break;
        default:
      }
      this.setState(
        prevState => ({
          rateCard: {
            ...prevState.rateCard,
            [name]: value
          }
        }),
        () => {
          this.props.onChangeCustomDeal(
            "PricingTerms",
            this.state.rateCard.pricingTerms
          );
          if (type !== 'Input') {
            this.getCommitmentfee(name);
          }
          this.checkVolumeCommitFlag();
          this.props.onchangeDealStructure("LefNav", this.state.rateCard);
        }
      );
    }
  }

  checkPrivatePricingEdit = (value, name, type) => {
    let alertMessage = false;
    if (Constants.textAreaQuestions.includes(name)) {
      const rateCardObj = { ...this.state.rateCardClone }
      if (rateCardObj[name] && rateCardObj[name] !== value) {
        alertMessage = true;
      }
    } else {
      if (!Constants.inputFieldsforAlert.includes(name) && type !== "Slider") {
        alertMessage = true;
      }
      if ((name === "Aos_Selected" || name === "TermLength" || name === "requested_commit") && type === "Slider") {
        alertMessage = true;
      }
    }
    if (alertMessage) {
      this.setState({
        visible: true
      })
    }
  }

  checkVolumeCommitFlag() {
    let VolumeCommitError = '';
    let minLimit = 10;

    if (this.state.rateCard.VolumeCommitCheckBox[0].status === true) {
      if (getIsCloudFrontShieldAdvancedDeal(this.props.dealInfo)) {
        if (this.props.dealInfo.cloudFrontShieldAdvancedDeal === CLOUD_FRONT_SHIELD_ADVANCED_ENTERPRISE) {
          minLimit = CLOUDFRONT_SHIELD_ADVANCED_ENTERPRISE_MIN_COMMIT;
        }
      }
      const errorMessage = `Monthly commit must be greater than ${minLimit} TB. Please increase commit.`;
      if (this.state.rateCard.requested_commit !== '') {
        const volumeCommit = this.convertVolumeCommit(this.state.rateCard.requested_commit, this.state.rateCard.volumeUnit);
        VolumeCommitError = this.getIsVolumeCommitError(
                              this.state.rateCard.TypeOfCFRL,
                              volumeCommit,
                              this.state.rateCard.TermLength,
                              minLimit
                            ) ? errorMessage : '';
      } else {
        VolumeCommitError = errorMessage;
      }
    }

    this.setState(prevState => ({
      rateCard: {
        ...prevState.rateCard,
        VolumeCommitError
      }
    }));
  }

  /**
   * This function is used to determine if there's a volume commit error
   * @param {string} typeOfCFRL type of commit i.e. Monthly, Bucket, Annual
   * @param {number} requestedCommit amount of commit entered
   * @param {number} termLength length of term entered
   * @param {number} minLimit minimum limit allowed
   * @returns {boolean} whether to display a volume commit error
   */
  getIsVolumeCommitError(typeOfCFRL, requestedCommit, termLength, minLimit) {
    if (typeOfCFRL === 'Monthly') {
      return (requestedCommit < minLimit);
    } else if (typeOfCFRL === 'Bucket') {
      return ((requestedCommit / termLength) < minLimit);
    } else if (typeOfCFRL === 'Annual') {
      return ((requestedCommit / 12) < minLimit);
    }
  
    return false;
  }

  /**
   * 
   * @param {string} requestedCommit amount of commit entered
   * @param {number} volumeUnit unit of volume ie. TB, PB
   * @returns 
   */
  convertVolumeCommit(requestedCommit, volumeUnit) {
    const requestedCommitVal = parseFloat(requestedCommit);
    return volumeUnit === 'PB' ? (requestedCommitVal * 1024) : requestedCommitVal;
  }

  /**
   * This function used calculate the Tier Pricing Logic
   * @param {*} value selected option which need to update in the state
   */
  tierPricingLogic = value => {
    var TBH, TBL = 0;
    var request_commit;
    if (value === true) {
      let data = this.state.rateCard.defaultRateCardRates;
      _.each(data, rowVal => {
        this.state.rateCard.dataTransferPriceTiered.map((cells, index) => {
          if (index === 0) {
            TBL = this.state.rateCard.volumeUnit === "PB" ? 0.1 : 10;
            if (this.state.rateCard.geo === "All") {
              TBH = this.state.rateCard.requested_commit;
            } else {
              let GeoValues = this.state.rateCard.geolocations;
              TBH = GeoValues.reduce(
                (min, p) =>
                  p.optionValue !== 0 && p.optionValue < min
                    ? p.optionValue
                    : min,
                GeoValues[0].optionValue
              );
            }
            cells.cells.map(d => {
              if (d.tierKey === rowVal.geo) {
                d.value = rowVal.price;
                d.isEdited = false;
              }
              if (d.tierKey === "ALL") {
                d.value = "";
              }
              if (d.tierKey === ">TB") {
                d.value = TBL;
              }
              if (d.tierKey === "<TB") {
                d.value = parseFloat(TBH);
              }
            });
          } else if (index === 1) {
            if (this.state.rateCard.geo === "All") {
              request_commit = this.state.rateCard.requested_commit;
            } else {
              let GeoValues = this.state.rateCard.geolocations;
              request_commit = GeoValues.reduce(
                (min, p) =>
                  p.optionValue !== 0 && p.optionValue < min
                    ? p.optionValue
                    : min,
                GeoValues[0].optionValue
              );
            }
            if (this.state.rateCard.volumeUnit === "PB") {
              TBL = parseFloat(request_commit) + 0.1;
            } else {
              TBL = parseInt(request_commit) + 1;
            }
            cells.cells.map(d => {
              if (d.tierKey === ">TB") {
                d.value = TBL;
              } else {
                d.value = "";
              }
            });
          } else {
            cells.cells.map(d => {
              d.value = "";
            });
          }
        });
      });
      if (this.state.rateCard.defaultRateError !== "") {
        this.state.rateCard.dataTransferPriceTiered.map((cells, index) => {
          if (index === 0) {
            TBL = this.state.rateCard.volumeUnit === "PB" ? 0.1 : 10;
            cells.cells.map(d => {
              if (d.tierKey === "ALL") {
                d.value = "";
              }
              else if (d.tierKey === ">TB") {
                d.value = TBL;
              }
              else if (d.tierKey === "<TB") {
                d.value = parseFloat(this.state.rateCard.requested_commit);
              } else {
                d.value = "";
              }
            });
          } else if (index === 1) {
            if (this.state.rateCard.geo === "All") {
              request_commit = this.state.rateCard.requested_commit;
            } else {
              let GeoValues = this.state.rateCard.geolocations;
              request_commit = GeoValues.reduce(
                (min, p) =>
                  p.optionValue !== 0 && p.optionValue < min
                    ? p.optionValue
                    : min,
                GeoValues[0].optionValue
              );
            }
            if (this.state.rateCard.volumeUnit === "PB") {
              TBL = parseFloat(request_commit) + 0.1;
            } else {
              TBL = parseInt(request_commit) + 1;
            }
            cells.cells.map(d => {
              if (d.tierKey === ">TB") {
                d.value = TBL;
              } else {
                d.value = "";
              }
            });
          } else {
            cells.cells.map(d => {
              d.value = "";
            });
          }
        });
      }
      this.setState(prevState => ({
        rateCard: {
          ...prevState.rateCard,
          minimum_commitment_fee: this.state.rateCard.standardMinimumRevenue,
          total_commitment_fee: this.state.rateCard.standardMinimumRevenue,
          dealEdited: false,
          dataTransferError: "",
          dataTransfertableError: ""
        }
      }));
    } else {

      let data = this.state.rateCard.defaultRateCardRates;
      _.each(data, rowVal => {
        this.state.rateCard.dataTransferPriceNotTiered.map(cells => {
          cells.cells.map(d => {
            if (d.tierKey === rowVal.geo) {
              d.value = rowVal.price;
              d.isEdited = false;
            }
          });
        });
      });

      let StandardRecord = [];
      _.each(this.state.rateCard.defaultRateCardRates, rowVal => {
        StandardRecord[rowVal.geo] = rowVal.price;
      });
      customRateCardObject[0] = StandardRecord;

      this.setState(prevState => ({
        rateCard: {
          ...prevState.rateCard,
          minimum_commitment_fee: this.state.rateCard.standardMinimumRevenue,
          total_commitment_fee: this.state.rateCard.standardMinimumRevenue,
          dealEdited: false,
          dataTransferError: "",
          dataTransfertableError: "",
          tieredPricing: false
        }
      }));
    }
    this.props.onchangeDealStructure("dealEdited", false);
    this.props.onChangeAdditionalApproval("customRateCard", false);
    this.props.onChangeCustomDeal("customRateCard", false);
    this.props.onchangeDealStructure(
      "Minimum Committed Revenue",
      "$ " + this.state.rateCard.standardMinimumRevenue
    );
    this.props.onchangeDealStructure(
      "datasource",
      this.state.rateCard.dataTransferPriceNotTiered
    );
  };

  checkPublicRates(key) {
    const { cloudFrontShieldAdvancedDeal } = this.props.dealInfo;
    const isCloudFrontShieldAdvanced = isCloudFrontShieldAdvancedDeal(cloudFrontShieldAdvancedDeal);
    let requestCommit;
    let dataValues =
      this.state.rateCard.tieredPricing === true
        ? this.state.rateCard.dataTransferPriceTiered
        : this.state.rateCard.dataTransferPriceNotTiered;
    if (this.state.rateCard.VolumeCommitCheckBox[1].status === true && this.state.rateCard.VolumeCommitCheckBox[0].status === false) {
      requestCommit = 10;
    } else {
      if (this.state.rateCard.volumeUnit === "PB") {
        requestCommit =
          parseFloat(this.state.rateCard.requested_commit) * Constants.multiplePegabyte;
        if (this.state.rateCard.geo !== "All") {
          requestCommit =
            parseFloat(
              this.state.rateCard.geolocations[0].optionValue
            ) * Constants.multiplePegabyte;
        }
      } else {
        requestCommit = parseFloat(this.state.rateCard.requested_commit);
        if (this.state.rateCard.geo !== "All") {
          requestCommit = parseFloat(
            this.state.rateCard.geolocations[0].optionValue
          );
        }
      }
    }

    let newDataArray = [];
    newDataArray = {
      TB: parseFloat(requestCommit),
      TypeOfCFRL: this.state.rateCard.TypeOfCFRL,
      TermLength: parseInt(this.state.rateCard.TermLength),
      isCloudFrontShieldAdvanced,
      values: []
    };



    dataValues.forEach((dataSource, dataIndex) => {
      if (dataIndex === 0) {
        if (this.state.rateCard.geo === "All") {
          if (key === "ALL") {
            dataSource.cells.map(d => {
              if (
                d.tierKey === "ALL" &&
                d.tierKey !== "<TB" &&
                d.tierKey !== ">TB" &&
                d.value !== "" &&
                customRateCardObject[dataIndex]
              ) {
                newDataArray.values.push({
                  geo: d.tierKey,
                  price: {
                    edited: parseFloat(d.value),
                    RC1: parseFloat(customRateCardObject[dataIndex]["NA"])
                  }
                });
              }
            });
          } else {
            dataSource.cells.map(d => {
              if (
                d.tierKey !== "ALL" &&
                d.tierKey !== "<TB" &&
                d.tierKey !== ">TB" &&
                d.value !== "" &&
                customRateCardObject[dataIndex]
              ) {
                newDataArray.values.push({
                  geo: d.tierKey,
                  price: {
                    edited: parseFloat(d.value),
                    RC1: parseFloat(customRateCardObject[dataIndex][d.tierKey])
                  }
                });
              }
            });
          }
        } else {
          if (key === "ALL") {
            dataSource.cells.map(d => {
              if (
                d.tierKey === "ALL" &&
                d.tierKey !== "<TB" &&
                d.tierKey !== ">TB" &&
                d.value !== ""
              ) {
                newDataArray.values.push({
                  geo: d.tierKey,
                  price: {
                    edited: parseFloat(d.value),
                    RC1: parseFloat(customRateCardObject[dataIndex]["NA"])
                  }
                });
              }
            });
          } else {
            dataSource.cells.map(d => {
              if (
                d.tierKey !== "ALL" &&
                d.tierKey !== "<TB" &&
                d.tierKey !== ">TB" &&
                d.value !== ""
              ) {
                const tierRC1 = customRateCardObject[dataIndex][d.tierKey];
                if (tierRC1 !== undefined) {
                  newDataArray.values.push({
                    geo: d.tierKey,
                    price: {
                      edited: parseFloat(d.value),
                      RC1: parseFloat(tierRC1)
                    }
                  });
                }
              }
            });
          }
        }

        if (newDataArray) {
          this.showDealStructureLoadingToast("Checking with public rates", true);
          axios({
            method: "POST",
            url: `${config.pricingIntakeUrl}publicrate`,
            data: newDataArray
          }).then(resp => {
            let dataTransferError = "";
            if (resp.data.statusCode && resp.data.statusCode === 400) {
              dataTransferError = resp.data.message;
            }
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                dataTransfertableError: dataTransferError
              }
            }));
            this.props.onchangeDealStructure("LefNav", this.state.rateCard);
            this.showDealStructureLoadingToast("Checking with public rates", false);
          }).catch(error => {

          });
        }

      }
    });

  }

  checkPublicRatesforNoCommit(key) {
    let requestCommit;
    let dataValues =
      this.state.rateCard.tieredPricing_noCommit === true
        ? this.state.rateCard.dataTransferPriceTieredNoCommit
        : this.state.rateCard.dataTransferPriceNotTieredNoCommit;

    requestCommit = 10;

    let newDataArray = [];
    newDataArray = {
      TB: parseFloat(requestCommit),
      TypeOfCFRL: 'Monthly',
      TermLength: parseInt(this.state.rateCard.TermLength),
      values: []
    };



    dataValues.forEach((dataSource, dataIndex) => {
      if (dataIndex === 0) {
        if (key === "ALL") {
          dataSource.cells.map(d => {
            if (
              d.tierKey === "ALL" &&
              d.tierKey !== "<TB" &&
              d.tierKey !== ">TB" &&
              d.value !== ""
            ) {
              newDataArray.values.push({
                geo: d.tierKey,
                price: {
                  edited: parseFloat(d.value),
                  RC1: 0
                }
              });
            }
          });
        } else {
          dataSource.cells.map(d => {
            if (
              d.tierKey !== "ALL" &&
              d.tierKey !== "<TB" &&
              d.tierKey !== ">TB" &&
              d.value !== ""
            ) {
              newDataArray.values.push({
                geo: d.tierKey,
                price: {
                  edited: parseFloat(d.value),
                  RC1: 0
                }
              });
            }
          });
        }


        if (newDataArray) {
          this.showDealStructureLoadingToast("Checking with public rates", true);
          axios({
            method: "POST",
            url: `${config.pricingIntakeUrl}publicrate`,
            data: newDataArray
          }).then(resp => {
            let dataTransferError = "";
            if (resp.data.statusCode && resp.data.statusCode === 400) {
              dataTransferError = resp.data.message;
            }
            this.setState(prevState => ({
              rateCard: {
                ...prevState.rateCard,
                dataTransfertableErrorNoCommit: dataTransferError
              }
            }));
            this.props.onchangeDealStructure("LefNav", this.state.rateCard);
            this.showDealStructureLoadingToast("Checking with public rates", false);
          }).catch(error => {

          });
        }

      }
    });

  }

  /**
   * This function used update the type of commit array and make changes based on selection
   * @param {*} value selected option which need to update in the state
   * @param {*} name in which name of the particular element value to be set
   */
  checkVloumeCommitValues(name, value) {
    var idx = this.state.rateCard.TypeOfCommit.indexOf(name);
    if (idx === -1 && value === true && name !== "No Commit") {
      this.state.rateCard.TypeOfCommit.push(name);
    } else {
      this.state.rateCard.TypeOfCommit.splice(idx, 1);
    }
    if (
      this.state.rateCard.TypeOfCommit.length === 0 &&
      name !== "No Commit " &&
      value !== true
    ) {
      this.setState(prevState => ({
        rateCard: {
          ...prevState.rateCard,
          VolumeCommitCheckBox: prevState.rateCard.VolumeCommitCheckBox.map(
            el =>
              el.optionValue === "Volume Commit" ? { ...el, status: true } : el
          )
        }
      }));
      this.state.rateCard.TypeOfCommit.push("Volume Commit");
      this.getCommitmentfee("Aos_Selected");
    }
  }

  /**
   * This function used update the local state value of each input element
   * and call the commitment Fee calcualtion based on value change
   * @param {*} value typed or selected option which need to update in the state
   * @param {*} name in which name of the particular element value to be set
   * @param {*} rowId the array row location where the values chaned
   * @param {*} cellId cell id of table when any table data is inserted to make change in particular cell
   * @param {*} key key name of the particular cell
   */
  handleInputBlur(value, name, rowId, cellId, key) {
    let { userRole } = this.props;
    if (Constants.inputBlurParams.includes(name)) {
      this.getCommitmentfee(name, key);
    }

    if (roleNames.PRIVATE_PRICING_USERS.includes(userRole)) {
      this.setState({
        visible: true
      })
    }

    if (value !== "") {
      if (name === "dataTransferInput") {
        let dealEdited = this.state.rateCard.dealEdited;
        if (key === "ALL") {
          if (this.state.rateCard.tieredPricing === true) {
            this.state.rateCard.dataTransferPriceTiered.map(
              (cells, dataIndex) => {
                if (rowId === dataIndex) {
                  cells.cells.map(data => {
                    if (data.tierKey !== ">TB" && data.tierKey !== "<TB") {
                      if (this.state.rateCard.geo === "All") {
                        data.value = value;
                      } else {
                        if (data.value !== "") {
                          data.value = value;
                        }
                      }
                    }
                  });
                }
              }
            );
          } else {
            this.state.rateCard.dataTransferPriceNotTiered.map(cells => {
              cells.cells.map(data => {
                if (this.state.rateCard.geo === "All") {
                  data.value = value;
                } else {
                  if (data.value !== "") {
                    data.value = value;
                  }
                }
              });
            });
          }
          this.state.rateCard.regionalUsageMixValues.map((cells, dataIndex) => {
            cells.cells.map(d => {
              d.value = "";
            });
          });
          dealEdited = true;
        } else if (key === "<TB") {

          let TBL;
          let TBH;
          this.state.rateCard.dataTransferPriceTiered.map(
            (cells, dataIndex) => {
              if (rowId === dataIndex) {
                cells.cells.map(data => {
                  if (data.tierKey === "<TB") {
                    TBH = data.value;
                  }
                  if (data.tierKey === ">TB") {
                    TBL = data.value;
                  }
                });
              }
            }
          );
          let dataTransferError = "";
          if (parseFloat(TBL) > parseFloat(TBH)) {
            dataTransferError = "<TB value should be greater than >TB";
            this.state.rateCard.dataTransferPriceTiered.map(
              (cells, dataIndex) => {
                if (rowId === dataIndex) {
                  cells.cells.map(data => {
                    if (data.tierKey !== "<TB" && data.tierKey !== ">TB") {
                      data.value = "";
                    }
                  });
                }
              }
            );
          } else {
            dataTransferError = "";
          }
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              dataTransfertableError: dataTransferError,
              defaultRateError: ""
            }
          }));
          if (dataTransferError === "") {
            let nextRow = parseInt(rowId) + 1;
            this.state.rateCard.dataTransferPriceTiered.map(
              (cells, dataIndex) => {
                if (dataIndex === nextRow) {
                  cells.cells.map(d => {
                    if (d.tierKey === ">TB") {
                      d.value =
                        this.state.rateCard.volumeUnit === "PB"
                          ? parseFloat(value) + 0.1
                          : parseFloat(value) + 1;
                    } else {
                      d.value = "";
                    }
                  });
                }
              }
            );
          }
        } else {
          if (
            customRateCardObject[rowId] &&
            customRateCardObject[rowId][key] &&
            customRateCardObject[rowId][key] > value
          ) {
            this.state.rateCard.regionalUsageMixValues.map(
              (cells, dataIndex) => {
                cells.cells.map(d => {
                  d.value = "";
                });
              }
            );
          }
        }
        if (key !== ">TB" && key !== "<TB") {
          if (rowId === 0) {
            this.calculateDeal(key);
          }
        }
        /** check previous tiles values */
        if (this.state.rateCard.tieredPricing === true) {
          let dataTransferError = "";
          let tierValues = this.state.rateCard.dataTransferPriceTiered;
          this.state.rateCard.dataTransferPriceTiered.map((row, dataIndex) => {
            if (dataIndex !== 0) {
              row.cells.map((data, valIndex) => {
                if (data.tierKey !== ">TB" && data.tierKey !== "<TB") {
                  if (
                    tierValues[dataIndex - 1].cells[valIndex] &&
                    data.value !== "" &&
                    tierValues[dataIndex - 1].cells[valIndex].value !== ""
                  ) {
                    if (
                      parseFloat(data.value) >
                      tierValues[dataIndex - 1].cells[valIndex].value
                    ) {
                      data.value = "";
                      dataTransferError =
                        "Tier Pricing: Entered value should be less than or equal to previous tier value";
                    }
                  }
                }
              });
            } else {
              let dealEdited = this.state.rateCard.dealEdited;
              row.cells.map((data) => {
                if (data.tierKey !== ">TB" && data.tierKey !== "<TB") {
                  if (data.value !== "") {
                    if (
                      customRateCardObject[dataIndex] &&
                      customRateCardObject[dataIndex][data.tierKey] &&
                      customRateCardObject[dataIndex][data.tierKey] > data.value
                    ) {
                      dealEdited = true;
                    }
                  }
                }
              });
              this.setState(prevState => ({
                rateCard: {
                  ...prevState.rateCard,
                  dealEdited: dealEdited
                }
              }));
              this.props.onchangeDealStructure("dealEdited", dealEdited);
              this.props.onChangeAdditionalApproval("customRateCard", dealEdited);
            }
          });
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              dataTransfertableError: dataTransferError,
              defaultRateError: ""
            }
          }));
        } else {
          let dealEdited = false;
          const { cloudFrontShieldAdvancedDeal } = this.props.dealInfo;
          const isCloudFrontShieldAdvanced = isCloudFrontShieldAdvancedDeal(cloudFrontShieldAdvancedDeal);
          if (!isCloudFrontShieldAdvanced || this.state.dataTransfertableError === "") {
            this.state.rateCard.dataTransferPriceNotTiered.forEach((row, dataIndex) => {
              row.cells.forEach((data) => {
                if (data.value !== "") {
                  if (
                    customRateCardObject[dataIndex] &&
                    customRateCardObject[dataIndex][data.tierKey] &&
                    customRateCardObject[dataIndex][data.tierKey] > data.value
                  ) {
                    dealEdited = true;
                  }
                }
              });
            });
          }
          this.setState(prevState => ({
            rateCard: {
              ...prevState.rateCard,
              dealEdited: dealEdited,
              defaultRateError: ""
            }
          }));
          this.props.onchangeDealStructure("dealEdited", dealEdited);
          this.props.onChangeAdditionalApproval("customRateCard", dealEdited);
        }
      }
      if (name === "regionalTrafficLimit") {
        let ShowGlobal = false;
        this.state.rateCard.regionalTrafficLimit.map((cells, dataIndex) => {
          cells.cells.map((data, index) => {
            if (index === 0) {
              if (parseInt(data.value) < 21) {
                ShowGlobal = true;
              }
            }
          });
        });
        this.setState(prevState => ({
          rateCard: {
            ...prevState.rateCard,
            regionalGlobalPopup: ShowGlobal
          }
        }));
      }
      if (name === "PrivateRates") {
        let standardprivateRates = this.state.rateCard.standardprivateRates;
        let privaterateEdited = false;
        this.state.rateCard.dataprivateRates.map((row, dataIndex) => {
          row.cells.map((data) => {
            if (data.value !== "") {
              if (
                standardprivateRates[dataIndex] &&
                standardprivateRates[dataIndex][data.tierKey] &&
                standardprivateRates[dataIndex][data.tierKey] > data.value
              ) {
                privaterateEdited = true;
              }
            }
          });
        });
        this.setState(prevState => ({
          rateCard: {
            ...prevState.rateCard,
            privaterateEdited: privaterateEdited
          }
        }));
        this.props.onChangeAdditionalApproval("privateRatesFlag", privaterateEdited);
      }
      if (name === "dataTransferPricenoCommit") {
        if (key === "ALL") {
          if (this.state.rateCard.tieredPricing_noCommit === true) {
            this.state.rateCard.dataTransferPriceTieredNoCommit.map(
              (cells, dataIndex) => {
                if (rowId === dataIndex) {
                  cells.cells.map(data => {
                    if (data.tierKey !== ">TB" && data.tierKey !== "<TB") {
                      data.value = value;
                    }
                  });
                }
              }
            );
          } else {
            this.state.rateCard.dataTransferPriceNotTieredNoCommit.map(cells => {
              cells.cells.map(data => {
                data.value = value;
              });
            });
          }
        }
        if (key !== ">TB" && key !== "<TB") {
          if (rowId === 0) {
            this.checkPublicRatesforNoCommit(key);
          }
        }
      }
    } else {
      if (name === "dataTransferInput") {
        let sumOfInsertedValue = 0;
        let ErrorStatus;
        if (this.state.rateCard.tieredPricing === true) {
          this.state.rateCard.dataTransferPriceTiered.map((cells) => {
            cells.cells.map((data) => {
              if (data.tierKey !== ">TB" && data.tierKey !== "<TB") {
                if (data.value !== "") {
                  sumOfInsertedValue = sumOfInsertedValue + parseFloat(data.value);
                }
              }
            })
          })
          ErrorStatus = (sumOfInsertedValue > 0) ? true : false;
        } else {
          this.state.rateCard.dataTransferPriceNotTiered.map((cells) => {
            cells.cells.map((data) => {
              if (data.value !== "") {
                sumOfInsertedValue = sumOfInsertedValue + parseFloat(data.value);
              }
            })
          })
          ErrorStatus = (sumOfInsertedValue > 0) ? true : false;
        }
        let errorMessage = (ErrorStatus === false) ? "Please enter the rates manually or try changing the parameters above" : "";
        this.setState(prevState => ({
          rateCard: {
            ...prevState.rateCard,
            defaultRateError: errorMessage
          }
        }));
      }
    }
  }

  handleErrorForRateCardApi(err) {
    console.error(err);
    this.setState(prevState => ({
      rateCard: {
        ...prevState.rateCard,
        defaultRateError: "",
        dataTransfertableError: ""
      }
    }));
    this.showDealStructureLoadingToast("Failed to retrive Rates. Please try after sometime", true);
    setTimeout(() => {
      this.showDealStructureLoadingToast("Failed to retrive Rates. Please try after sometime", false);
    }, 5000);
  }

  /**
   * This function is used to find the rate card values based on selection of values
   * and it will also return the minimum commitment revenue for the values selected
   * @param {*} name name of the element changed , based on the it will call the API
   */
  getCommitmentfee(name, key) {
    const isApiFieldChange = Constants.apiParams.includes(name);
    const requiredFieldsHaveValues = this.state.rateCard.TypeOfCFRL !== "" &&
      this.state.rateCard.Aos_Selected !== "" &&
      this.state.rateCard.volumeUnit !== "" &&
      this.state.rateCard.TermLength !== "" &&
      this.state.rateCard.geo !== "";
    const geoIsAll = this.state.rateCard.geo === "All";
    const commitTypes = this.state.rateCard.TypeOfCommit;
    const hasCommit = commitTypes.length > 0;

    const { Aos_Selected, commitment_fee, dataTransferPriceNotTiered, dataTransferPriceTiered, geolocations, requested_commit, TermLength, tieredPricing, TypeOfCFRL, TypeOfCommit, volumeUnit } = this.state.rateCard;
    const { cloudFrontShieldAdvancedDeal } = this.props.dealInfo;
    const isCloudFrontShieldAdvanced = isCloudFrontShieldAdvancedDeal(cloudFrontShieldAdvancedDeal);
    const cloudFrontShieldAdvancedType = getCloudFrontShieldAdvancedTypeForRateCard(cloudFrontShieldAdvancedDeal);
    const apiBody = getRateCardApiBody(
      requested_commit,
      TypeOfCFRL,
      TypeOfCommit,
      Aos_Selected,
      TermLength,
      commitment_fee,
      volumeUnit,
      geoIsAll,
      geolocations,
      isCloudFrontShieldAdvanced,
      cloudFrontShieldAdvancedType
    );

    if (isApiFieldChange && requiredFieldsHaveValues && hasCommit && apiBody.length > 0) {
      this.showDealStructureLoadingToast("Loading Rates", true);
      const { saveEnteredRates } = this.state.rateCard;
      const isVolumeCommit = this.state.rateCard.VolumeCommitCheckBox[0].status === true;
      const standardRateCard = {};
      ApiRequest.post(RATE_CARD_API_URL, undefined, apiBody).then(resp => {
        const minimumCommitmentRevenue = parseRevenueFromApi(isVolumeCommit, resp.data.Minimum_commit_revenue, this.state.rateCard.commitment_fee);
        const rateCardData = resp.data.data;
        rateCardData.forEach(rate => {
          standardRateCard[rate.geo] = rate.price;
        });
        customRateCardObject[0] = standardRateCard;

        const dealEdited = !isCloudFrontShieldAdvanced && isDealEdited(saveEnteredRates, tieredPricing, dataTransferPriceNotTiered, standardRateCard);
        const defaultRateCardRates = rateCardData && rateCardData.length > 0 ? rateCardData : this.state.rateCard.defaultRateCardRates;
        const updatedRateCardState = getUpdatedStateFromRateCardApi(
          rateCardData,
          standardRateCard,
          saveEnteredRates,
          minimumCommitmentRevenue,
          dealEdited,
          defaultRateCardRates,
          dataTransferPriceNotTiered,
          dataTransferPriceTiered,
          isCloudFrontShieldAdvanced
        );
        const prevMinimumCommit = this.state.rateCard.minimum_commitment_fee;

        this.setState(prevState => ({
          rateCard: {
            ...prevState.rateCard,
            ...updatedRateCardState
          }
        }), () => {
          this.showDealStructureLoadingToast("Loading Rates", false);
          this.props.onChangeAdditionalApproval("customRateCard", dealEdited);
          this.props.onchangeDealStructure(
            "Minimum Committed Revenue",
            "$ " + minimumCommitmentRevenue
          );
          this.props.onChangeCustomDeal("customRateCard", dealEdited);
          this.props.onchangeDealStructure("dealEdited", dealEdited);
          if (saveEnteredRates && geoIsAll) {
            this.calculateDeal(key, prevMinimumCommit);
          } else {
            this.props.onchangeDealStructure("datasource", this.state.rateCard.dataTransferPriceNotTiered);
          }
          this.props.onchangeDealStructure("LefNav", this.state.rateCard);
        });

      }).catch(err => this.handleErrorForRateCardApi(err));
    }
  }

  /**
   * This function call backend api to get the request Fees in admin module
   * @param {*} rate which is a type of rate Card
   */
  setPrivateRates = () => {
    this.showDealStructureLoadingToast("Loading Private rates...", true);
    let privateRatesApi = `${config.adminrateCardbaseUrl}privateRates`;
    axios({
      method: "GET",
      url: privateRatesApi
    }).then(resp => {
      let privaterates = resp.data;
      this.state.rateCard.dataprivateRates.map((cells, dataIndex) => {
        cells.cells.map(d => {
          d.value = privaterates[dataIndex][d.tierKey] ? privaterates[dataIndex][d.tierKey] : dataIndex === 0 ? "Http" : "Https";
          d.isEdited = false;
        });
      });
      this.setState(prevState => ({
        rateCard: {
          ...prevState.rateCard,
          standardprivateRates: resp.data,
          privaterateEdited: false
        }
      }));
      this.showDealStructureLoadingToast("Loading Private rates...", false);
    });
    this.props.onchangeDealStructure("PrivateRates", this.state.rateCard.dataprivateRates);
    this.props.onChangeAdditionalApproval("privateRatesFlag", false);
  };

  /**
   * This function used to load the standard rates after user Edited the rate card values
   */
  loadStandardPrivateRates() {
    let privaterates = this.state.rateCard.standardprivateRates;
    this.state.rateCard.dataprivateRates.map((cells, dataIndex) => {
      cells.cells.map(d => {
        d.value = privaterates[dataIndex][d.tierKey] ? privaterates[dataIndex][d.tierKey] : dataIndex === 0 ? "Http" : "Https";
        d.isEdited = false;
      });
    });
    this.setState(prevState => ({
      rateCard: {
        ...prevState.rateCard,
        privaterateEdited: false
      }
    }));
    this.props.onChangeAdditionalApproval("privateRatesFlag", false);
  }

  /**
   * This function used to set the entire rate card object values as state in parent component
   */
  setRateCardData() {
    return JSON.stringify(this.state.rateCard);
  }

  handleOk = () => {
    this.setState({
      visible: false
    }, () => {
      this.props.onchangeDealStructure("LefNav", this.state.rateCard);
    })
  }

  handleCancel = () => {
    const rateCardClone = JSON.parse(this.props.rateCardClone);
    this.props.onchangeDealStructure("TermLength", rateCardClone.TermLength);
    this.props.onCancelRateCardChanges();
    this.setState({
      rateCard: JSON.parse(this.props.rateCardClone),
      visible: false
    }, () => {
      this.props.onchangeDealStructure("LefNav", this.state.rateCard);
    });
  }

  render() {
    return (
      <div className="deal-wraper">
        <div className="col-12 p-0 flaot-left">
          <AWSModal
            type="privatePricingEdit"
            visible={this.state.visible}
            handleOk={() => { this.handleOk() }}
            handleCancel={() => { this.handleCancel() }}
            titleMessage="This will override current data, are you sure you want to continue?"
          />
          <Col span={24}>
            <PricingDetails
              canEditPPR={this.props.canEditPPR}
              rateCard={this.state.rateCard}
              handleInputChange={(value, name, rowIndex, cellIndex, type) =>
                this.handleInputChange(value, name, rowIndex, cellIndex, type)
              }
              handleInputBlur={(value, name, rowIndex, cellIndex, key) =>
                this.handleInputBlur(value, name, rowIndex, cellIndex, key)
              }
              calculateDeal={key => this.calculateDeal(key)}
              loadStandardDeal={() => this.loadStandardDeal()}
              loadStandardPrivateRates={() => this.loadStandardPrivateRates()}
              triggeredNext={this.props.triggeredNext}
              userRole={this.props.userRole}
              dealInfo={this.props.dealInfo}
            />
            <RegionalDetails
              canEditPPR={this.props.canEditPPR}
              rateCard={this.state.rateCard}
              handleInputChange={(key, value, rowIndex, cellIndex) =>
                this.handleInputChange(key, value, rowIndex, cellIndex)
              }
              handleInputBlur={(key, index, value) =>
                this.handleInputBlur(key, index, value)
              }
              showDealStructureLoadingToast={(message, activeState) =>
                this.showDealStructureLoadingToast(message, activeState)
              }
              triggeredNext={this.props.triggeredNext}
              userRole={this.props.userRole}
            />
            <DealDetails
              canEditPPR={this.props.canEditPPR}
              rateCard={this.state.rateCard}
              handleInputChange={(key, value, rowIndex, cellIndex) =>
                this.handleInputChange(key, value, rowIndex, cellIndex)
              }
              onChangeAdditionalApproval={(key, value) =>
                this.props.onChangeAdditionalApproval(key, value)
              }
              triggeredNext={this.props.triggeredNext}
              selectedDealType={this.props.selectedDealType}
              userRole={this.props.userRole}
              renewalDealInfo={this.props.renewalDealInfo}
              dealInfo={this.props.dealInfo}
            />
          </Col>
        </div>
      </div>
    );
  }
}

export default DealStructure;

DealStructure.propTypes = {
  dataModel: PropTypes.array,
  TermLength: PropTypes.any,
  value: PropTypes.any,
  termLength: PropTypes.any,
  onRef: PropTypes.func,
  dealInfo: PropTypes.any,
  userRole: PropTypes.any,
  rateCardData: PropTypes.any,
  selectedDealType: PropTypes.any,
  onchangeDealStructure: PropTypes.func,
  showDealStructureLoadingToast: PropTypes.func,
  triggeredNext: PropTypes.bool,
  onChangeCustomDeal: PropTypes.func,
  onChangeAdditionalApproval: PropTypes.func
};
