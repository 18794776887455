import React, { Component } from "react";
import AWSheader from "../../common/AWSheader/AWSheader";
// import { AppProvider, Icon } from "@shopify/polaris";
// import enTranslations from "@shopify/polaris/locales/en.json";
// import {
//   MobileVerticalDotsMajorMonotone,
//   SaveMinor
// } from "@shopify/polaris-icons";
import Leftnavigation from "../../common/LeftNav/Leftnavigation";
import NavBar from "../NavBar/NavBar";
import Controls from "../../Dashboard/Controls";
import NavMenu from "../NavMenu/NavMenu";
import "./AdminLandingPage.scss";
import adminMngNotificationModuleJson from "../../../json/adminmanagementnotificationmodule.json";
import adminConfiguration from "../../../json/adminConfiguration.json";
import adminRateCardModuleJson from "../../../json/adminmoduleratecard.json";
import adminUserModuleJson from "../../../json/adminusermodule.json";
import AWSUsers from "../AWSUsers/AWSUsers";
import AWSLoadingToast from "../../common/AWSLoadingToast/AWSLoadingToast";
import AWSMngNotification from "../AWSMngNotification/AWSMngNotification";
import AWSModal from "../AWSModal/AWSModal";
import axios from "axios";
import RateCardManagementWrapper from "../RateCardMangement/RateCardManagementWrapper";
import PPRManagement from "../PPRManagement/PPRManagement";
import Cookies from "universal-cookie";
import AwsConfig from "../../../config/AWSconfig";
import Configurations from '../Configurations/Configurations';
import { getTokenBasedOnRole } from '../../../utils/common';

const cookies = new Cookies();

const config = new AwsConfig();
axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");

let baseUrlForPPr;

class AdminLandingPage extends Component {
  state = {
    displayMenu: false,
    collapaseStateName: [
      "The Team",
      "The Customer",
      "Business Information",
      "Enterprise Agreement (EA)"
    ],
    collapaseStateName1: ["Deal Type", "Business Terms", "PPA", "anyhting"],
    menuIndex: 0,
    navMenuOption: "",
    valueChange: true,
    selectedStage: 0,
    dataModel: [],
    leftNavIndex: 0,
    visible: false,
    loadingError: false,
    activateToast: false,
    loadingMessageDuration: undefined,
    loadingMessage: "",
    makeModalVisible: false,
    activeCollapse: 0,
    clonedDataModel: [],
    clonedDataModelForEditHeader: [],
    enableEditHeader: false,
    scrollheaderValue: 0,
  };

  constructor(props) {
    super(props);
    baseUrlForPPr = config.amazonFalconbaseUrl;
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.userRole === "Admin") {
      this.questionApiCall();
    } else {
      this.props.history.push("/");
    }
  }

  /**
   * This function call backend api to get the question of ppr management in admin module
   */
  questionApiCall = () => {
    this.showUserManagementLoadingToast("Loading questions .....", true);
    axios({
      method: "GET",
      url: baseUrlForPPr + "adminppr",
      headers: {
        'Authorization': localStorage.getItem('cfat'),
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then(resp => {
      this.setState({
        valueChange: !this.state.valueChange,
        dataModel: resp.data.pageStages,
        clonedDataModel: JSON.parse(JSON.stringify(resp.data.pageStages))
      });
      this.showUserManagementLoadingToast("Loading questions ...", false);
    });
  };

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      displayMenu: false
    });
  };

  /**
   * This function will take index as params and navigate to different admin module by setting state
   */
  handleLefNavClick = index => {
    this.setState({
      leftNavIndex: index,
      navMenuOption: undefined,
      selectedStage: 0
    });
    if (index === 0) {
      this.setState({ dataModel: [] });
      this.questionApiCall();
    } else if (index === 1) {
      this.setState({
        dataModel: adminUserModuleJson
      });
    } else if (index === 2) {
      this.setState({
        dataModel: adminRateCardModuleJson
      });
    } else if (index === 3) {
      this.setState({
        dataModel: adminConfiguration
      });
    }
    else if (index === 4) {
      this.setState({
        dataModel: adminMngNotificationModuleJson
      });
    } else {
      this.setState({
        dataModel: []
      });
    }
  };

  /**
   * This function will load toaster as a loader while api calls, it will have message and state of loader as params
   * @params message, activeState
   */
  showUserManagementLoadingToast = (message, activeState) => {
    this.setState({
      activateToast: activeState,
      loadingMessage: message
    });
  };

  /**
   *This  function used to make modal visble  for edit section
   */
  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOkOrCancelOfModal = () => {
    this.setState({
      visible: false
    });
  };

  /**
   * This function is used to render left navigation of admin module
   */
  renderLeftNavigation = () => {
    let leftNav = [
      "PPR Management",
      "User Management",
      "Rate Card Management",
      "ABT Configurations",
      "Notification Management",
    ];

    return (
      <Leftnavigation
        navMenu={leftNav}
        handleLefNavClick={index => this.handleLefNavClick(index)}
        isExpand={false}
        leftNavIndex={this.state.leftNavIndex}
        changeInvalue={false}
        heading="ADMIN CONSOLE"
        stage={0}
        isForm={false}
        disableSubmitButton={false}
        terminationPage={false}
        handleOnClick={() => { }}
        leftNavRateCardData={[]}
        summaryComment=""
        summaryCommentTimeStamp=""
        showSummary={false}
        handleSummaryComment={() => { }}
        handleSubmit={() => { }}
      />
    );
  };

  callApiToFetchStageData = stageId => {
    let { dataModel } = this.state;
    this.showUserManagementLoadingToast("Loading questions .....", true);
    axios({
      method: "GET",
      url: baseUrlForPPr + `stage?stageId=${stageId}`
    }).then(resp => {
      dataModel.map((stageData, stageDataIndex) => {
        if (stageData.templateStageId === stageId) {
          stageData.queBlock = resp.data.queBlock;
        }
      });

      this.setState({
        valueChange: !this.state.valueChange,
        dataModel: dataModel,
        clonedDataModel: JSON.parse(JSON.stringify(dataModel))
      });
      this.showUserManagementLoadingToast("Loading questions ...", false);
    });
  };

  /**
   * handle navBar
   */
  handleSelectTab = (menuIndex, stageId, stageName) => {
    if (this.state.enableEditHeader === false) {
      let checkForstagecalled = this.state.dataModel.filter(
        (stage, index) => stage.templateStageId === stageId
      )[0];

      if (
        checkForstagecalled.queBlock === undefined &&
        this.state.leftNavIndex === 0
      ) {
        this.callApiToFetchStageData(stageId);
      }
      this.setState({
        selectedStage: menuIndex,
        activeCollapse: undefined
      });
    }
  };

  /**
   * This funmction handle navBar (on the top) menu function
   * @params navMenuOption (addSection,editSection)
   */
  onMenuClick = navMenuOption => {

    let { dataModel } = this.state;
    if (
      navMenuOption === "addSection" ||
      navMenuOption === "rateCardConfig" ||
      navMenuOption === "editMenu"
    ) {
      // if (navMenuOption === "addSection" || navMenuOption === "rateCardConfig") {
      this.setState({
        makeModalVisible: true
      });
    }

    // if (navMenuOption === "editMenu") {
    //     this.setState({
    //         enableEditHeader: true,
    //         clonedDataModelForEditHeader: JSON.parse(JSON.stringify(dataModel))
    //     })
    // }

    if (navMenuOption === "addPage") {
      let lengthOfPage = dataModel && dataModel.length;
      let pageIdOfNewPage = lengthOfPage + 1;
      let newPageJson = {
        stageId: null,
        stageName: "0" + pageIdOfNewPage + "  New Page",
        stageSeq: pageIdOfNewPage,
        isStageDelete: true,
        formHeader: "New Page Header",
        queBlock: [
          {
            queBlkId: null,
            queBlkSeq: "01",
            isQueBlkDelete: true,
            queBlkHeader: "New Section",
            isVisible: true,
            questions: []
          }
        ]
      };
      this.callApiToAddAndUpdatePage("POST", newPageJson);
      // dataModel.push(newPageJson);
      // this.setState({
      //     displayMenu: !this.state.displayMenu,
      //     navMenuOption,
      //     selectedStage: lengthOfPage
      // })
    }
    this.setState({
      displayMenu: !this.state.displayMenu,
      navMenuOption
    });
  };

  onMenuSaveClick = () => {
    let {
      selectedStage,
      dataModel,
      clonedDataModel,
      clonedDataModelForEditHeader
    } = this.state;
    if (dataModel[selectedStage].stageName === "") {
      dataModel[selectedStage].stageName = "0" + (selectedStage + 1);
    }

    let editHeaderJson = {
      stageId: dataModel[selectedStage].templateStageId,
      stageName: dataModel[selectedStage].stageName,
      isStageDelete: false,
      formHeader: dataModel[selectedStage].formHeader
    };
    this.callApiToAddAndUpdatePage("PUT", editHeaderJson);
    this.setState({
      displayMenu: false,
      enableEditHeader: false
      // dataModel: clonedDataModelForEditHeader
    });
  };

  handleSectionOrPageModalSave = (typeOfModal, questionJson) => {
    let { selectedStage, dataModel } = this.state;
    if (typeOfModal === "addNewSection") {
      this.callApiToAddSection(questionJson);
      // dataModel[selectedStage].questionBlock.push(questionJson)
    }

    if (typeOfModal === "editPageHeader") {
      this.callApiToAddAndUpdatePage("PUT", questionJson);
    }

    this.setState({
      makeModalVisible: false,
      dataModel
    });
  };

  handleDelete = (typeOfModal, questionJson) => {
    if (typeOfModal === "deletePageHeader") {
      this.callApiToDeleteHeader("DELETE", questionJson);
    }
  };

  handleSectionOrPageModalCancel = () => {
    this.setState({
      makeModalVisible: false,
      enableEditHeader: false,
      navMenuOption: "notInEditMenuMode"
    });
  };

  /**
   * function to update stage name after clicking on edit option from menu of navigaion bar
   */
  handleMenuTextChange = (navTextValue, navIndex) => {
    let { selectedStage, dataModel } = this.state;
    dataModel &&
      dataModel.map((stage, stageIndex) => {
        if (stageIndex === navIndex) {
          // if (navTextValue !== "") {

          const stageName = stage.stageName;
          stage.stageName = navTextValue;
          // if ()
          this.setState({
            selectedStage
          });
          // }
        }
      });
  };

  callApiToAddQuestion = (dataJSon, sectionIndex) => {
    let { dataModel, selectedStage } = this.state;

    this.showUserManagementLoadingToast("Adding question ...", true);

    axios({
      method: "POST",
      url: baseUrlForPPr + "question",
      data: dataJSon
    })
      .then(resp => {
        let queBlock = resp.data.data.queBlock[0];
        queBlock.questions[0].templateId = queBlock.templateId;
        this.showUserManagementLoadingToast("Adding question ...", false);
        dataModel[selectedStage].queBlock[sectionIndex].questions.push(
          queBlock.questions[0]
        );
        this.setState({
          dataModel
        });
        // this.questionApiCall()
      })
      .catch(error => {
        this.showUserManagementLoadingToast(
          "Failed Adding question ...",
          false
        );
      });
  };

  callApiToUpdateQuestion = (dataJSon, sectionIndex) => {
    let { dataModel, selectedStage } = this.state;
    let { rowSeq } = dataJSon.queBlock[0].questions[0];
    let questionId = dataJSon.queBlock[0].questions[0].queId;
    let newSectionForSequence = {
      queBlock: [dataModel[selectedStage].queBlock[sectionIndex]]
    };
    this.showUserManagementLoadingToast("Updating question ...", true);
    axios({
      method: "PUT",
      url: baseUrlForPPr + "question",
      data: dataJSon
    })
      .then(resp => {
        let newUpdatedQuestion = resp.data.data.queBlock[0].questions;
        let questionArray =
          dataModel[selectedStage].queBlock[sectionIndex].questions;

        let newUpdatedDataModel = questionArray.map(
          obj => newUpdatedQuestion.find(o => questionId === obj.queId) || obj
        );
        dataModel[selectedStage].queBlock[
          sectionIndex
        ].questions = newUpdatedDataModel;
        this.showUserManagementLoadingToast("Adding question ...", false);

        // dataModel[selectedStage].queBlock[sectionIndex].questions.push(queBlock.questions[0]);
        this.setState({
          dataModel,
          valueChange: !this.state.valueChange
        });
        // this.questionApiCall()
      })
      .catch(error => {
        this.showUserManagementLoadingToast(
          "Failed Adding question ...",
          false
        );
      });
  };
  resetScrollheader = value => {
    document.getElementById("nav-container").scrollLeft = 0;
  };
  /*api call to delete header */
  callApiToDeleteHeader = (callType, dataJson) => {
    let { dataModel, clonedDataModelForEditHeader, selectedStage } = this.state;
    let DeletePageUrl = baseUrlForPPr + "page";
    let loadingMessage = "Deleting Page ...";
    let failureMessage = "Failed To Delete Page ...";
    let successMessage = "Page deleted successfully ...";

    if (callType === "DELETE") {
      loadingMessage = "Deleting Page ...";
      failureMessage = "Failed To Delete Page ...";
      successMessage = "Page deleted successfully ...";
    }
    this.showUserManagementLoadingToast(loadingMessage, true);
    axios({
      method: callType,
      url: DeletePageUrl,
      data: dataJson
    })
      .then(resp => {
        this.showUserManagementLoadingToast(successMessage, true);

        this.setState(
          {
            dataModel,
            makeModalVisible: false,
            enableEditHeader: false
          },
          () => {
            setTimeout(() => {
              this.resetScrollheader(0);
              this.questionApiCall();
              this.setState({
                leftNavIndex: 0,
                navMenuOption: undefined,
                selectedStage: 0
              });
              this.showUserManagementLoadingToast(successMessage, false);
            }, 1000);
          }
        );
      })
      .catch(error => {
        this.showUserManagementLoadingToast(failureMessage, true);
        this.setState(
          {
            dataModel: clonedDataModelForEditHeader
          },
          () => {
            setTimeout(() => {
              this.showUserManagementLoadingToast(failureMessage, false);
            }, 1000);
          }
        );
      });
  };

  callApiToAddAndUpdatePage = (callType, dataJson) => {
    let { dataModel, clonedDataModelForEditHeader, selectedStage } = this.state;
    let addAndUpdatePageUrl = baseUrlForPPr + "page";

    let loadingMessage = "Adding New Page ...";
    let failureMessage = "Failed To Add New Page ...";
    let successMessage = "Adding New Page ...";

    if (callType === "PUT") {
      loadingMessage = "Updating Page Header...";
      failureMessage = "Failed To Update ...";
      successMessage = "Updating Page Header...";
    }

    this.setState({
      navMenuOption: "notInEditMenuMode"
    });

    // if (callType === "POST") {
    this.showUserManagementLoadingToast(loadingMessage, true);
    axios({
      method: callType,
      url: addAndUpdatePageUrl,
      data: dataJson
    })
      .then(resp => {
        let newUpdated = resp.data.data;

        newUpdated.templateStageId = resp.data.data.stageId;
        if (callType === "POST") {
          dataModel.push(newUpdated);
        } else {
          dataModel[selectedStage].stageName = newUpdated.stageName;
          dataModel[selectedStage].formHeader = newUpdated.formHeader;
        }
        this.showUserManagementLoadingToast(successMessage, true);
        this.setState(
          {
            dataModel,
            makeModalVisible: false,
            enableEditHeader: false,
            selectedStage: dataModel.length - 1
          },
          () => {
            setTimeout(() => {
              this.showUserManagementLoadingToast(successMessage, false);
            }, 1000);
          }
        );
      })
      .catch(error => {
        this.showUserManagementLoadingToast(failureMessage, true);
        this.setState(
          {
            dataModel: clonedDataModelForEditHeader
          },
          () => {
            setTimeout(() => {
              this.showUserManagementLoadingToast(failureMessage, false);
            }, 1000);
          }
        );
      });
    // }
  };

  callApiToAddSection = jsonValue => {
    let { selectedStage, dataModel } = this.state;

    jsonValue.queBlock[0].queBlkSeq =
      "0" + (dataModel[selectedStage].queBlock.length + 1);
    let abc = {
      rowSeq: [
        {
          col: [
            {
              value: false
            },
            {
              value: false
            },
            {
              value: false
            }
          ]
        }
      ]
    };

    // if (rowSeq !== undefined) {
    jsonValue.queBlock[0].rowSeq = abc;
    // }
    let appendedJsonToAddSection = {
      stageId: dataModel[selectedStage].templateStageId,
      stageName: dataModel[selectedStage].stageName,
      isStageDelete: false,
      stageSeq: dataModel[selectedStage].stageSeq
    };
    let addSectionJson = Object.assign(appendedJsonToAddSection, jsonValue);

    this.showUserManagementLoadingToast("Adding section ...", true);
    let addSection = baseUrlForPPr + "section";
    axios({
      method: "POST",
      url: addSection,
      data: addSectionJson
    })
      .then(resp => {
        dataModel[selectedStage].queBlock.push(resp.data.data.queBlock[0]);
        this.showUserManagementLoadingToast(
          "New Section Added successfully ...",
          false
        );
        this.setState({
          dataModel,
          valueChange: !this.state.valueChange
        });
      })
      .catch(error => {
        this.showUserManagementLoadingToast(
          "Failed To Add New Section ...",
          false
        );
      });
  };

  callApiToEditSection = (jsonValue, sectionIndex) => {
    let { selectedStage, dataModel } = this.state;

    let appendedJsonToAddSection = {
      stageId: dataModel[selectedStage].templateStageId,
      stageName: dataModel[selectedStage].stageName
    };
    let updateSectionJson = Object.assign(appendedJsonToAddSection, jsonValue);

    // this.showUserManagementLoadingToast("Updating section ...", true);

    let addSection = baseUrlForPPr + "section";
    axios({
      method: "PUT",
      url: addSection,
      data: updateSectionJson
    })
      .then(resp => {
        // dataModel[selectedStage].queBlock.push(resp.data.data.queBlock[0])
        dataModel[selectedStage].queBlock[sectionIndex].infoText =
          jsonValue.queBlock[0].infoText;
        dataModel[selectedStage].queBlock[sectionIndex].isVisible =
          jsonValue.queBlock[0].isVisible;
        dataModel[selectedStage].queBlock[sectionIndex].queBlkSeq =
          jsonValue.queBlock[0].queBlkSeq;
        dataModel[selectedStage].queBlock[sectionIndex].queBlkHeader =
          jsonValue.queBlock[0].queBlkHeader;

        this.showUserManagementLoadingToast(
          "New Section Added successfully ...",
          false
        );
        this.setState({
          dataModel,
          valueChange: !this.state.valueChange,
          activeCollapse: undefined
        });
      })
      .catch(error => {
        this.showUserManagementLoadingToast(
          "Failed To Add New Section ...",
          false
        );
      });
  };

  /**
   * function to call api to delete question on clicking of delete icon in table
   */
  callApiToDeletequestion = (jsonValue, sectionIndex) => {
    let { selectedStage, dataModel } = this.state;

    let pageStage = dataModel[selectedStage];
    let deleteQuestionJson = {
      stageId: pageStage.templateStageId,
      queBlock: [
        {
          templateId: jsonValue.templateId,
          queBlkId: pageStage.queBlock[sectionIndex].queBlkId,
          questions: [
            {
              queId: jsonValue.queId
            }
          ]
        }
      ]
    };

    this.showUserManagementLoadingToast("Deleting Question ...", true);
    let deleteQuestion = baseUrlForPPr + "question";

    axios({
      method: "DELETE",
      url: deleteQuestion,
      data: deleteQuestionJson
    })
      .then(resp => {
        let newUpdatedQuestions = pageStage.queBlock[
          sectionIndex
        ].questions.filter(question => {
          return question.queId !== jsonValue.queId;
        });

        pageStage.queBlock[sectionIndex].questions = newUpdatedQuestions;
        this.showUserManagementLoadingToast(
          "Question Deleted Successfully",
          true
        );

        this.setState(
          {
            dataModel,
            valueChange: !this.state.valueChange,
            activeCollapse: undefined
          },
          () => {
            setTimeout(() => {
              this.showUserManagementLoadingToast(
                "Question Deleted Successfully",
                false
              );
            }, 1000);
          }
        );
      })
      .catch(error => {
        this.showUserManagementLoadingToast("Failed To Delete ...", false);
      });
  };

  /*API call to delete section */
  callApiToDeleteSection = (jsonValue, sectionIndex) => {
    let { selectedStage, dataModel } = this.state;
    let pageStage = dataModel[selectedStage];
    let deleteQuestionJson = {
      stageId: pageStage.templateStageId,
      queBlock: [
        {
          templateId: jsonValue.templateId,
          queBlkId: pageStage.queBlock[sectionIndex].queBlkId,
          questions: [
            {
              queId: jsonValue.queId
            }
          ]
        }
      ]
    };

    this.showUserManagementLoadingToast("Deleting Section ...", true);
    let deleteQuestion = baseUrlForPPr + "section";

    axios({
      method: "DELETE",
      url: deleteQuestion,
      data: deleteQuestionJson
    })
      .then(resp => {
        // let newUpdatedQuestions = pageStage.queBlock[sectionIndex].questions.filter((question) => {
        //     return question.queId !== jsonValue.queId
        // });
        let newUpdatedDataModel = dataModel[selectedStage].queBlock.filter(
          (queBlock, queBlockIndex) => {
            return queBlockIndex !== sectionIndex;
          }
        );
        dataModel[selectedStage].queBlock = newUpdatedDataModel;
        //pageStage.queBlock[sectionIndex].questions = newUpdatedQuestions;
        this.showUserManagementLoadingToast(
          "Section Deleted Successfully",
          true
        );

        this.setState(
          {
            dataModel,
            valueChange: !this.state.valueChange,
            activeCollapse: undefined
          },
          () => {
            setTimeout(() => {
              this.showUserManagementLoadingToast(
                "Section Deleted Successfully",
                false
              );
            }, 1000);
          }
        );
      })
      .catch(error => {
        this.showUserManagementLoadingToast(
          "Failed To Delete Section ...",
          false
        );
      });
  };

  /**
   * function to navigate to particalar api calls
   */
  handleSectionChange = (type, jsonValue, sectionIndex, rowSeq) => {
    let { selectedStage, dataModel } = this.state;
    let rowColSeq = rowSeq;
    if (type === "addQuestionPPR") {
      this.callApiToAddQuestion(jsonValue, sectionIndex, rowColSeq);
    }

    if (type === "editQuestionPPR") {
      this.callApiToUpdateQuestion(jsonValue, sectionIndex, rowColSeq);
    }

    if (type === "editSection") {
      this.callApiToEditSection(jsonValue, sectionIndex);
    }
    if (type === "addNewSection") {
      this.callApiToEditSection(jsonValue, sectionIndex);
    }
    if (type === "deleteQuestionPPR") {
      this.callApiToDeletequestion(jsonValue, sectionIndex);
    }
    if (type === "deleteSection") {
      this.callApiToDeleteSection(jsonValue, sectionIndex);
    }
  };

  /**
   * This function will call dashboard api
   */
  getPPRManagment = () => {

    this.setState({
      leftNavIndex: 0,
      selectedStage: 0,
      navMenuOption: undefined,
      dataModel: []
    });
    this.questionApiCall();
  };

  updateUserRole = (secondaryRole, activeRole) => getTokenBasedOnRole(secondaryRole, activeRole, this.props.location.state, this.props.history);

  render() {
    let { selectedStage, dataModel } = this.state;

    return (
      <div className="full-width admin-wraper">
        {/* <AppProvider i18n={enTranslations}> */}
        <AWSheader
          disableHomeIcon={true}
          type={"admin"}
          handlePPRCreatedForAutoSave={() => this.getPPRManagment()}
          updateUserRole={(secondaryRole, activeRole) => { this.updateUserRole(secondaryRole, activeRole) }}
        />

        <AWSModal
          type={this.state.navMenuOption}
          isVisibleSection={this.state.isVisibleSection}
          visible={this.state.makeModalVisible}
          handleChangeSectionModal={(type, value) =>
            this.handleChangeSectionModal(type, value)
          }
          handleOk={(typeOfModal, questionJson) => {
            this.handleSectionOrPageModalSave(typeOfModal, questionJson);
          }}
          handleCancel={() => {
            this.handleSectionOrPageModalCancel();
          }}
          showUserManagementLoadingToast={(message, activeState) =>
            this.showUserManagementLoadingToast(message, activeState)
          }
          selectedStage={this.state.selectedStage}
          dataModel={dataModel}
          handleDelete={(typeOfModal, questionJson) => {
            this.handleDelete(typeOfModal, questionJson);
          }}
          enableEditHeader={this.state.enableEditHeader}
          location={this.props.location} //passing the userinfo binded in location prop
        />
        <div
          className={
            "main-container bg-grey pt-5 pb-4 overflow-auto-y scroll-width5 moz-scroll"
          }
          ref={this.myRef}
        >
          <div className={"overlay-dashboard display-none"}></div>
          {/* <Controls pprId="" /> */}
          <div className={"col-3 float-left p-0"}>
            {this.renderLeftNavigation()}
          </div>
          {this.state.activateToast && (
            <AWSLoadingToast
              message={this.state.loadingMessage}
              error={this.state.loadingError}
              duration={this.state.loadingMessageDuration}
            />
          )}
          <div className="col-9 float-right pr-5 pl-5">
            <div className="full-width border-bottom">
              <div className="col-11 p-0 float-left">
                <NavBar
                  selectedStage={this.state.selectedStage}
                  enableEditHeader={this.state.enableEditHeader}
                  handleSelectTab={(menuIndex, stageId, stageName) => {
                    this.handleSelectTab(menuIndex, stageId, stageName);
                  }}
                  dataModel={dataModel}
                  navMenuOption={this.state.navMenuOption}
                  handleChange={(value, navIndex) =>
                    this.handleMenuTextChange(value, navIndex)
                  }
                />
              </div>
              {this.state.leftNavIndex !== 1 &&
                this.state.leftNavIndex !== 4 && (
                  <span
                    className="float-right"
                  // className="float-right cursor-pointer three-dot"
                  // onClick={() => this.onMenuClick()}
                  >
                    <NavMenu
                      onMenuClick={onMenuClick => this.onMenuClick(onMenuClick)}
                      showModal={() => this.showModal()}
                      leftIndex={this.state.leftNavIndex}
                    />
                  </span>
                )}
              {this.state.enableEditHeader === true ? (
                <span
                  className="float-right cursor-pointer pr-2"
                  onClick={() => this.onMenuSaveClick()}
                >
                  <span className="float-left">
                    {/* <Icon source={SaveMinor} /> */}
                  </span>
                  <div
                    className="float-left cursor-pointer"
                    onClick={() => this.onMenuSaveClick()}
                  >
                    Save
                    </div>
                </span>
              ) : (
                  ""
                )}

              {/* {this.state.displayMenu && (
                  <div ref={node => (this.node = node)}>
                    <NavMenu
                      onMenuClick={onMenuClick => this.onMenuClick(onMenuClick)}
                      showModal={() => this.showModal()}
                      leftIndex={this.state.leftNavIndex}
                    />
                  </div>
                )} */}
            </div>

            <div className="full-width pt-3 cloud-tab">
              {this.state.leftNavIndex === 0 && (
                <PPRManagement
                  dataModel={this.state.dataModel}
                  selectedStage={this.state.selectedStage}
                  leftNavIndex={this.state.leftNavIndex}
                  activeCollapse={this.state.activeCollapse}
                  onChangeSection={(section, stage) =>
                    this.onChangeSection(section, stage)
                  }
                  valueChange={this.state.valueChange}
                  questionBlock={this.state.dataModel[selectedStage]}
                  handleSectionChange={(
                    type,
                    jsonValue,
                    sectionIndex,
                    rowColSeq
                  ) =>
                    this.handleSectionChange(
                      type,
                      jsonValue,
                      sectionIndex,
                      rowColSeq
                    )
                  }
                  location={this.props.location}
                />
              )}
              {this.state.leftNavIndex === 1 && (
                <AWSUsers
                  selectedStage={this.state.selectedStage}
                  location={this.props.location}
                  showUserManagementLoadingToast={(message, activeState) =>
                    this.showUserManagementLoadingToast(message, activeState)
                  }
                />
              )}
              {this.state.leftNavIndex === 2 && (
                <div className="full-width p-0">
                  <RateCardManagementWrapper
                    dataModel={this.state.dataModel}
                    selectedStage={this.state.selectedStage}
                    leftNavIndex={this.state.leftNavIndex}
                    activeCollapse={this.state.activeCollapse}
                    onChangeSection={(section, stage) =>
                      this.onChangeSection(section, stage)
                    }
                    stage={this.state.selectedStage}
                    showUserManagementLoadingToast={(message, activeState) =>
                      this.showUserManagementLoadingToast(
                        message,
                        activeState
                      )
                    }
                  />
                </div>
              )}
            </div>
            {this.state.leftNavIndex === 3 && (
              <Configurations
                selectedStage={this.state.selectedStage}
                location={this.props.location}
                showUserManagementLoadingToast={(message, activeState) =>
                  this.showUserManagementLoadingToast(message, activeState)
                }
              />
            )}
            {
              this.state.leftNavIndex === 4 && (
                <AWSMngNotification
                  selectedStage={this.state.selectedStage}
                  showUserManagementLoadingToast={(message, activeState) =>
                    this.showUserManagementLoadingToast(message, activeState)
                  }
                />
              )
            }
          </div>

          <div className={"col-3 float-left p-0"}>
            {this.renderLeftNavigation()}
          </div>
        </div>
        {/* </AppProvider> */}
      </div>
    );
  }
}

export default AdminLandingPage;
