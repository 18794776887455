import React from "react";
import './AWSDragDropFile.scss';
import axios from 'axios';
import _ from 'underscore';
import deleteIcon from '../../../images/delete.svg';
import { Icon, Button, Popover } from "@amzn/awsui-components-react";
import AWSbutton from "../AWSbutton/AWSbutton";
import * as roleNames from '../../../constants/roleNames.const';

import AWSModal from "../AWSModal/AWSModal";
import Col from '../AWSCol/AWSCol';
import AWSLoadingToast from "../AWSLoadingToast/AWSLoadingToast";
import AWSConfig from '../../../config/AWSconfig'
import { isValidFile } from "../../../utils/common";
import infoHelpIcon from '../../../images/awsfalconhelp.png';
const config = new AWSConfig();
let baseUrl;

export default class AWSDragDropFile extends React.Component {
  state = {
    fileName: [],
    answerId: "",
    error: false,
    visible: false,
    errorLabel: "",
    attachment: "",
    attachmentIndex: "",
    loadingMessage: "",
    loadingError: false,
    loadingMessageDuration: 3000,
  }

  uploadRef = React.createRef();

  componentDidMount() {
    baseUrl = config.amazonFalconbaseUrl;
  }

  // handleClick = (e) => {
  //   const { canEditPPR } = this.props;
  //   if (canEditPPR === true) {
  //     this.setState({
  //       error: false
  //     })
  //     document.getElementById(`file_${this.props.questionValue && this.props.questionValue.queId}`).click();
  //   }
  // }

  validate(validationArray, val) {
    _.each(validationArray, (validationObject, validationIndex) => {
      //test value against validation rule
      if (validationObject.type === "Required") {
        if ((_.isEmpty(val)) || (_.isNull(val))) {
          this.setState({
            error: true,
            errorLabel: validationObject.message
          })
        }
      }
    });
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  // handleClick = event => {
  //   const { target = {} } = event || {};
  //   target.value = "";
  // };

  handleUpload = async (event) => {
    // let clonedFormGroup = Object.assign({}, question)

    const file = event.target.files[0];
    const fileSize = (file.size) / (1024 * 1024);
    event.target.value = null;
    this.setState({
      error: false
    })
    // File size limiting to 4MB due to AWS Lambda quota:
    // https://docs.aws.amazon.com/lambda/latest/dg/gettingstarted-limits.html
    // According to this, maximum Invocation Payload is 6MB (including metadata)
    if (fileSize > 4) {
      this.setState({
        activateToast: true,
        loadingMessage: `File size must be less than 4MB`,
        loadingError: false,
      })
      setTimeout(() => {
        this.setState({
          activateToast: false
        })
      }, 5000)
      return;
    } else {
      this.setErrorAsFalse()
      if (!isValidFile(file.name)) {
        this.setState({
          activateToast: true,
          loadingMessage: `Invalid file`,
          loadingError: false,
          loadingMessageDuration: 2000
        }, () => {
          setTimeout(() => {
            this.setState({
              activateToast: false,
            })
          }, 1000)
        }
        )
        return;
      }


      let base64 = await this.toBase64(file);
      // this.props.onChange(file.name);

      this.setState({
        error: false,
        activateToast: true,
        loadingMessage: `Attaching ${file.name} ...`,
        loadingError: false
      })

      const updatedFileName = this.props.summaryPageUpload ? "summary_" + file.name : file.name

      return new Promise(async (resolve, reject) => {

        axios({
          method: 'POST',
          url: `${baseUrl}cfppr/attach`,
          data: {
            base64String: base64.split(",")[1],
            fileName: updatedFileName,
            pprId: this.props.pprId,
          }
        }).then((r) => {
          const { questionValue } = this.props;

          if (questionValue && (questionValue.answer.attachments === null || questionValue.answer.attachments === "")) {
            let fileData = [];
            fileData.push(r.data.fileURL);

            questionValue.answer.attachments = fileData
          } else {
            questionValue && questionValue.answer.attachments.push(r.data.fileURL) 
          }
          this.props.onChange(file.name);
          this.setState({
            activateToast: false
          })
        }).catch((error) => {

          this.setState({
            error: true,
            errorLabel: error.data && error.data.error,
            activateToast: true,
            loadingMessage: `Failed to attach ${file.name}`,
            loadingError: true
          }, () => {
            setTimeout(() => {
              this.setState({
                activateToast: false,
              })
            }, 1000)
          })
        })
      });
    }

  }

  handleLink = (attachment) => {
    let fileName =  attachment.substring(attachment.indexOf(',')+1)
    // let fileName = attachment.split(",")[1];

    axios({
      method: 'POST',
      url: `${baseUrl}cfppr/attach`,
      data: {
        pprId: this.props.pprId,
        fileName: fileName,
        action: "download"
      }

    }).then(r => {
      const linkSource = `data:application/pdf;base64,${r.data.base64String}`;
      const downloadLink = document.createElement("a");
      const fileName = attachment.substring(attachment.indexOf(',')+1)

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    })
  }

  removeError = () => {
    this.props.onChange(null);
    this.setErrorAsFalse()
  }

  setErrorAsFalse = () => {
    this.setState({
      error: false,
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {

    let attachmentIndex = this.state.attachmentIndex;
    let { attachment } = this.state;
    let fileName = attachment.substring(attachment.indexOf(',')+1)
    this.setState({
      visible: false,
      activateToast: true,
      loadingMessage: `Removing ${fileName} from attachment`,
      loadingError: false
    });
    axios({
      method: 'Delete',
      url: `${baseUrl}cfppr/attach`,
      data: {
        fileName: fileName,
        pprId: this.props.pprId
      }

    }).then(r => {

      let { answer } = this.props.questionValue || {};
      let { attachments, answerValue } = answer || {};

      attachments.splice(attachmentIndex, 1)

      if (attachments.length === 0) {
        answerValue = null
      }
      this.props.onChange(answerValue)
      this.setState({
        activateToast: true,
        loadingMessage: `Removed ${fileName} from attachment`,
        loadingError: false,
        loadingMessageDuration: 2000
      }, () => {
        setTimeout(() => {
          this.setState({
            activateToast: false,
          })
        }, 1000)
      }
      )

    }).catch((error) => {

      this.setState({
        activateToast: true,
        loadingMessage: `Failed to remove ${fileName} from attachment`,
        loadingError: false,
        loadingMessageDuration: 2000
      }, () => {
        setTimeout(() => {
          this.setState({
            activateToast: false,
          })
        }, 1000)
      }
      )
    })
    this.setState({
      nothing: ""
    })
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  handleOnDelete = (attachment, attachmentIndex) => {
    let {questionValue} = this.props;
    if (this.props.canEditPPR === true) {
      if (questionValue.quePermission && questionValue.quePermission.read === false) {
        this.setState({
          visible: true,
          attachment, attachmentIndex
        })
      }
    }
  }

  displayLink = () => {
    if (this.props.questionValue.answer.attachments && this.props.questionValue.answer.attachments.length > 0) {
      return this.props.questionValue.answer.attachments.map((attachment, attachmentIndex) => {

        return (
          <div>
            <a target="_blank" onClick={() => this.handleLink(attachment)} className="dark-blue pl-2 ml-2">
              {/* {attachment.split(",")[1]} */}
              {attachment.substring(attachment.indexOf(',')+1)}
            </a>
            <span className="ml-2 close-icon">
              <img src={deleteIcon}
                key={attachmentIndex}
                style={{ cursor: 'pointer' }}
                onClick={() => { this.handleOnDelete(attachment, attachmentIndex) }}
                alt="delete" className="width10 mt-1 filter-green " />
            </span>
          </div>
        )
      })
    } else {
      return (
        <div>
          <a target="_blank">
            {this.props.questionValue.answer.answerValue}
          </a>
          <span className="ml-2 close-icon">
            <img src={deleteIcon}
              style={{ cursor: 'pointer' }}
              onClick={() => this.handleOnDelete()}
              alt="delete" className="width10 mt-1 filter-green " />
          </span>
        </div>
      )
    }
  }

  editQuestionCss = (questionValue) => {
    return questionValue.isQuestionEdit === true ? 'editable-color' : ''
  }

  checkForDisable = (questionValue, canEditPPR) => {
    if (canEditPPR === true) {
        return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
    } else if (canEditPPR === false) {
        return true // disable true
    } else {
        return questionValue && questionValue.quePermission ? questionValue.quePermission.read : false
    }
}

  render() {

    const { value, triggeredNext,userRole ,questionValue, canEditPPR, uploadText = "Attachments :" } = this.props;

    if (this.state.error === true && questionValue && questionValue.isVisible === false) {
      this.removeError()
    }

    if (triggeredNext && (!this.state.error) && questionValue && questionValue.isVisible) {
      this.validate(this.props.validation, value)
    }

    return (
      <div>
        <AWSModal
          type="Secondary"
          visible={this.state.visible}
          handleOk={() => { this.handleOk() }}
          handleCancel={() => { this.handleCancel() }}
          titleMessage="Are you sure you want to continue?"
        />
        <div className="drag-wraper">

          <div className="font-size14 mb-0 full-width">
            <div className={`float-left ${this.editQuestionCss(questionValue)}`}>{questionValue !== undefined && questionValue.isMandatory === true ? questionValue.queLabel : `${questionValue.queLabel} (Optional)`}</div>

            <Popover content={questionValue && questionValue.helpText}
              size="small" position="top">

<span className="float-left circle-alert">{/* <img src={infoHelpIcon} alt="help" /> */}
            <Icon name="status-info"></Icon></span>
            </Popover>
          </div>
          <div className="pt-5 grey">

            <Button icon="upload"
              onClick={() => 
                this.uploadRef.current.click() 
              }
              >

              Attach File
                </Button>
            {/* <AWSbutton
                    label="Attach"
                    isIcon={false}
                    // btnType="fill"
                    icon="external"
                    onClick={() => {
                      canEditPPR === true ? this.uploadRef.current.click() : ''
                    }}
                  /> */}
          </div>

          <Col span={24} className=" rightAttachLabel">
            <div className="upload-txt">
              <input
                ref={this.uploadRef}
                type="file"
                style={{ display: 'none' }}
                onChange={this.handleUpload}
                // onClick={this.handleClick}
                disabled={this.checkForDisable(questionValue,canEditPPR)}

              />

              <div className="float-left col-12 mb-1 pl-2 ml-2">
                
                { questionValue && questionValue.answer.answerValue && (
                  <div className="attach-txt cursor-pointer font-size14 float-left attachmentHeading" >{uploadText}</div>
                )}
                <div className="attach-txt float-left pt-2">
                  {/* <a
                    onClick={() => {
                      canEditPPR === true ? this.uploadRef.current.click() : ''
                    }}
                    className="attachLabel mt-2 ml-3"
                  >Click Here To Attach </a> */}
                  {/* <AWSbutton
                    label="Attach"
                    isIcon={false}
                    btnType="fill"
                    onClick={() => {
                      canEditPPR === true ? this.uploadRef.current.click() : ''
                    }}
                  /> */}
                </div>
              </div>
            </div>

            {questionValue && questionValue.answer.answerValue ?
              this.displayLink() :
              ""
            }
          </Col>
          {(questionValue && questionValue.isVisible === true) && (questionValue.answer.attachments === null || questionValue.answer.attachments.length === 0) && (this.state.error === true) && (<div className="has-error font-size10 red float-left col-12 p-0 pt-2"><span className="error-icon mr-0 float-left red">
            <Icon name={"status-warning"} />
          </span>{`${this.state.errorLabel} `}</div>)}
        </div>
        {this.state.activateToast && (
          <AWSLoadingToast
            message={this.state.loadingMessage}
            error={this.state.loadingError}
            duration={this.state.loadingMessageDuration}
          />
        )}
      </div>
    );
  }
}