import { createBrowserHistory } from "history";
import queryString from "query-string";
import axios from "axios";
import { getToken } from "../services/Oauth/Oauth";
import AWSConfig from "../config/AWSconfig";
import * as roleNames from '../constants/roleNames.const';
import * as routes from '../constants/routes.const';
let config = new AWSConfig();

const history = createBrowserHistory();


export default class Auth {
  authLogin = () => {
    axios({
      method: "GET",
      url: `${config.awsFalconAuthUrl}token/details`,
    }).then(resp => {
      resp.data.AuthHeader && localStorage.setItem("auth_header", resp.data.AuthHeader);
      localStorage.setItem("client_id", resp.data.client_id);
      localStorage.setItem("grant_type", resp.data.grant_type);
      localStorage.setItem("redirect_uri", resp.data.redirect_uri);
      localStorage.setItem("tokenexcahngeUrl", resp.data.tokenexcahngeUrl);
      window.location.href = resp.data.cognitoPoolUrl
    })
  }

  getCognitoAuthenticationHeaders = () => {
    const headers = {
      "Content-Type": 'application/x-www-form-urlencoded'
    };

    const authHeader = localStorage.getItem("auth_header");
    if (authHeader) {
      headers["Authorization"] = authHeader;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    return headers;
  }

  handleAuthentication = () => {

    if (history.location && history.location.search) {
      const access = queryString.parse(history.location.search);
      const params = new URLSearchParams();

      params.append('grant_type', localStorage.getItem("grant_type"));
      params.append('client_id', localStorage.getItem("client_id"));
      params.append('redirect_uri', localStorage.getItem("redirect_uri"));
      params.append('code', access.code);

      axios({
        method: "POST",
        url: localStorage.getItem("tokenexcahngeUrl"),
        data: params,
        headers: this.getCognitoAuthenticationHeaders()
      }).then(resp => {
        localStorage.setItem("cfat", resp.data.id_token);
        localStorage.setItem("cfart", resp.data.refresh_token);
        localStorage.setItem("cfatage", resp.data.expires_in);

        axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
        axios({
          method: "GET",
          url: `${config.awsFalconAuthUrl}token/authorizer`,
          headers: {
            'Authorization': localStorage.getItem("cfat")
          }
        }).then(respData => {
          if (respData && respData.data && respData.data.length > 0) {
            this.setPathAccessBasedOnUserRole(respData);
          }

        }).catch(() => {
          let location = {
            pathname: "login",
            state: {
              unAuthorized: true
            }
          };
          history.push(location);
          window.location.reload();
        });
      });
    }
  };

  checkValidAuthentication = (path) => {
    if (localStorage.getItem("cfat")) {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
        axios({
          method: "GET",
          url: `${config.awsFalconAuthUrl}token/authorizer`,
          headers: {
            'Authorization': localStorage.getItem("cfat")
          }
        }).then(respData => {
          if (respData && respData.data && respData.data.length > 0) {
            this.setPathAccessBasedOnUserRole(respData);
          }
        }).catch(() => {
          localStorage.clear();
          this.props.history.push("/");
        });
    }
  };


  setPathAccessBasedOnUserRole = (respData) => {
    if(respData.data[0].isSPUser){
      this.setSolutionProviderRole(roleNames.SOLUTION_PROVIDER, respData.data[0].UserRole);
    }


    const hasSecondaryRole = !!(respData && respData.data[0].SecondaryRole);
    const secondaryRoleOfUser = (hasSecondaryRole) ? respData.data[0].SecondaryRole : null;
    const primaryRoleOfUser = respData.data[0].UserRole;

    /**
     * Check for currentPath user clicked on from external
     */
    const currentPath = sessionStorage.getItem(routes.CURRENT_PATH);
    /**
     * Allowing View PPR Page and Dashboard page.
     * SIM: https://issues.amazon.com/issues/V513272023
     */
    const hasAllowedPath = /^\/create-ppr/|/view$/.test(currentPath) || /^\/Termination/|/view$/.test(currentPath) || /^\/dashboard$/.test(currentPath);

    /**
     * This variables decides weather to route to Admin page by default.
     * It Checks for userRole and also check for user has secondary role.
     * Final check is to view the PPR 
     * (Depends upon If user doesn't have secondary role - it will route back to admin page).
     */
    const shouldRouteToAdminPage = (respData && respData.data[0].UserRole === roleNames.ADMIN) && (!hasSecondaryRole || !hasAllowedPath);
    /**
     * Based on shouldRouteToAdminPage and hasAllowedPath it will 
     * decide weather to route directly to view PPR page or dashboard
     */
    const currentRouteToTake = (shouldRouteToAdminPage) ? routes.PATH_TO_ADMIN : (hasAllowedPath) ? currentPath : routes.PATH_TO_DASHBOARD;
    const currentPageName = (shouldRouteToAdminPage) ? roleNames.ADMIN : routes.DASHBOARD;
    /**
    * This change is added to directly route the link clicked by user on view PPR for all the users.
    */
    this.setRolesOfUserInLocalStorage(primaryRoleOfUser, secondaryRoleOfUser, hasAllowedPath);
    const currentUserRole = localStorage.getItem(roleNames.ACTIVEROLE);

    const userData = {
      firstName: respData.data[0].FirstName,
      lastName: respData.data[0].LastName,
      userRole: currentUserRole,
      alias: respData.data[0].AWSAlias,
      secondaryRole: secondaryRoleOfUser
    }
    localStorage.setItem("userDetails", JSON.stringify(userData));

    const result = getToken(
      currentRouteToTake,
      history,
      respData.data[0].FirstName,
      respData.data[0].LastName,
      currentUserRole,
      respData.data[0].AWSAlias,
      secondaryRoleOfUser
    );

    result.then((res) => {
      localStorage.removeItem("client_id");
      localStorage.removeItem("grant_type");
      localStorage.removeItem("redirect_uri");
      localStorage.removeItem("AuthHeader");
      localStorage.removeItem("tokenexcahngeUrl");
      window.history.replaceState(res, currentPageName, currentRouteToTake);
      window.location.reload();
    });
  }

  setSession = access => {
    let expiresAt = JSON.stringify(
      access.expires_in * 1000 + new Date().getTime()
    );
    localStorage.setItem("id_token", access.id_token);
    localStorage.setItem("expires_at", expiresAt);
  };

  setSecondaryRole = (secondaryRole, activeRole) => {
    localStorage.setItem(roleNames.SECONDARYROLE, secondaryRole);
    localStorage.setItem(roleNames.ACTIVEROLE, activeRole);
    localStorage.setItem(roleNames.ORIGINALSECONDARYROLE, secondaryRole);
  }

  setRolesOfUserInLocalStorage = (primaryRoleOfUser, secondaryRoleOfUser, hasAllowedPath) => {
    if(hasAllowedPath && primaryRoleOfUser === roleNames.ADMIN && secondaryRoleOfUser !== null) {
      localStorage.setItem(roleNames.SECONDARYROLE, primaryRoleOfUser);
      localStorage.setItem(roleNames.ACTIVEROLE, secondaryRoleOfUser);
      localStorage.setItem(roleNames.ORIGINALSECONDARYROLE, secondaryRoleOfUser);
    } else if(secondaryRoleOfUser !== null) {
        this.setSecondaryRole(secondaryRoleOfUser, primaryRoleOfUser);
    } else {
      localStorage.setItem(roleNames.ACTIVEROLE, primaryRoleOfUser);
    }
  }

  setSolutionProviderRole = (SolutionProvider, activeRole) => {
    localStorage.setItem(roleNames.SOLUTIONPROVIDERROLE, SolutionProvider);
    localStorage.setItem(roleNames.ACTIVEROLE, activeRole);
  }

  authlogout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.clear();
    // navigate to the home route
    history.replace("/");
    window.location.reload();
  };

  isAuthenticated = () => {
    let authToken = localStorage.getItem("cfat");
    return authToken;
    // let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    // return new Date().getTime() < expiresAt;
  };

  authLoginForRefreshToken = () => {
    let cfatAge = localStorage.getItem("cfatage");
    let timeInterval = (cfatAge * 1000) - 300000;
    if (timeInterval > 300000) {
      setInterval(() => {
        this.getParamsDetailForToken()
      }, timeInterval)
    }
  }

  getParamsDetailForToken() {
    axios({
      method: "GET",
      url: `${config.awsFalconAuthUrl}token/details`,
    }).then(resp => {
      resp.data.AuthHeader && localStorage.setItem("auth_header", resp.data.AuthHeader);
      localStorage.setItem("client_id", resp.data.client_id);
      localStorage.setItem("grant_type", resp.data.grant_type);
      localStorage.setItem("redirect_uri", resp.data.redirect_uri);
      localStorage.setItem("tokenexcahngeUrl", resp.data.tokenexcahngeUrl);
      this.getAccessTokenFromRefresToken(resp.data)
    })
  }

  getAccessTokenFromRefresToken(responseData) {
      const params = new URLSearchParams();
      params.append('grant_type', "refresh_token");
      params.append('client_id', responseData.client_id);
      params.append('refresh_token', localStorage.getItem('cfart'))

      axios({
        method: "POST",
        url: responseData.tokenexcahngeUrl,
        data: params,
        headers: this.getCognitoAuthenticationHeaders()
      }).then(resp => {

        localStorage.setItem("cfat", resp.data.id_token);
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("cfat");
      });
  }
}