import React, { Component } from 'react';
import { getFormattedDate } from '../../../utils/dates'
import AWSbutton from "../../common/AWSbutton/AWSbutton";
import { Icon } from "@amzn/awsui-components-react";
// import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import './AddQuestion.scss';
import NavBar from '../NavBar/NavBar';
import Question from './Question';
import DependentQuest from './DependentQuest';
import PermissionAndSeq from './PermissionAndSeq';
import AWSLoadingToast from '../../common/AWSLoadingToast/AWSLoadingToast';
import axios from 'axios';
import AWSConfig from '../../../config/AWSconfig';
import { getRateTableObj } from '../../../constants/qualifyingQuestionsConstants';

const config = new AWSConfig();

class AddQuestion extends Component {

    state = {
        activateToast: false,
        loadingMessage: '',
        loadingError: false,
        options: [],
        validationList: [],
        validationListOnQueType: [],
        selectedStage: 0,
        // dataModel: [{ stageName: "Question" }, { stageName: "Permissions and Sequence" }, { stageName: "Dependent Question" }],
        dataModel: [{ stageName: "Question" }, { stageName: "Permissions" }],
        menuIndex: 0,
        questionJsonNew: {
            stageId: null,
            parentDependentOn: {
                queId: null,
                depentOn: []
            },
            queBlock: [
                {
                    templateId: null,
                    queBlkId: null,
                    questions: [{
                        queId: null,
                        tableObj: null,
                        rowNum: 1,
                        columnNum: 1,
                        queType: null,
                        queLabel: null,
                        placeholder: null,
                        options: [],
                        isVisible: true,
                        isMandatory: true,
                        formGroup: null,
                        dependentOn: [{
                            alertMessage: null,
                            event: null,
                            option: null,
                            dependentType: null,
                            dependentId: null,
                            questionId: null,
                            visible: true
                        }],
                        defaultValue: null,
                        defaultAnswer: [],
                        blockRowId: null,
                        validation: [],
                        answer: null,
                        queSequence: null,
                        sourceQue: null,
                        isQueDelete: true,
                        helpText: null,
                        permissionSeq: [{
                            'SCE_Owner': { read: false, write: true, hidden: false },
                            'Sales_Manager': { read: true, write: false, hidden: false },
                            'PPO_Owner': { read: true, write: false, hidden: false },
                            'Sales_Representative': { read: false, write: true, hidden: false },
                        }],
                        rowSeq: [{ col: [{ value: false }, { value: false }, { value: false }] }]
                    }],
                }]
        }
    }

    /*
        calling validation and options api to render in question dropdown
    */
    componentDidMount() {
        this.callValidationInputApi();
        this.callOptionsApi();
    }

    /*
        show loading message when user try to add question when nothing is selected in dropdown
    */
    showUserManagementLoadingToast = (message, activeState) => {
        this.setState({
            activateToast: activeState,
            loadingMessage: message
        }, () => {
            setTimeout(() => {
                this.setState({
                    activateToast: false,
                })
            }, 1000)
        });
    }

    /*
    @param none
    @return {get api call for validation for all question}
    */

    callValidationInputApi = () => {
        const validationListApi = `${config.amazonFalconbaseUrl}validation`;
        axios({
            method: "GET",
            url: validationListApi
        })
            .then(resp => {
                let validationListOnQueType = resp.data.filter(({ queType }) => queType === "TEXT")

                this.setState({
                    validationList: resp.data,
                    validationListOnQueType
                })
            });
    }
    /*
    @param none
    @return {get api call for question}
    */
    callOptionsApi = () => {
        const optionsApi = `${config.amazonFalconbaseUrl}options`
        axios({
            method: 'GET',
            url: optionsApi
        }).then(res => {
            const options = res.data;
            this.setState({ options });
        })
    }

    /*
    @param none
    @return {create new dependent On Object}
    */
    createDependentOn = () => {
        const newDepOnObj = {
            alertMessage: null,
            event: null,
            option: null,
            dependentType: null,
            dependentId: null,
            questionId: null,
            visible: true
        }

        let depOnSet = new Set();
        depOnSet.add(newDepOnObj);
        this.state.questionJsonNew.queBlock[0].questions[0].dependentOn = [...depOnSet];
    }

    /*
    @param none
    @return {handle the modal/pop up close}
    */
    handleOk = () => {

        let { dataModel, selectedStage, sectionIndex, handleOk, dataColumn } = this.props;
        let { questionJsonNew } = this.state;
        const { queLabel, queType } = questionJsonNew.queBlock[0].questions[0];

        if (queType === undefined) {
            setTimeout(() => {
                this.showUserManagementLoadingToast('Please Add Question Type', true);
            }, 3000);
            return;
        } else if (queType === 'RADIOBUTTON') {
            questionJsonNew.queBlock[0].questions[0].defaultValue = 'Yes';
        } else if (queType === 'RATETABLE') {
            questionJsonNew.queBlock[0].questions[0].tableObj = getRateTableObj(questionJsonNew.queBlock[0].questions[0].options);
        }
        if (this.props.type === "editQuestionPPR") {
            handleOk(this.state.questionJson, dataColumn.questionSequence)
        } else {
            this.state.questionJsonNew.stageId = dataModel[selectedStage].templateStageId;
            this.state.questionJsonNew.queBlock[0].queBlkId = dataModel[this.props.selectedStage].queBlock[sectionIndex].queBlkId;
            this.state.questionJsonNew.queBlock[0].questions[0].rowNum = dataModel[this.props.selectedStage].queBlock[sectionIndex].questions.length + 1;
            const { questionId, option } = this.state.questionJsonNew.queBlock[0].questions[0].dependentOn[0];
            if (questionId === null && option === 'Select' || option === null) {
                this.createDependentOn();
            } else {
                const { dependentOn } = this.state.questionJsonNew.queBlock[0].questions[0];
                const { depentOn } = this.state.questionJsonNew.parentDependentOn;
                let depTemp = { ...dependentOn[0] };
                if (depTemp.event === 'On Select')
                    depTemp.event = 'Off Select';
                dependentOn.push(depTemp);
                this.state.questionJsonNew.parentDependentOn.depentOn = [...new Set([...depentOn, ...dependentOn])];
                this.createDependentOn();

            }
            handleOk(this.state.questionJsonNew)
        }
    }
    /*
    @param {array questionJson, string value, integer index} 
    @return {map value for answer by creating new option object}
    */

    createNewOptions = (questionJsonNew, value, index, iconSource) => {
        let newOptionObject = {
            sF_Id: null,
            questionId: null,
            optionValue: value,
            optionOrder: index,
            optionKey: value,
            IconSource: iconSource,
            defaultValue: null,
            cellId: null
        }
        questionJsonNew.queBlock[0].questions[0].options.push(newOptionObject);
    }

    /*
    @param {string value, integer index}
    @return {map options choosen for answer option to question json}
    */
    createOptions = (value, index) => {
        let { questionJsonNew } = this.state;
        let optionArrayLength = questionJsonNew.queBlock[0].questions[0].options.length
        let iconSource = null
        if (index > (optionArrayLength - 1)) {
            const { queType } = questionJsonNew.queBlock[0].questions[0];

            if (queType === 'BUTTONICON') {
                const { options } = this.state;
                const filterdOpt = options.filter((opt) => opt.optionKey === queType)
                iconSource = filterdOpt[0].IconSource;
                this.createNewOptions(questionJsonNew, value, index, iconSource)
            } else
                this.createNewOptions(questionJsonNew, value, index, iconSource)
        } else {
            questionJsonNew.queBlock[0].questions[0].options[index].optionKey = value;
            questionJsonNew.queBlock[0].questions[0].options[index].optionValue = value;
            questionJsonNew.queBlock[0].questions[0].options[index].optionOrder = index;
        }
    }

    /*
    @param {string value, integer index}
    @return {map checkbox values to questionJson}
    */
    setDefaultValueForAnswer = (value, index) => {
        const { questionJsonNew } = this.state;
        const { defaultAnswer, options } = questionJsonNew.queBlock[0].questions[0];
        for (let i = 0; i < defaultAnswer.length; i++) {
            defaultAnswer[i] = false;
        }

        options.map((opt) => {
            if (opt.optionOrder === index)
                questionJsonNew.queBlock[0].questions[0].defaultValue = opt.optionValue
        })
        defaultAnswer[index] = value;
    }

    /*
    @param {integer index}
    @return {map removed options and checked box value to questionJson}
    */
    removeOptions = (index) => {
        const { questionJsonNew } = this.state;
        const { options, defaultAnswer } = questionJsonNew.queBlock[0].questions[0];
        if (index === undefined) {
            const newDefAnswer = [];
            const newOptions = [];
            questionJsonNew.queBlock[0].questions[0].options = newOptions;
            questionJsonNew.queBlock[0].questions[0].defaultAnswer = newDefAnswer;
        } else {
            const newOptions = options.slice(0, index).concat(options.slice(index + 1, options.length));
            const newDefaultAnswer = defaultAnswer.slice(0, index).concat(defaultAnswer.slice(index + 1, defaultAnswer.length));
            questionJsonNew.queBlock[0].questions[0].options = newOptions;
            questionJsonNew.queBlock[0].questions[0].defaultAnswer = newDefaultAnswer;
        }
        return;

    }

    /*
    @param {string value}
    @return {set all validation which are for selected question type}
    */
    handleValidationListOption = (value) => {
        let { validationList } = this.state;

        let validationListOnQueType = validationList.filter(({ queType }) => queType === value)

        this.setState({
            validationListOnQueType
        })
    }

    /*
    @param {string value, string type, integer index}
    @return {map values of different fields to questio json}
    */
    handleQuestionChange = (value, type, index) => {
        let { questionJsonNew } = this.state;

        switch (type) {
            case 'questionType':
                this.handleValidationListOption(value)
                questionJsonNew.queBlock[0].questions[0].queType = value;
                break;
            case 'isVisible':
                questionJsonNew.queBlock[0].questions[0].isVisible = value;
                break;
            case 'isMandatory':
                questionJsonNew.queBlock[0].questions[0].isMandatory = value;
                break;
            case 'queLabel':
                questionJsonNew.queBlock[0].questions[0].queLabel = value;
                break;
            case 'isDefault':
                this.setDefaultValueForAnswer(value, index);
                break;
            case 'options':
                this.createOptions(value, index)
                break;
            case 'helpText':
                questionJsonNew.queBlock[0].questions[0].helpText = value;
                break;
            case 'permission':
                break;

        }
        this.setState({
            questionJsonNew
        })
    }

    /*
    @param {string value, string type, number questionID, array depOn }
    @return {map values to questionJson dependentOn and parentDepOn attribute}
    */
    setDependentOn = (value, type, questionID, depOn) => {       

        const { questionJsonNew } = this.state;
        switch (type) {
            case 'event':
                questionJsonNew.queBlock[0].questions[0].dependentOn[0].event = value;
                break;
            case 'alertMessage':
                questionJsonNew.queBlock[0].questions[0].dependentOn[0].alertMessage = value;
                break;
            case 'option':
                questionJsonNew.queBlock[0].questions[0].dependentOn[0].option = value;
                break;
        }
        if (questionID !== null) {
            questionJsonNew.queBlock[0].questions[0].dependentOn[0].questionId = questionID;
            questionJsonNew.parentDependentOn.queId = questionID;
            // if (depOn.length > 0) {
                const { depentOn } = questionJsonNew.parentDependentOn;
                const { dependentOn } = questionJsonNew.queBlock[0].questions[0];
                if (depentOn.length === 0)
                    questionJsonNew.parentDependentOn.depentOn = [...dependentOn]
                else {
                    questionJsonNew.parentDependentOn.depentOn = [...new Set([...dependentOn])];
                }
            // }
        }
        this.setState({ questionJsonNew })
    }

    setPermissionsHandler = (permission, val, userRole) => {
        let permissionKey = Object.keys(permission[0][userRole]);
        for (let i = 0; i < permissionKey.length; i++) {
            if (permissionKey[i] === val) {
                permission[0][userRole][`${permissionKey[i]}`] = true;
            } else {
                permission[0][userRole][`${permissionKey[i]}`] = false;
            }
        }
        return;
    }

    /**
     * This method updates the permission state for diff user roles
     * @param {*} value text
     * @param {*} userRole text
     */
    handlePermission = (value, userRole) => {
        let _userRole = userRole.split(' ').join('_');

        const { questionJsonNew } = this.state
        const { permissionSeq } = questionJsonNew.queBlock[0].questions[0];

        switch (_userRole) {
            case 'Sales_Manager':
                this.setPermissionsHandler(permissionSeq, value, 'Sales_Manager');
                break;
            case 'SCE_Owner':
                this.setPermissionsHandler(permissionSeq, value, 'SCE_Owner');
                break;
            case 'PPO_Owner':
                this.setPermissionsHandler(permissionSeq, value, 'PPO_Owner');
                break;
            case 'Sales_Representative':
                this.setPermissionsHandler(permissionSeq, value, 'Sales_Representative');
                break;
            default:

        }
        this.setState({ questionJsonNew });
    }

    /**
    * This method updates the sequence position of question based on selection
    * @param {*} rowIndex rowIndex
    */
    handleSequencing = (rowIndex, colIndex, type) => {
        const { questionJsonNew } = this.state;
        let rowNum = questionJsonNew.queBlock[0].questions[0].rowNum;

        if (type === "removeSequence" && rowNum === rowIndex) {
            questionJsonNew.queBlock[0].questions[0].rowNum = null;
            questionJsonNew.queBlock[0].questions[0].columnNum = null;
        } else {
            questionJsonNew.queBlock[0].questions[0].rowNum = rowIndex;
            questionJsonNew.queBlock[0].questions[0].columnNum = colIndex;
        }

        this.setState({ questionJsonNew });
    }

    /*
    @param {array selected validation}
    @return {add all validation to question json}
    */
    handleAddValidation = (validationArr) => {
        const { questionJsonNew } = this.state;
        if (validationArr.length > 0) {
            questionJsonNew.queBlock[0].questions[0].validation = [...validationArr];
        } else {
            questionJsonNew.queBlock[0].questions[0].validation = [];
        }
    }

    /*
    @param {number index of menu}
    @return {map to to particular tab viz question, permission and...}
    */
    handleSelectTab = menuIndex => this.setState({ selectedStage: menuIndex })

    render() {

        let { questionJsonNew, selectedStage, validationListOnQueType } = this.state;

        const { location: { state: { firstName, lastName } } } = this.props
        return (
            <div className="addquestion-ppr popup full-width">
                <div className="full-width header-wraper">
                    <p className="pt-4 pl-5 pb-4 pr-3 font-size16 dark-black mb-0 amber-bold">
                        Add Question
                        <span className="float-right cursor-pointer pr-2 pt-1 close-icon" onClick={() => this.props.handleCancel()}>
                            <Icon name="close"></Icon>
                        </span>
                    </p>
                </div>
                <div className="col-12 float-right p-0">
                    <div className='full-width border-bottom'>
                        <div className="col-12 pl-5 pt-5 float-left">
                            <NavBar
                                selectedStage={this.state.selectedStage}
                                handleSelectTab={(menuIndex) => { this.handleSelectTab(menuIndex) }}
                                dataModel={this.state.dataModel}
                            />
                        </div>
                    </div>
                </div>
                <div className="tabcontent-wraper full-width mb-3 scroll-width5 moz-scroll">
                    {selectedStage === 0 &&
                        (
                            <Question
                                options={this.state.options}
                                questionJson={questionJsonNew}
                                validationListOnQueType={validationListOnQueType}
                                handleQuestionChange={(value, type, index) => { this.handleQuestionChange(value, type, index) }}
                                handleAddValidation={(val) => this.handleAddValidation(val)}
                                removeOptions={this.removeOptions}
                            />

                        )
                    }
                    {selectedStage === 1 &&
                        (
                            <PermissionAndSeq
                                questionJson={questionJsonNew}
                                selectedStage={this.props.selectedStage}
                                sectionIndex={this.props.sectionIndex}
                                updateQuestion={false}
                                oldDataModel={this.props.dataModel}
                                handlePermission={(value, userRole) => { this.handlePermission(value, userRole) }}
                                handleSequencing={(rowIndex, colIndex, type) => { this.handleSequencing(rowIndex, colIndex, type) }}
                            />)
                    }
                    {/* {selectedStage === 2 && (
                        <DependentQuest
                            questionJson={questionJsonNew}
                            setDependentOn={(value, type, questionId, depOn) => this.setDependentOn(value, type, questionId, depOn)}
                        />
                    )} */}
                </div>

                <div className="footer-wraper full-width pr-4">
                    <span className="float-left pt-4 mt-2 pl-4">Created By <span className="dark-blue">{`${firstName} ${lastName} ${getFormattedDate()}`}</span></span>
                    <div className="mb-3 mt-4 float-right btn-container p-0">
                        <div className="float-left full-width pr-3 pb-2">
                            <span className="float-left mr-3">
                                <AWSbutton
                                    btnType="btns unfill-button"
                                    label="Cancel"
                                    isIcon={false}
                                    onClick={() => this.props.handleCancel()}
                                />
                            </span>
                            <span className="float-right">
                                <AWSbutton
                                    btnType="fill"
                                    label={this.props.type === "editQuestionPPR" ? "Update" : "Add"}
                                    isIcon={false}
                                    onClick={() => this.handleOk()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                {this.state.activateToast && (
                    <AWSLoadingToast
                        message={this.state.loadingMessage}
                        error={this.state.loadingError}
                        duration={this.state.loadingMessageDuration}
                    />
                )}
            </div>
        )
    }
}

export default AddQuestion;
