import { getToken } from "../services/Oauth/Oauth";
import * as roleNames from '../constants/roleNames.const';
import { NEW_QUESTION_LABEL, QUESTION_LABELS, UPDATE_LABELS_OBJECT } from "../constants/questionAnswerLabelConstants";

export const isValidFile = (file) => {
  const splits = file.split('.');
  const extension = splits[splits.length - 1];
  const validExtension = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'ppt', 'pptx', 'txt', 'csv'];

  return validExtension.includes(extension);
};

export const isValidJson = (validatejsontext) => {
  try {
    JSON.parse(JSON.stringify(validatejsontext))
    return true
  }
  catch (e) {
    return false
  }
}


export const base64toBlob = (base64Data, contentType) => {
  contentType = contentType || '';
  if (contentType === 'application/docx') {
    contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  } else if (contentType === 'application/doc') {
    contentType = 'application/msword';
  } else {
    contentType = contentType;
  }
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const userRoleCheckForEditableCell = (locationStateUser, userRole, dealInfo, roleNames) => {
  switch (userRole) {

    case roleNames.SALES_REPRESENTATIVE:
      return locationStateUser && dealInfo.Sales_Representative === locationStateUser.alias ? true : false

    case roleNames.SCE_OWNER:
      return locationStateUser && dealInfo.SCE_Owner === locationStateUser.alias ? true : false

    case roleNames.IGT_OWNER:
      return locationStateUser && dealInfo.PPO_Owner === locationStateUser.alias ? true : false

    case roleNames.PRIVATE_PRICING_CHINA:
      return locationStateUser && dealInfo.Private_Pricing_China === locationStateUser.alias ? true : false

    case roleNames.PRIVATE_PRICING_PUBLIC_SECTOR:
      return locationStateUser && dealInfo.Private_Pricing_Public_Sector === locationStateUser.alias ? true : false

    case roleNames.SALES_MANAGER:
      return false

    default:
      return false;
  }
}

export const getTokenBasedOnRole = (secondaryRole, activeRole, locationState, history, isReturn = false, solutionProvider = false) => {
  if (locationState) {
    if (activeRole === roleNames.IGT_OWNER && secondaryRole === roleNames.PRIVATE_PRICING_CHINA) {
        localStorage.setItem(roleNames.ACTIVEROLE, roleNames.PRIVATE_PRICING_CHINA);
    } else if (activeRole === roleNames.PRIVATE_PRICING_CHINA && secondaryRole === roleNames.IGT_OWNER) {
        localStorage.setItem(roleNames.ACTIVEROLE, roleNames.IGT_OWNER);
    } else if (secondaryRole === roleNames.ADMIN) {
        localStorage.setItem(roleNames.ACTIVEROLE, secondaryRole);
        localStorage.setItem(roleNames.SECONDARYROLE, localStorage.getItem(roleNames.ORIGINALSECONDARYROLE));
    } else {
        if (solutionProvider === true) {
            localStorage.setItem(roleNames.SOLUTIONPROVIDERROLE, activeRole)
        } else {
            localStorage.setItem(roleNames.SECONDARYROLE, activeRole);
        }
        localStorage.setItem(roleNames.ACTIVEROLE, secondaryRole);
    }

    const pathname = localStorage.getItem(roleNames.ACTIVEROLE) === roleNames.ADMIN ? "admin" : "dashboard";
    const tokenResult = getToken(
      pathname,
      history,
      locationState.firstName,
      locationState.lastName,
      secondaryRole,
      locationState.alias
    );

    if (isReturn === true) {
      return tokenResult;
    } else {
      const title = localStorage.getItem(roleNames.ACTIVEROLE) === roleNames.ADMIN ? "Admin Console" : "Dashboard";
      const url = `/${pathname}`;
      tokenResult.then((res) => {
        window.history.replaceState(res, title, url);
        window.location.reload();
      });
    }
  }
}

const FalconDomainName = {
  BETA: 'beta.falcon.sales.aws.a2z.com',
  GAMMA: 'gamma.falcon.sales.aws.a2z.com',
  PROD: 'falcon.sales.aws.a2z.com'
}

export const StageName = {
  BETA: "Beta",
  GAMMA: "Gamma",
  PROD: "Prod"
}

export const getEnv = () => {
  const domainName = window && window.location && window.location.hostname;
  switch (domainName) {
      case FalconDomainName.PROD:
          return StageName.PROD;
      case FalconDomainName.GAMMA:
          return StageName.GAMMA;
      case FalconDomainName.BETA:
      default:
          return StageName.BETA;
  }
}

//TODO:DEPS-9618 Remove isAfterOrgChange logic
export const isAfterOrgChange = () => {
  const currentEnv = getEnv();
  switch (currentEnv) {
    case FalconDomainName.PROD:
        return true;
    case FalconDomainName.GAMMA:
        return true;
    case FalconDomainName.BETA:
    default:
        return true;
  }
}

export const getQuestionLabelOverride = (questionLabel) => {
  switch (questionLabel) {
    case QUESTION_LABELS.CustomerType: {
      return isAfterOrgChange() ? NEW_QUESTION_LABEL.CustomerStatus : questionLabel;
    }
    case QUESTION_LABELS.BusinessCustomerType: {
      return isAfterOrgChange() ? NEW_QUESTION_LABEL.CustomerType : questionLabel;
    } 
    default: {
      return questionLabel;
    }
  }
}

export const getOptionsOverride = (questionLabel, options) => {
  if (questionLabel === QUESTION_LABELS.BusinessUnit && isAfterOrgChange()) {
    const updatedOptions = options.map( option => {
      const optionKey = option.optionKey;

      return { ...option, optionLabel: UPDATE_LABELS_OBJECT[QUESTION_LABELS.BusinessUnit][optionKey] || optionKey };
    });
    return updatedOptions;
  }
  return options;   
}
