import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import AWSConfig from "../../config/AWSconfig";
import { base64toBlob } from "../../utils/common";
import PPRDownload from "../../json/pprdownload.json";
import downloadIcon from '../../images/awsfalcondownload.svg';
import downArrowIcon from '../../images/awsfalcondownArrow.svg';
import { Spinner } from "@amzn/awsui-components-react";
import * as roleNames from '../../constants/roleNames.const';

import './Controls.scss';
import AuditTrail from "./AuditTrail/AuditTrail";

const config = new AWSConfig();
const versionClickEvent = React.createRef();

const Controls = ({
  pprId,
  shouldShowVersioning = false,
  shouldShowPTS = false,
  locationState,
  history,
  showToast,
  createdById,
  onComponentRendered
}) => {
  const baseUrl = config.amazonFalconbaseUrl;
  const ptsDownloadUrl = config.ptsDownload;
  const [versionData, setVersionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showVersionData, setShowVersionData] = useState(false);

  const { alias, userRole } = locationState || {};

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (versionClickEvent && versionClickEvent.current && versionClickEvent.current.contains && versionClickEvent.current.contains(e && e.target)) {
      // inside click
      return;
    }
    setShowVersionData(false)
  }

  const displayVersionData = pprId => {
    if (showVersionData) {
      setShowVersionData(false)
    } else {
      setShowVersionData(true)
      getVersioningData(pprId)
    }
  }

  const getVersioningData = pprId => {

    setLoading(true);

    axios({
      method: "POST",
      url: `${baseUrl}cfppr/versions`,
      data: {
        AWSAlias: createdById,
        userRole: roleNames.SALES_REPRESENTATIVE,
        dealInfo: {
          pprName: pprId
        }
      }
    })
      .then(resp => {
        setVersionData(resp.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getPdfData = () => {
    showToast("Getting the PTS data...", true);
    let data = {
      "createdById": createdById,
      "pprId": "PPR-" + pprId
    };
    axios({
      method: "POST",
      url: `${baseUrl}dashboard/pts`,
      data: data
    })
      .then(resp => {
        showToast("Downloading the PTS data...", true);
        axios({
          method: "POST",
          url: ptsDownloadUrl,
          data: resp.data,
          headers: {
            "Content-Type": 'application/json',
            // 'Authorization': localStorage.getItem("AuthHeader")
          }
        })
          .then(resp => {
            showToast("Generating PTS...", false);
            const contentType = `application/pdf`;

            var blob = base64toBlob(resp.data.body, contentType);

            var url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${pprId}_PTS.pdf`;
            a.click();
          })
          .catch(() => {
            showToast("Generating PTS...", false);
          });
      })
      .catch(() => {
        showToast("Generating PTS...", false);
      });

  };

  const loadVersion = (pprId) => {
    const pathNameForPPr =
      "/create-ppr/" + pprId + "/view";
    let location = {
      pathname: pathNameForPPr,
      state: locationState
    };
    data && data.length && history.replace(location);
  }

  const data = versionData && versionData.sort((x, y) => {
    const id = `PPR-${pprId}`;
    return x.pprId === id ? -1 : y.pprId === id ? 1 : 0;
  });


  return (
    <div className="controls-wrapper mb-0 mt-0">
      <div className="full-width">
        <div className="controls mt-3 mr-4">
          <div className="float-right width-auto p-0">
            <span className="dark-black font-size14 amber-bold col-8 cursor-pointer pr-4" onClick={() => onComponentRendered(null,"refreshOnId")} >
              {pprId ? `PPR ID - ${pprId}` : ""}
            </span>

            <div className="width-auto float-right">
              {shouldShowVersioning && (
                <div className="float-left pr-3 post-relat">
                  <div
                    className="pl-0 version-txt cursor-pointer"
                    onClick={() => displayVersionData(pprId)}
                  >
                    <span className="font-size14 pr-3">Version History</span> <span className="down-arrow float-right">
                      <img src={downArrowIcon} alt="downArrow" /></span>
                  </div>

                  <div ref={versionClickEvent} className={showVersionData === true ? "audit-trail" : ""}>
                    {
                      showVersionData && (loading === false) && data && data.map((data) => {
                        return (
                          <li key={data.pprId} onClick={() => { loadVersion(data.pprId) }} className="cursor-pointer">
                            <div className="txt">
                              <span className="mr-3 float-left">{data.pprId}</span>

                            </div>
                            <div className="txt">
                              <span className="float-right text-right">
                                {moment(data.createdDate).format("MM-DD-YYYY  hh:mm A")}
                              </span>

                            </div>
                          </li>
                        )
                      })
                    }
                    {
                      showVersionData && (loading === false) && data && data.length === 0 &&
                      <li key={0} >
                        <div>
                          No Data Available
                              </div>
                      </li>
                    }

                    {
                      showVersionData && (loading === true) &&
                      <li key={0} >
                        <div>
                          <Spinner variant="disabled" size="normal"></Spinner>
                        </div>
                      </li>
                    }

                  </div>

                </div>
              )}
              <div className="float-left">
                <AuditTrail
                  alias={alias}
                  createdById={createdById}
                  pprId={pprId}
                  history={history}
                  locationState={locationState}
                  onComponentRendered={onComponentRendered}
                />
              </div>

            </div>


            {/* {shouldShowPTS &&
              <div className="float-right full-width pr-4 pt-4 generate-pts" onClick={() => getPdfData()}>
                <div className="float-right cursor-pointer">
                  <span className="float-left pr-3">
                    Generate PTS
                  </span>
                  <span className="download-icon float-left">
                    <img src={downloadIcon} alt="download" /></span></div></div>
            } */}
            {/* <span className="mr-3"><img src={share} alt="share"/></span> */}
            {/* <span className="mr-3"><img src={download} alt="download"/></span> */}
            {/* <span><img src={save} alt="save"/></span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Controls;
