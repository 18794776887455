import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import "./LeftNavigationSummary.scss";
import Col from "../../common/AWSCol/AWSCol";
import "./Leftnavigation";
import AWSPolarisTable from "../AWSEditableTable/AWSPolarisTable";
import { base64toBlob } from "../../../utils/common";
import AWSHtmlTable from "../AWSHtmlTable/AWSHtmlTable";
import * as Constants from "../../../constants/dealStructureConstants";
import * as qualifyingQuestionsConstants from "../../../constants/qualifyingQuestionsConstants";
import AWSbutton from "../AWSbutton/AWSbutton";
import CopyIcon from "../../../images/Copy.svg";
import closeIcon from '../../../images/icon_close.svg';
import downloadWhite from '../../../images/pts_download.svg';
import AWStextarea from "../AWStextarea/AWStextarea";
import AWSconfig from "../../../config/AWSconfig";
import * as roleNames from '../../../constants/roleNames.const';
import * as dealStatusConstant from '../../../constants/dealStatusConstant';
import * as tagNameConstant from '../../../constants/tagNameConstant';
import { isCloudFrontShieldAdvancedDeal,
        CLOUD_FRONT_SHIELD_ADVANCED_QUESTION_LABEL,
        getCloudFrontShieldAdvancedAosValue } from '../../../constants/cloudFrontShieldAdvancedConstants';
import AWSModal from '../AWSModal/AWSModal';
import youtubeIcon from '../../../images/Play.png';
import { Icon } from "@amzn/awsui-components-react";
import VideoIcon from '../../common/Icon/VideoIcon/VideoIcon';
import { NEW_GEO_CHINA, OLD_BUSINESS_UNIT_ANSWER_LABELS, OLD_GEO_CHINA, isChinaBusinessUnit } from "../../../constants/questionAnswerLabelConstants";

const config = new AWSconfig();
let baseUrl;
const isFirefox = typeof InstallTrigger !== 'undefined';
const DiscountTermEffectiveDateLabel = "Discount Term Effective Date";
const dealTypeTagName = tagNameConstant.dealTypeTag;
const businessJustTagName = tagNameConstant.provideBusinessDetailsTag;
const TermLengthLabel = "Term Length (Months)";
const ptsbaseUrl = config.amazonFalconbaseUrl;
const ptsDownloadUrl = config.ptsDownload;
const affiliate_Payer_Id = "Affiliate Payer Id";
const payerIdLabel = "Payer Id";
let validationArray = [
  {
    cellId: null,
    message: "Please Enter Comments before proceeding",
    type: "Required",
    criteria: null
  }
];

let validatingQuestion = {
  isVisible: true
};

const commitAndPricingSummaryData = ["NoCommit", "CFRC Type", "Commit Type", "Commitment Fee", "Request fees", "Minimum Average Object Size", "Commit Volume", "Minimum Committed Revenue", "Traffic Limit", "Business Justification", "regionalMix", "regionalTraffic", "defaultLoad", "pricingTermsFlag"]

export default class LeftnavigationSummary extends Component {
  constructor(props) { super(props); }

  state = {
    volumeUnit: "TB",
    textAreaValue: "",
    Salesrep: "",
    combinedTraffic: 0,
    basicModalVisible: false,
    URLPath: '',
    otherAwsValue: null,
    originWithAWS: null,
    specifiedOriginWithAWS: null,
    crossServicePPA: null,
    negotiatingCrossServicePPA: null,
    productAtRiskList: null,
    areWeInCompetetiveSituation: "No",
    competitorOffers: null,
    subRegionMix: null,
    winDeal: null,
    explainWindeal: null,
    pastPrice: null,
    floorPrice: null,
    showPriceDetail: false,
    alertMessageSCEReviewed: null
  };

  copyRef = React.createRef();
  copyURLRef = React.createRef();

  componentDidMount() {
    baseUrl = config.amazonFalconbaseUrl;
    let userRoleAndName = this.props.locationStateUser;
    let SalesRep =
      userRoleAndName !== undefined
        ? userRoleAndName.firstName + " " + userRoleAndName.lastName
        : "";

    this.setState({
      Salesrep: SalesRep
    });
    this.props.pprData &&
      this.props.pprData[0].queBlock.map(
        (questionBlockRow, questionBlockRowIndex) => {
          questionBlockRow.rows &&
            questionBlockRow.rows.map(
              (questionRow, questionrowIndex) => {
                questionRow.questions &&
                  questionRow.questions.map(
                    (question, questionIndex) => {
                      this.setSummaryDataFromApiForFirstPage(question);
                    }
                  );
              }
            );
        }
      );

    this.props.pprData &&
      this.props.pprData[1].queBlock.map(
        (questionBlockRow, questionBlockRowIndex) => {
          this.props.pprData &&
            this.props.pprData[1].queBlock.map(
              (questionBlockRow, questionBlockRowIndex) => {
                questionBlockRow.rows &&
                  questionBlockRow.rows.map(
                    (questionRow, questionrowIndex) => {
                      questionRow.questions &&
                        questionRow.questions.map(
                          (question, questionIndex) => {
                            this.setSummaryDataFromApi(question);
                          }
                        );
                    }
                  );
              }
            );
        }
      );
    this.setState({
      textAreaValue: ""
    });
    this.setRateCarData();
    //this.copyloadText();
  }


  /**
 * this function will set value in summary page from Api
 */
  setSummaryDataFromApiForFirstPage = question => {

    let { summaryData, tempArray, customerLegalName, affiliatedIdArray = [] } = this.props

    if (question.tagName === tagNameConstant.otherAWSServiceTag) {
      let otherAwsValueService = [];
      question.formGroup && question.formGroup.rows && question.formGroup.rows.map((formGroupRow, formGroupRowIndex) => {
        if (formGroupRowIndex !== 0) {
          if (formGroupRow.cells && formGroupRow.cells[0] && formGroupRow.cells[0].value !== "None") {
            otherAwsValueService.push(formGroupRow.cells[0].value);
          }
        }


        this.setState({
          otherAwsValue: otherAwsValueService.join("; ")
        });

      })
    }

    if (question.tagName === tagNameConstant.endCustomerLegalEntityName) {
      summaryData['End Customer Legal Entity Name'] = question.answer.answerValue;
    }

    if (tempArray && tempArray.length) {
      summaryData[payerIdLabel] = tempArray.join();
    }

    if (affiliatedIdArray && affiliatedIdArray.length) {
      summaryData[affiliate_Payer_Id] = affiliatedIdArray.join();
    }

    if (question.tagName === tagNameConstant.geoTag && question.isVisible === true) {
      summaryData["Geo"] = question.answer.answerValue;
    } else if (question.tagName === tagNameConstant.businessUnitTag && question.answer && isChinaBusinessUnit(question.answer.answerValue)) {
      summaryData["Geo"] = question.answer.answerValue === OLD_BUSINESS_UNIT_ANSWER_LABELS ? OLD_GEO_CHINA : NEW_GEO_CHINA;
    } else if (question.tagName === tagNameConstant.geoTagPs && question.isVisible === true) {
      summaryData["Geo"] = question.answer.answerValue;
    }

    if (question.tagName === tagNameConstant.businessCustomerType) {
      summaryData["Customer Type"] = question.answer.answerValue;
    }

    if (question.tagName === tagNameConstant.customerLegalNameTag) {
      summaryData["Customer Legal Name"] = question.answer.answerValue;
    }

    this.setState({
      textAreaValue: ""
    });
  };

  componentDidUpdate(prevProps) {
    let { summaryData, affiliatedIdArray } = this.props

    if (prevProps.affiliatedIdArray !== affiliatedIdArray) {
      if (affiliatedIdArray && affiliatedIdArray.length) {
        summaryData[affiliate_Payer_Id] = affiliatedIdArray.join();
      }
    }
  }

  /**
   * this function will set value in summary page from Api
   */
  setSummaryDataFromApi = question => {
    if (
      question.queLabel ===
      "Is your customer agreeable that AWS may use their name and logo to identify them as a customer?"
    ) {
      this.props.summaryData["References"] = question.answer.answerValue;
    }
    if (question.queLabel === DiscountTermEffectiveDateLabel) {
      this.props.summaryData[DiscountTermEffectiveDateLabel] =
        question.answer.answerValue;
    }
    if (question.tagName === dealTypeTagName && question.answer.answerValue !== null) {
      this.props.summaryData["Deal Type"] =
        question.answer.answerValue;
    }
    if (question.tagName === businessJustTagName) {
      this.props.summaryData["Business Justification"] =
        question.answer.answerValue;
    }
    if (question.tagName === tagNameConstant.termLengthTag) {
      if (this.props.summaryData[DiscountTermEffectiveDateLabel] !== null) {
        let jan312009 = new Date(
          this.props.summaryData[DiscountTermEffectiveDateLabel]
        );
        let eightMonthsFromJan312009 = jan312009.setMonth(
          jan312009.getMonth() + parseInt(question.answer.answerValue)
        );
        this.props.summaryData["Discount Term End Date"] = moment(
          eightMonthsFromJan312009
        )
          .subtract(1, "days")
          .format("MM/DD/YYYY");
      }
      if (question.answer.answerValue !== '') {
        this.props.summaryData[TermLengthLabel] = question.answer.answerValue;
      }
    }

    if (question.tagName === tagNameConstant.currentDiscountTermEndCalTag) {
      this.props.summaryData["Current Discount Term End Date"] =
        question.answer.answerValue;
    }

    if (question.tagName === tagNameConstant.originAWSTag) {
      this.setState({
        originWithAWS: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.specifyOriginAWS) {
      this.setState({
        specifiedOriginWithAWS: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.existingCrossServicePPA) {
      this.setState({
        crossServicePPA: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.negotiatingCrossServicePPA) {
      this.setState({
        negotiatingCrossServicePPA: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.productAtRisk) {
      this.setState({
        productAtRiskList: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.areWeInCompetetiveSituation) {
      this.setState({
        areWeInCompetetiveSituation: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.competitorOffers) {
      this.setState({
        competitorOffers: question.answer.answerValue
      })
    }

    if (question.tagName === tagNameConstant.autoRenewTag) {
      this.props.summaryData["Auto Renew"] =
        question.answer.answerValue;
    }

    if (question.tagName === tagNameConstant.cloudFrontShieldAdvanced) {
      this.props.summaryData[CLOUD_FRONT_SHIELD_ADVANCED_QUESTION_LABEL] =
        question.answer.answerValue;
    }

    this.setState({
      textAreaValue: ""
    });
  };

  setForAdditionalQuestion = (parsedData) => {
    // For additional question sub_region_mix
    this.setState({
      subRegionMix: parsedData.sub_region_mix,
      projectedStorage: parsedData.projected_storage,
      winDeal: parsedData.winDeal,
      explainWindeal: parsedData.explainWindeal
    })

    if (parsedData.VolumeCommitCheckBox[1].status === true) {
      this.setState({
        showPriceDetail: true,
        pastPrice: parsedData.past_price,
        floorPrice: parsedData.floor_price
      })
    } else if (parsedData.VolumeCommitCheckBox[3].status === true) {
      this.setState({
        showPriceDetail: true,
        pastPrice: parsedData.past_price_noCommit,
        floorPrice: parsedData.floor_price_noCommit

      })
    } else {
      this.setState({
        showPriceDetail: false
      })
    }
  }
  /**
   * this function will set rate card value to summary page
   */
  setRateCarData = () => {
    if (this.props.rateCardData) {
      let parsedData = JSON.parse(this.props.rateCardData);
      this.setForAdditionalQuestion(parsedData)

      this.setState({
        volumeUnit: parsedData.volumeUnit
      });
      // For all question other than additional
      if (parsedData.VolumeCommitCheckBox[0].status === true) {
        this.props.summaryData["CFRC Type"] = "Volume Commit";
      }
      if (parsedData.VolumeCommitCheckBox[1].status === true) {
        this.props.summaryData["CFRC Type"] = "Commitment Fee";
      }
      if (parsedData.VolumeCommitCheckBox[0].status === true && parsedData.VolumeCommitCheckBox[1].status === true) {
        this.props.summaryData["CFRC Type"] = "Volume Commit, Commitment Fee";
      }
      if (parsedData.VolumeCommitCheckBox[0].status === true || parsedData.VolumeCommitCheckBox[1].status === true) {
        if (parsedData.minimum_commitment_fee !== "") {
          this.props.summaryData["Minimum Committed Revenue"] =
            "$ " + parsedData.minimum_commitment_fee;
        }

        if (parsedData.TypeOfCFRL !== "") {
          if (parsedData.VolumeCommitCheckBox[0].status === false && parsedData.VolumeCommitCheckBox[1].status === true) {
            this.props.summaryData["Commit Type"] = "";
          } else {
            this.props.summaryData["Commit Type"] = parsedData.TypeOfCFRL;
          }
        }
        if (parsedData.TermLength !== "") {
          if (this.props.summaryData[DiscountTermEffectiveDateLabel] !== null) {
            let jan312009 = new Date(
              this.props.summaryData[DiscountTermEffectiveDateLabel]
            );
            let eightMonthsFromJan312009 = jan312009.setMonth(
              jan312009.getMonth() + parseInt(parsedData.TermLength)
            );
            this.props.summaryData["Discount Term End Date"] = moment(
              eightMonthsFromJan312009
            )
              .subtract(1, "days")

              .format("MM/DD/YYYY");
          }
          this.props.summaryData[TermLengthLabel] = parsedData.TermLength;
        }
        if (parsedData.combinedTraffic !== "") {
          this.setState({
            combinedTraffic: parsedData.combinedTraffic
          });
        }
        if (parsedData.requested_commit !== "" && parsedData.VolumeCommitCheckBox[0].status === true) {
          if (parsedData.geo !== "All") {
            let geo = [];
            geo = parsedData.geolocations
              .map(el => {
                if (el.optionValue !== 0) {
                  return ` ${el.optionKey === 'EU' ? 'EU/IL' : el.optionKey} ${el.optionValue} ${parsedData.volumeUnit}`;
                }
              })
              .filter(ele => ele !== undefined);
            geo.length > 0
              ? (parsedData.requested_commit = geo.join(";"))
              : parsedData.requested_commit;
            this.props.summaryData["Commit Volume"] = parsedData.requested_commit;
          } else {
            this.props.summaryData[
              "Commit Volume"
            ] = `${parsedData.requested_commit} ${parsedData.volumeUnit}`;
          }
        }
        if (parsedData.commitment_fee !== "") {
          this.props.summaryData["Commitment Fee"] =
            "$ " + parsedData.commitment_fee;
        }
        if (parsedData.Aos_Selected !== "" && parsedData.request_fees === 'Waive Request Fees') {
          this.props.summaryData["Minimum Average Object Size"] = parsedData.Aos_Selected;
        }
        if (parsedData.regionalTraffic !== "") {
          this.props.summaryData["Traffic Limit"] = parsedData.regionalTraffic;
          this.props.summaryData["regionalTraffic"] = parsedData.regionalTraffic;
        }
        if (parsedData.dealEdited === true) {
          this.props.summaryData["dealEdited"] = true;
        }
        if (parsedData.request_fees !== "") {
          this.props.summaryData["Request fees"] = parsedData.request_fees;
        }
      } else {
        if (parsedData.VolumeCommitCheckBox[3].status === true) {
          this.props.summaryData["CFRC Type"] = "No Commit";
        }
        if (parsedData.request_fees !== "") {
          this.props.summaryData["Request fees"] = parsedData.request_fees;
        }
        if (parsedData.TermLength_Nocommit !== "") {
          if (this.props.summaryData[DiscountTermEffectiveDateLabel] !== null) {
            let jan312009 = new Date(
              this.props.summaryData[DiscountTermEffectiveDateLabel]
            );
            let eightMonthsFromJan312009 = jan312009.setMonth(
              jan312009.getMonth() + parseInt(parsedData.TermLength_Nocommit)
            );
            this.props.summaryData["Discount Term End Date"] = moment(
              eightMonthsFromJan312009
            )
              .subtract(1, "days")

              .format("MM/DD/YYYY");
          }
          this.props.summaryData[TermLengthLabel] = parsedData.TermLength_Nocommit;
        }
        if (parsedData.regionalTraffic !== "") {
          this.props.summaryData["Traffic Limit"] = parsedData.regionalTraffic;
          this.props.summaryData["regionalTraffic"] = parsedData.regionalTraffic;
        }
        if (parsedData.Aos_Selected_Nocommit !== "" && parsedData.request_fees === 'Waive Request Fees') {
          this.props.summaryData["Minimum Average Object Size"] = parsedData.Aos_Selected_Nocommit;
        }
        if (parsedData.TypeOfCFRL !== "") {
          this.props.summaryData["Commit Type"] = "";
        }
        if (parsedData.combinedTraffic !== "") {
          this.setState({
            combinedTraffic: parsedData.combinedTraffic
          });
        }
      }
    }

    this.setState({
      textAreaValue: ""
    });
  };

  /**
   * this function will set value of comments
   */
  handleChange = value => {
    this.props.handleSummaryComment(value);
  };

  /**
   * this function will display Payer ID in different line
   */
  displayPayerIdValue = value => {
    let payerIdValue = value && value.split(",");
    return (
      payerIdValue &&
      payerIdValue.map(valuePayerId => {
        return (
          <>
            <span className="summary-left-text">{valuePayerId}</span>
            <br />
          </>
        );
      })
    );
  };
  /*this function for copy text from summary page */
  copyCodeToClipboard = () => {
    // var elm = document.getElementById("summary-copytext");
    var items = document.querySelectorAll(".summary-left-text"),
      i; //selecting all container has class .item

    for (i = 0; i < items.length; ++i) {
      // loop through each item and calling copyToClipBoard  function
      //this.copyToClipBoard(items[i]);
    }
    var copyArea = document.querySelectorAll(".nav-txt"); // selecting copy area for this item
    // var range = document.createRange();
    // range.selectNode(copyArea);
    // window.getSelection().addRange(range);  // select the text
    // try {
    //     // execute the copy command  on selected the text in copy area
    //     var copyStatus = document.execCommand('copy');
    //     var msg = copyStatus ? 'copied' : 'failed';
    // } catch (error) {
    // }

    // window.getSelection().removeAllRanges();  // remove the selection

    // for Internet Explorer

    if (document.body.createTextRange) {
      var range = document.body.createTextRange();
      range.moveToElementText(copyArea);
      range.select();
      document.execCommand("Copy");
    } else if (window.getSelection) {
      // other browsers
      var selection = window.getSelection();
      var range = document.createRange();
      range.selectNodeContents(copyArea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy");
    }
  };

  copyloadText = () => {
    var items = document.querySelectorAll(".summary-left-text"),
      i; //selecting all container has class .item

    for (i = 0; i < items.length; ++i) {
      // loop through each item and calling copyToClipBoard  function
      copyToClipBoard(items[i]);
    }

    function copyToClipBoard(item) {
      var btnCopy = item.querySelector(".btn-copy"); // selecting copy button for this item
      btnCopy.addEventListener("click", function (event) {
        // attaching click event
        var copyArea = item.querySelector(".copy-area"); // selecting copy area for this item
        var range = document.createRange();
        range.selectNode(copyArea);
        window.getSelection().addRange(range); // select the text
        try {
          // execute the copy command  on selected the text in copy area
          var copyStatus = document.execCommand("copy");
          var msg = copyStatus ? "copied" : "failed";
        } catch (error) { 
          console.log("error found", error)
        }

        window.getSelection().removeAllRanges(); // remove the selection
      });
    }
  };

  // *this function for copy text from summary page for Firefox Browser only*/
  copyToClipFirefox = () => {
    this.loadToastForCopy()
    window.getSelection().removeAllRanges();

    var range = document.createRange();

    const div = this.copyRef.current;
    const color = div.style.background;
    const textColor = div.style.color;
    div.style.margin = "10px";
    div.style.background = "#ffffff";
    div.style.color = "black";
    range.selectNode(div);
    window.getSelection().addRange(range);

    document.getElementById("copyContent").style.visibility = "hidden";
    document.execCommand("foreColor", false, "black");
    // document.execCommand( "fontName", false, "Arial");
    document.execCommand("copy");
    //document.designMode = "off";

    document.getElementById("copyContent").style.visibility = "visible";

    div.style.margin = "0px";
    div.style.background = color;
    div.style.color = textColor;
    // let body = document.getElementById("leftnavOpen");
    // body.className = "font-change";
    document.execCommand("foreColor", false, "#cecece");
    window.getSelection().removeAllRanges();
  };

  loadToastForCopy = () => {
    this.props.showDealStructureLoadingToast("Summary copied", true);
    setTimeout(() => {
      this.props.showDealStructureLoadingToast("Summary copied", false);
    }, 1500)
  }
  copyContent = () => {
    this.loadToastForCopy()
    window.getSelection().removeAllRanges();

    var range = document.createRange();

    const div = this.copyRef.current;
    const color = div.style.background;
    div.style.margin = "10px";
    div.style.background = "#ffffff";

    document.getElementById("copyContent").style.visibility = "hidden";
    range.selectNode(div);
    window.getSelection().addRange(range);
    try {
      // Now that we've selected the anchor text, execute the copy command
      document.designMode = "on";
      if (document.getElementById("additionalPricingTable")) {
        document.getElementById("additionalPricingTable").className = "after-copy-table";
      }
      if (document.getElementById("additionalDealsTable")) {
        document.getElementById("additionalDealsTable").className = "after-copy-table";
      }
      document.execCommand("foreColor", false, "black");
      // document.execCommand( "fontName", false, "Arial");
      document.execCommand("copy");
      document.designMode = "off";
    } catch (err) {
      console.warn("Copying warning", err);
    } finally {
      document.getElementById("copyContent").style.visibility = "visible";
      if (document.getElementById("additionalPricingTable")) {
        document.getElementById("additionalPricingTable").className = "";
      }
      if (document.getElementById("additionalDealsTable")) {
        document.getElementById("additionalDealsTable").className = "";
      }
      if (this.props.leftNavTierPricingTable.length >= 1 || this.props.requestFeesTable.length >= 1 || this.props.regionalLimitsTable.length >= 1 || this.props.regionalTrafficLimitTable.length >= 1) {
        document.getElementById("simpleTable").className = "copy-table data-transfer";
      }

      div.style.margin = "0px";
      div.style.background = color;
      // let body = document.getElementById("leftnavOpen");
      // body.className = "font-change";
      document.designMode = "on";
      document.execCommand("foreColor", false, "#cecece");
      document.designMode = "off";
      window.getSelection().removeAllRanges();
    }
  };

  loadToastForCopyURL = () => {
    this.props.showDealStructureLoadingToast("URL copied", true);
    setTimeout(() => {
      this.props.showDealStructureLoadingToast("URL copied", false);
    }, 1500)
  }
  copyURL = () => {

    this.loadToastForCopyURL()
    var copyText = document.getElementById("urlPath");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");

  }
  /**
   * this function will check for permission of question visible to loggedin user and return value to set visible state
   */
  makeCellVisibleBasedOnPermission = (cell, userRole) => {
    let updatedUserRole = userRole.replace(/ /g, "_");
    let isCellVisible = false;
    let cellPermission = cell.permissionSeq[0];
    let keyValueOfCellPermission = Object.keys(cellPermission);
    keyValueOfCellPermission.forEach((keyPair, keyPairIndex) => {
      if (keyPair === updatedUserRole) {
        isCellVisible = !cellPermission[keyPair].hidden;
      }
    });
    return isCellVisible;
  };

  /*load attachment list*/
  loadAttachmentList = (dealAction, isReleased = false) => {
    let attachementListAPI = `${config.amazonFalconbaseUrl}AttachmentList/listofattch?pprId=${this.props.pprId}`;
    axios({
      method: "GET",
      url: attachementListAPI
    }).then(resp => {
      if (
        resp.data &&
        ((isReleased && resp.data.executable.length > 0) ||
          (!isReleased && resp.data.fullyExecuted.length > 0))
      ) {
        this.props.handleSubmit(dealAction);
      } else {
        const message = isReleased
          ? `Please attach 'Executable Released' before updating the status.`
          : `Please attach the 'Fully Executed' document before marking 'Deal Signed'`;
        this.props.showDealStructureLoadingToast(message, true);
        setTimeout(() => {
          this.props.showDealStructureLoadingToast(message, false);
        }, 5000);
      }
    });
  };

  handleSubmit = async dealAction => {
    let { neglectCommitAndPricing } = this.props;
    if (dealAction.sucStatus === "Deal Signed") {
      this.loadAttachmentList(dealAction);
    } else if (dealAction.sucStatus === "Executable Released") {
      this.loadAttachmentList(dealAction, true);
    } else if (dealAction.sucStatus === "SCE Reviewed" || dealAction.sucStatus === "PP Public Sector Reviewed" || dealAction.sucStatus === "PP China Reviewed") {
      if (document.getElementsByClassName('progress-line').length > 0 || ((neglectCommitAndPricing === undefined || neglectCommitAndPricing === false) && document.getElementsByClassName('progress-line-forCNP').length > 0)) {
        let messageText = "Please fill all the mandatory fields in deal intake form"
        this.props.showDealStructureLoadingToast(messageText, true);
        setTimeout(() => {
          this.props.showDealStructureLoadingToast(messageText, false);
        }, 6000);
        // this.setState({
        //   basicModalVisible: true,
        //   alertMessageSCEReviewed:"Please fill all the mandatory fields"

        // })
        return;
      } else if (document.getElementsByClassName('progress-line').length > 0 && (neglectCommitAndPricing === true)) {
        const messageText = "Please fill all the mandatory fields in deal intake form"
        this.props.showDealStructureLoadingToast(messageText, true);
        setTimeout(() => {
          this.props.showDealStructureLoadingToast(messageText, false);
        }, 6000);
        // this.setState({
        //   basicModalVisible: true,
        //   alertMessageSCEReviewed:"Please fill all the mandatory fields"
        // })
        return;
      } else {
        this.props.handleSubmit(dealAction);
      }
    } else {
      this.props.handleSubmit(dealAction);
    }
  };
  //function called only for deal signed
  handleSubmitForDealSigned = () => {
    if (this.props.userRole === roleNames.BILLING_TEAM) {
      const dealAction = {
        "sucStatus": "Deal Signed",
        "sucStage": "Signature",
        "sucActionLabel": "Deal Signed",
        "isMandatory": false,
        "sucCheckOut": roleNames.BILLING_TEAM
      };
      this.props.handleSubmit(dealAction);
    } else {
      let commentUpdateApi = `${config.amazonFalconbaseUrl}cfppr/comment`;
      let data = {
        "AWSAlias": this.props.createdById,
        "userRole": "Sales Representative",
        "dealStatus": this.props.dealStatus,
        "pprId": "PPR-" + this.props.pprId,
        "comments": this.props.summaryComment,
        "loginAlias": this.props.locationStateUser.alias,
        "action": "comment"
      }
      axios({
        method: "POST",
        url: commentUpdateApi,
        data: data
      })
        .then(resp => {
          if (resp.status === 200) {
            window.location.reload();

          }
        }).catch(() => { this.props.showDealStructureLoadingToast("Failed", true); })
    }

  }
  // funtion to generate pts
  generatePTS = () => {
    let { disableSubmitButton, pprType } = this.props;
    if (disableSubmitButton) {
      this.props.showDealStructureLoadingToast("Please fill all the fields in PPR!", true);
      setTimeout(() => {
        this.props.showDealStructureLoadingToast("Please fill all the fields in PPR!", false);
      }, 3000)
    } else if (pprType === 'Specialized') {
      this.props.showDealStructureLoadingToast("PTS will be available only for Pre-Approved Deals", true);
      setTimeout(() => {
        this.props.showDealStructureLoadingToast("PTS will be available only for Pre-Approved Deals", false);
      }, 3000)
    } else {
      this.props.showDealStructureLoadingToast("Getting the PTS data...", true);
      let data = {
        "createdById": this.props.createdById,
        "pprId": "PPR-" + this.props.pprId
      };
      axios({
        method: "POST",
        url: `${ptsbaseUrl}dashboard/pts`,
        data: data
      })
        .then(resp => {
          this.props.showDealStructureLoadingToast("Downloading the PTS data...", true);
          axios({
            method: "POST",
            url: ptsDownloadUrl,
            data: resp.data,
            headers: {
              "Content-Type": 'application/json',
              // 'Authorization': localStorage.getItem("AuthHeader")
            }
          })
            .then(resp => {

              this.props.showDealStructureLoadingToast("Generating PTS...", false);
              const contentType = `application/pdf`;

              var blob = base64toBlob(resp.data.body, contentType);

              var url = window.URL.createObjectURL(blob);

              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = `${this.props.pprId}_PTS.pdf`;
              a.click();

            })
            .catch(() => {
              this.props.showDealStructureLoadingToast("Generating PTS...", false);
            });
        })
        .catch(() => {
          this.props.showDealStructureLoadingToast("Generating PTS...", false);
        });
    }

  };

  handleBasicCancel = () => {
    this.setState({
      basicModalVisible: false
    })
  }

  closeSummary = () => {
    this.props.handleOnClick()
  }

  navigateCopy = () => {
    window.open("https://broadcast.amazon.com/videos/192404", "_blank")
  }

  getRegionalMixTable = () => {
    return (
      <div className="summary-table pl-4 col-7 float-right pr-0">
        <p className="font-size12 light-white amber-bold mb-0 mt-0">
          Regional Usage Mix:
        </p>
        <div className="regional-usage-table full-width">
          <AWSHtmlTable
            columns={Constants.regionalUsageMixHeaders}
            rowValues={this.props.regionalLimitsTable}
            tierType={"summaryPage"}
          />
        </div>
      </div>
    )
  }

  /**
   * Returns value to display on summary page
   * @param {*} value Question value
   * @param {key} key Question key
   * @param {boolean} neglectCommitAndPricing Flag whether to neglect commit and pricing
   * @param {boolean} isCloudFrontShieldAdvanced Flag identifying cloudfront + shield advanced deals
   */
  getSummaryValue = (value, key, neglectCommitAndPricing, isCloudFrontShieldAdvanced) => {
    if (typeof value !== "object" && key !== "Minimum Average Object Size") {
      if (key !== "Business Justification" && commitAndPricingSummaryData.includes(key) && neglectCommitAndPricing === true) {
        return 'N/A';
      } else {
        return value;
      }
    } else if (key === "Minimum Average Object Size" && neglectCommitAndPricing === false && value !== "") {
      return `${getCloudFrontShieldAdvancedAosValue(isCloudFrontShieldAdvanced, value)}kB`;
    } else {
      return '';
    }
  }

  render() {
    let { dealIdentifier, pprType, dealInfo } = this.props;
    const { cloudFrontShieldAdvancedDeal } = this.props.dealInfo;
    const isCloudFrontShieldAdvanced = isCloudFrontShieldAdvancedDeal(cloudFrontShieldAdvancedDeal);
    let { otherAwsValue, originWithAWS, specifiedOriginWithAWS, crossServicePPA, negotiatingCrossServicePPA,
      productAtRiskList, areWeInCompetetiveSituation, competitorOffers, subRegionMix, projectedStorage, explainWindeal, winDeal,
      pastPrice, floorPrice, showPriceDetail, alertMessageSCEReviewed } = this.state;
    const additionalQuestionFlag = (dealIdentifier) ? Object.values(dealIdentifier).includes(true) : false;

    let inputData = Object.entries(this.props.summaryData);
    let {
      summarCommentArrayObject,
      triggerCommentValidation,
      disableSubmitButton,
      neglectCommitAndPricing = false
    } = this.props;

    let disableButton = disableSubmitButton;

    let columns =
      this.props.leftNavTierPricing === "tiered"
        ? Constants.getTableHeaderTier(this.state.volumeUnit)
        : Constants.tableHeaderAll;
    if (triggerCommentValidation === false) {
      validatingQuestion.isVisible = false;
    } else {
      validatingQuestion.isVisible = true;
    }
    const qualifyingQuestions = this.props.summaryData.qualifyingQuestions;
    const originShieldQuestions = this.props.summaryData.originShieldQuestions;
    const showRegionalMixTable = neglectCommitAndPricing === false && this.props.regionalLimitsTable;

    return (
      <div className="summary-wraper pl-4 float-left">
        <div className="copy-pts-wraper">
          <AWSModal
            type="basic"
            visible={this.state.basicModalVisible}
            alertMessage={alertMessageSCEReviewed}
            handleCancel={() => { this.handleBasicCancel() }}
          />
          <span className="close-summary light-white cursor-pointer float-right ml-4" onClick={() => { this.closeSummary() }}>Close <img src={closeIcon} alt="copy" className="ml-2" /></span>

          <span
            className="float-right font-size14 light-white cursor-pointer btn-copy noselect generate-ptsicon"
            id="copyURL"
            onClick={() => {
              this.copyURL();
            }}
          >
            Copy URL
            <img src={CopyIcon} alt="copy" className="ml-2" />
          </span>
          {this.props.shouldShowPTS && this.props.summaryData["Deal Type"] !== "Amendment" && <span className="float-right font-size14 light-white cursor-pointer mr-4 generate-ptsicon" onClick={() => { this.generatePTS() }}>
            PTS<img src={downloadWhite} alt="copy" className="ml-2" />
          </span>}
          <span
            className="float-right font-size14 light-white cursor-pointer btn-copy noselect generate-ptsicon mr-4"
            id="copyContent"
            onClick={() => {
              isFirefox ? this.copyToClipFirefox() : this.copyContent()
            }}
          >
            Copy
            <img src={CopyIcon} alt="copy" className="ml-2" />
          </span>
          {this.props.userRole === roleNames.SALES_REPRESENTATIVE && <span className="youtube-icon copy-youtube-icon leftNav-copy-youtube" onClick={() => { this.navigateCopy() }}>
            {/* <img src={youtubeIcon} alt="youtube" /> */}
            <VideoIcon />
            <span className="leftNav-copy-tooltip">Deal Summary - Copy Icon</span></span>}
          <input type="text" defaultValue={`${config.baseURl}/create-ppr/PPR-${this.props.pprId}/view`} id="urlPath" className="urlpath" aria-hidden="true" />
          {/* <p className="urlpath" id="urlPath" ref={this.copyURLRef}>{`${config.baseURl}/create-ppr/PPR-${this.props.pprId}/view`}</p> */}
          {/* <p className="urlpath" id="urlPath" ref={this.copyURLRef}>{this.state.URLPath}</p> */}
          {/* <span className="float-right font-size14 light-white cursor-pointer mr-4 generate-ptsicon" onClick={() => { this.generatePTS() }}>
            PTS<img src={downloadWhite} alt="copy" className="ml-2" />
          </span> */}

        </div>

        <div
          className="summary-content col-12 p-0 float-left scroll-width10 summary-scroll moz-scroll pr-4"
          id="leftnavOpen"
          ref={this.copyRef}
        >
          <div className="tite-wraper">
            <span className="font-size18 orange title-txt">Deal summary</span>
            <span className="font-size18 orange title-txt"> - </span>
            {this.props.pprId && (
              <span className="font-size18 orange title-txt">
                {this.props.pprId}
              </span>
            )}
            <span className="font-size18 orange title-txt"> - </span>
            {this.props.summaryData["Customer Legal Name"] && (
              <span className="font-size18 orange title-txt">
                {this.props.summaryData["Customer Legal Name"]}
              </span>
            )}

          </div>
          <div className="float-left col-12 p-0">
            <div className="summary-left nav-txt col-5 float-left p-0 pr-5">
              {inputData &&
                inputData.map(([key, value]) => {
                  if (
                    key !== "editableTableValues" &&
                    key !== "regionalMix" &&
                    key !== "regionalTraffic" &&
                    key !== "PprId" &&
                    key !== "defaultLoad" &&
                    key !== "pricingTermsFlag" &&
                    key !== "NoCommit" &&
                    key !== "dealEdited" &&
                    key !== "TermLength" &&
                    (value && value.queType !== "RATETABLE") &&
                    key !== "qualifyingQuestions" &&
                    key !== "originShieldQuestions"
                  ) {
                    return (
                      <div className="nav-txt font-size12 light-white full-width">
                        <span className="float-left pr-2 sub-title">
                          {key}
                          {" :    "}
                        </span>
                        {key === payerIdLabel || key === affiliate_Payer_Id ? (
                          <span className={`float-left ${key === affiliate_Payer_Id ? 'payer-ids-affiliate' : 'payer-ids'}`}>
                            {value && value.split(",").join("; ")}
                          </span>
                        ) : (
                          <span
                            className="summary-left-text"
                            id="summary-copytext"
                          >
                            {this.getSummaryValue(value, key, neglectCommitAndPricing, isCloudFrontShieldAdvanced)}
                          </span>
                        )}
                      </div>
                    );
                  }
                })}

              <p className="font-size10 light-white mt-2 col-12 p-0 pricing-txt float-left">
                Pricing and terms provided (subject to effective date policy)
                are valid ONLY for 30 days from today's date. After 30 days
                executive approval will be required again.
              </p>
            </div>
            {Object.values(this.props.summaryData).filter((question) => question && question.queType === "RATETABLE" && question.isVisible).map((question) => {
              return (
                <div key={question.queId} className="summary-table pl-4 col-7 float-right pr-0">
                  <p className="font-size12 light-white amber-bold mb-0 mt-0">
                    {question.queLabel}
                  </p>
                  <div className="request-fee-table full-width">
                    <AWSHtmlTable
                      columns={question.options.map((option) => option.optionValue)}
                      rowValues={question.tableObj.rows}
                      tierType={"summaryPage"}
                    />
                  </div>
                </div>
              );
            })}
            {neglectCommitAndPricing === false && this.props.summaryData["Request fees"] === "Apply Private Rates" &&
              this.props.requestFeesTable &&
              (
                <div className="summary-table pl-4 mb-0 col-7 float-right pr-0 ">
                  <p className="font-size12 light-white amber-bold mb-0">
                    Request Fee:
                  </p>
                  <div className="request-fee-table full-width">
                    <AWSHtmlTable
                      columns={Constants.tableRequestHeaders}
                      rowValues={this.props.requestFeesTable}
                      tierType={"summaryPage"}
                    />
                  </div>
                </div>
              )}
            {neglectCommitAndPricing === false && this.props.leftNavTierPricing !== "" &&
              this.props.leftNavTierPricingTable && (
                <div className="summary-table pl-4 mb-0 col-7 float-right pr-0 ">
                  <p className="font-size12 light-white amber-bold mb-0 mt-0">
                    Data Transfer Price (per GB):
                  </p>
                  <div className={`${this.props.leftNavTierPricing === 'Nottiered' ? "data-transfer-table full-width withouttier" : "data-transfer-table full-width withtier"} `}>
                    <AWSHtmlTable
                      columns={columns}
                      rowValues={this.props.leftNavTierPricingTable}
                      tierType={"summaryPage"}
                    />
                  </div>
                </div>
              )}
            {showRegionalMixTable && this.getRegionalMixTable()}
            {neglectCommitAndPricing === false && this.props.summaryData["regionalTraffic"] === "Yes" &&
              this.props.regionalTrafficLimitTable && (
                <div className="summary-table pl-4 mb-4 col-7 float-right pr-0">
                  <p className="font-size12 light-white amber-bold mb-0 mt-0">
                    Regional Traffic Limits (as a % of total traffic):
                  </p>
                  <div className="regional-traffic-table full-width">
                    <AWSHtmlTable
                      columns={Constants.regionalTrafficLimitHeaders}
                      rowValues={this.props.regionalTrafficLimitTable}
                      tierType={"widthregional"}
                    />
                  </div>
                </div>
              )}
          </div>
          <div
            id="commentDiv"
            className="comment-wraper float-left col-12 p-0 mb-2"
          >
            {this.props.showAdiDealTermInSummary === true && (
              <>
                <div className="full-width additional-table addtional-deal">
                  <p className="light-white font-size12 mb-0 amber-bold pt-3 pb-3 lineheight36 full-width">
                    Additional Deal Terms
                  </p>
                  <table id="additionalDealsTable" border='1' border-collapse='0' bordercolor='#293647'>
                    <thead>
                      <tr>
                        <th>Request</th>
                        <th>Justification</th>
                        {(this.props.userRole === roleNames.SCE_OWNER ||
                          this.props.userRole === roleNames.IGT_OWNER ||
                          this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                          this.props.userRole === roleNames.PRIVATE_PRICING_CHINA) && (
                            <th>
                              Map Additional Deal Term
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.adiDealTermFormGroup &&
                        this.props.adiDealTermFormGroup.map(
                          (formGroupRow, formGroupRowIndex) => {
                            if (formGroupRowIndex !== 0) {
                              return (
                                <tr>
                                  {formGroupRow.cells &&
                                    formGroupRow.cells.map(
                                      (formGroupCell, formGroupCellIndex) => {
                                        let cellVisible = this.makeCellVisibleBasedOnPermission(
                                          formGroupCell,
                                          this.props.userRole
                                        );
                                        if (cellVisible === true) {
                                          return (
                                            <td
                                              key={formGroupCellIndex}
                                            >
                                              {formGroupCell.value}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              );
                            }
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {neglectCommitAndPricing === false && this.props.summaryData["pricingTermsFlag"] === "Yes" && (
              <div className="full-width additional-table">
                <p className="light-white font-size12 mb-0 amber-bold pt-3 pb-3 lineheight36">
                  Additional Pricing Terms
                </p>
                <table id="additionalPricingTable" border='1' border-collapse='0' bordercolor='#293647'>
                  <thead>
                    <tr>
                      <th>Request</th>
                      <th>Justification</th>
                      {(this.props.userRole === roleNames.SCE_OWNER ||
                        this.props.userRole === roleNames.IGT_OWNER ||
                        this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                        this.props.userRole === roleNames.PRIVATE_PRICING_CHINA
                      ) && (
                          <th>
                            Map to Additional Pricing terms
                          </th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.additionalPricingTerms &&
                      this.props.additionalPricingTerms.map((row, rowIndex) => {
                        return (
                          <tr key={rowIndex}>
                            {row.cells.map((cell, cellIndex) => {
                              return (
                                <td
                                  key={cellIndex}
                                >
                                  {cell.value}
                                </td>
                              );
                            })}

                            {(this.props.userRole === roleNames.SCE_OWNER ||
                              this.props.userRole === roleNames.IGT_OWNER ||
                              this.props.userRole === roleNames.PRIVATE_PRICING_PUBLIC_SECTOR ||
                              this.props.userRole === roleNames.PRIVATE_PRICING_CHINA) && (
                                <td
                                  key={rowIndex}
                                >
                                  {row.corePlusOption}
                                </td>
                              )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {/* specialized ppr question */}
            {(additionalQuestionFlag === true || pprType === "Specialized") && (
              <div className="full-width additional-table spcialized-table pt-0">
                <p className="light-white font-size12 mb-0 amber-bold pt-3 pb-3 lineheight36">
                  Service Team Required Information
                </p>
                <table border='1' class="separate" bordercolor='#293647'>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Other AWS Services Consumed</td>
                      <td>{otherAwsValue === null || otherAwsValue === "" ? "None" : otherAwsValue}</td>
                    </tr>
                    <tr>
                      <td>Is the origin with AWS?</td>
                      <td>{originWithAWS}{originWithAWS === "Yes" ? "" : `${specifiedOriginWithAWS === null ? "" : `, ${specifiedOriginWithAWS}`}`}</td>
                    </tr>
                    {/* {
                      originWithAWS === "No" && (
                        <tr>
                          <td>Please specify the origin.</td>
                          <td>{specifiedOriginWithAWS}</td>
                        </tr>
                      )
                    } */}

                    <tr>
                      <td>Does the customer have an existing Cross Service PPA ? (Optional)</td>
                      <td>{crossServicePPA}</td>
                    </tr>
                    <tr>
                      <td>Is the customer negotiating a Cross Service PPA ? (Optional)</td>
                      <td>{negotiatingCrossServicePPA}</td>
                    </tr>
                    <tr>
                      <td>Which other products are at risk if this deal does not get approved ?</td>
                      <td>{productAtRiskList}</td>
                    </tr>

                    {
                      neglectCommitAndPricing === false && (
                        <tr>
                          <td>If approved at the floor price, will this win the deal ?</td>
                          <td>{winDeal}{winDeal === "Yes" ? "" : `${(explainWindeal === null || explainWindeal === "") ? "" : `, ${explainWindeal}`}`}</td>
                        </tr>
                      )
                    }

                    {/* {
                      neglectCommitAndPricing === false && winDeal === "No" && (
                        <tr>
                          <td>Please Explain.</td>
                          <td>{explainWindeal}</td>
                        </tr>
                      )
                    } */}
                    {
                      neglectCommitAndPricing === false && showPriceDetail === true && (
                        <>
                          <tr>
                            <td>How much CloudFront usage has the customer had over the past 12 months ?</td>
                            <td>{pastPrice}</td>
                          </tr>
                          <tr>
                            <td> How much CloudFront usage does the customer expect to have per year over the term of the agreement?</td>
                            <td>{floorPrice}</td>
                          </tr></>
                      )
                    }
                    {areWeInCompetetiveSituation === "Yes" && (
                      <tr>
                        <td>Please provide what the competitor is offering in terms of rate and commit ?</td>
                        <td>{competitorOffers}</td>
                      </tr>
                    )}
                    {
                      neglectCommitAndPricing === false && (
                        <>
                          <tr>
                            <td> Is there any projected incremental S3 storage ? (Optional)</td>
                            <td>{projectedStorage}</td>
                          </tr>
                          <tr>
                            <td> What is sub region mix in AP- SIN, HK, KR, CH, PH, TW, KUL ? (Optional)</td>
                            <td>{subRegionMix}</td>
                          </tr>
                        </>
                      )
                    }
                  </tbody>
                </table>
              </div>
            )}
            {Object.values(qualifyingQuestions).length > 0 && (
              <div className="full-width additional-table">
                <p className="light-white font-size12 mb-0 amber-bold pt-3 pb-3 lineheight36">
                  Additional Qualifying Questions
                </p>
                <table id="additionalQualifyingQuestionsTable" border='1' border-collapse='0' bordercolor='#293647'>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.qualifyingQuestionIds &&
                      this.props.qualifyingQuestionIds.map(id => {
                        const question = qualifyingQuestions[id];
                        return question && question.isVisible ? (
                          <tr>
                            <td>
                              {question.queLabel}
                            </td>
                            <td>
                              {question.value}
                            </td>
                          </tr>
                        ) : null;
                      })}
                  </tbody>
                </table>
              </div>
            )}
            {Object.values(originShieldQuestions).length > 0 &&
              Object.values(qualifyingQuestions).find(question => question.queLabel === qualifyingQuestionsConstants.originShieldQuestionLabel).value === "Yes" &&
              (
                <div className="full-width additional-table">
                  <p className="light-white font-size12 mb-0 amber-bold pt-3 pb-3 lineheight36">
                    Origin Shield Questions
                  </p>
                  <table id="originShieldQuestionsTable" border='1' border-collapse='0' bordercolor='#293647'>
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Response</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.originShieldQuestionIds &&
                        this.props.originShieldQuestionIds.map(id => {
                          const question = originShieldQuestions[id];
                          return question && question.isVisible ? (
                            <tr>
                              <td>
                                {question.queLabel}
                              </td>
                              <td>
                                {question.value}
                              </td>
                            </tr>
                          ) : null;
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            {/* <div className="col-6 pt-4 float-left p-0">
                            <p className="font-size10 light-white">Sales Rep: {this.props.salesRepName}</p>

                            {
                                summarCommentArrayObject.commentText && summarCommentArrayObject.commentText.map((commentObject, commentObjectIndex) => {
                                    if (commentObject.commentText !== "" && commentObject.commentText !== null) {
                                        return (
                                            <p className="font-size10 light-white">
                                                {commentObject.summaryUserRole !== "Sales Representative" ? `${commentObject.summaryUserRole} Comment :` : "Sales Rep Comment: "}{commentObject.commentText}
                                            </p>
                                        )
                                    }
                                })
                            }

                        </div> */}

          </div>
        </div>

        <div className="padding-summary-from-title">
          {this.props.enableCommentBox === true && (
            <div className="full-width comment-box mt-4">
              <div className="comments-button col-6 pull-right float-right p-0 mt-3">
                <AWStextarea
                  className="summaryComments commentTextArea"
                  label=""
                  labelWhite={true}
                  // placeholder={this.props.userRole === "Sales Representative" ? "Comments to Sales Manager" : "Comments to Sales Rep"}
                  placeholder="Enter Comments"
                  onChangeFunc={e => this.handleChange(e)}
                  value={this.props.summaryComment || ""}
                  questionValue={validatingQuestion}
                  validation={validationArray}
                  triggeredNext={triggerCommentValidation}
                />
                {this.props.summaryCommentTimeStamp && (
                  <span className="comment-timestamp">
                    {moment(this.props.summaryCommentTimeStamp).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="summary-context full-width">
            <div className="float-right mt-2 mb-0">
              {
                this.props.dealStatus == "Deal Signed" && (
                  <div className="width-auto float-left btmbtn-group">
                    <AWSbutton
                      btnType="fill"
                      label="Submit"
                      disabled={this.props.summaryComment === null ? true : false}
                      onClick={() => {
                        this.handleSubmitForDealSigned();
                      }}
                      isIcon={false}
                    />
                  </div>
                )
              }
            </div>
          </div>
          <div className="summary-context full-width pb-3">
            <div className="float-right mt-2 mb-0">
              {this.props.dealAction &&
                this.props.dealAction.map((dealAction, dealActionIndex) => {
                  if (
                    document.getElementsByClassName("progress-line").length ===
                    0 &&
                    dealAction.isMandatory === false
                  ) {
                    disableButton = false;
                  }

                  if ((this.props.ownerType === this.props.userRole) || this.props.ownerType === "Open") {
                    return (
                      <div className="width-auto float-left pt-3 btmbtn-group">
                        <AWSbutton
                          btnType={
                            this.props.dealAction.length === dealActionIndex + 1
                              ? "fill"
                              : "trasparent-btn"
                          }
                          label={dealAction.sucActionLabel}
                          // disabled={this.props.dealStatus === dealStatusConstant.PPR_Created ? disableSubmitButton : dealAction.isMandatory === false ? false : disableSubmitButton}
                          disabled={(this.props.dealStatus === dealStatusConstant.PPR_Created || this.props.dealStatus === dealStatusConstant.Awaiting_Sales_Input || this.props.dealStatus === dealStatusConstant.Awaiting_Sales_Input_SM) ? disableSubmitButton : false}
                          onClick={() => {
                            this.handleSubmit(dealAction);
                          }}
                          isIcon={false}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
