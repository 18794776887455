import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./AWSinput.scss";
import _ from 'underscore';
import AWSError from '../AWSError/AWSError';
import * as roleNames from '../../../constants/roleNames.const';
import {Input} from  "@amzn/awsui-components-react";

export default class AWSPricinginput extends Component {

    state = {
        errorLabel: "",
        error: false
    }

    validate(validationArray, val) {
        _.each(validationArray, (validationObject, validationIndex) => {
            switch (validationObject.type) {
                case "Required":
                    if ((_.isEmpty(val)) || (_.isNull(val))) {
                        this.setState({
                            error: true,
                            errorLabel: validationObject.message
                        })
                    } else if((_.isEmpty(val.trim())) || (_.isNull(val))){
                        this.setState({
                            error: true,
                            errorLabel: validationObject.message
                        })
                    }
                break;
            }
        });
    }

    checkForDisable = (canEditPPR) => {
        if (canEditPPR === false) {
            return true 
        } else {
            return false
        }
      }

    handleChange = (val, label, validationArray) => {
        this.setState({
            error: false
        })
        this.validate(validationArray, val);
        this.props.onChangeFunc(val);
    }

    handleBlur = (val) => {
        this.props.onBlurFunc(val);
    }

    removeError = () => {
        this.setState({
            error: false
        })
    }

    render() {
        const { label, placeholder, value, triggeredNext, questionValue, userRole, canEditPPR } = this.props;
        if (this.state.error === true && questionValue.isVisible === false) {
            this.removeError()
        }
        if (triggeredNext && (!this.state.error) && questionValue && questionValue.isVisible) {
            this.validate(this.props.validation, value)
        }

        return (
            <div>
                <div className="AWS-input-div">
                    <div className={this.props.labelType === "TEXTUP" ? `${this.props.className}` : "input-label"}>{label === "Polaris" ? '' : label}</div>
                    <Input
                        placeholder={placeholder}
                        value={value}
                        error={value === "" ? true : false}
                        type={"text"}
                        onInput={(e) => this.handleChange(e.detail.value, label, this.props.validation)}
                        onBlur={(e) => this.handleBlur(value)}
                        disabled={this.checkForDisable(canEditPPR)}
                    />
                </div>
                {this.state.error &&
                (<AWSError
                    errorLabel={this.state.errorLabel}
                />)}
            </div>
        );
    }
}

AWSPricinginput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChangeFunc: PropTypes.func,
    onBlurFunc: PropTypes.func
};
